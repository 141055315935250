import { Link } from 'react-router-dom';

import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Context } from "../index";
import { leftMenuLinks } from '../routes';
import Row from 'react-bootstrap/esm/Row';

const LeftMenu = observer(() => {

    const context = useContext(Context)

    return (
        <div className={'left-menu ' + (context?.menu.expanded ? 'expanded' : 'collapsed')}>
            <Row className="nav">
                {leftMenuLinks.map((link, i) => {
                    return (context?.user?.authenticated || !link.auth ? <Link key={i} to={link.path} className="nav-link"><div>{link.icon}{context?.menu.expanded && link.caption}</div></Link> : '')
                })}
            </Row>

            {context?.menu.expanded &&
                <Row className="contacts">
                    <div>
                        <div>Контакты техподдержки:</div>
                        <div>X (XXX )XXX-XXXX</div>
                        <div>X (XXX) XXX-XXXX</div>
                        <div><a href="mailto:123@mail.ru">Написать в техподдержку</a></div>
                        <div><Link to={'/about'}>Руководство пользователя</Link></div>
                    </div>
                </Row>
            }

        </div>
    )
})

export default LeftMenu;          