import { useEffect, useState } from "react";
import { fetchAllOdata } from "../http/ODataApi";
import { Container, Spinner } from "react-bootstrap";
import { ComplexPredicate, Condition, Filter, Predicate, SimplePredicate } from "../utils/filters";
import { AuditDesc } from "../descriptions/Descriptions";
import { FormatValue } from "../utils/formats";
import { OperationType } from "../descriptions/Enums";

interface Props {
  id: string
}

const ObjectAudit = (props: Props) => {
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  const setResult = (result: any) => {
    setItems(result.jsonItems);
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)

    let predicates = []
    predicates.push(new SimplePredicate('ObjectPrimaryKey', Filter.Eq, '{' + props.id + '}'))
    predicates.push(new SimplePredicate('OperationType', Filter.Neq, 'Ratify'))

    const filter = new ComplexPredicate(Condition.And, predicates)

    fetchAllOdata(AuditDesc, AuditDesc.views.webL, { search: filter, sort: 'OperationTime', sortOrder: 'desc' }).then((results) => {
      setResult(results)
    }).catch((e) => {
      console.error(e)
      setLoading(false)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container fluid>
      {loading
        ? <Container fluid className='save-loader d-flex justify-content-center'><Spinner animation='border' variant='primary' aria-flowto='' /></Container>
        : 
        <div className="table-container">
          {items.length > 0
            ? items.map((record) => {
              return (
                <div key={record.id} className='audit'>
                  <div className='record-title'>{record.OperationType === OperationType.Insert ? 'Создание' : record.OperationType === OperationType.Update ? 'Изменение' : 'Удаление'} объекта ({record.UserName}, {FormatValue(record.OperationTime)} )</div>
                  {record.OperationType !== OperationType.Delete &&
                    <div>
                      <div className='table-responsive'>
                        <table className='table table-lg table-striped table-hover'>
                          <thead>
                            <tr><th>Поле</th><th>Старое значение</th><th>Новое значение</th></tr>
                          </thead>
                          <tbody>
                            {record.SerializedFields.map((f: { id: string, field: string, newValue: any | null, oldValue: any | null }) => {
                              return (
                                <tr key={f.id}>
                                  <td>{f.field}</td>
                                  <td>{f.oldValue || ' - Нет значения - '}</td>
                                  <td>{f.newValue || ' - Нет значения - '}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>}
                </div>)
            })
            : <div className={'noData'}>Нет данных</div>}

        </div>
      }
    </Container>
  );
}

export default ObjectAudit;