import DatePicker from 'react-datepicker'
import { FormatValue } from "../utils/formats";

interface Props {
    onChange: any
    value?: Date | null
    name?: string
    readonly: boolean
    className?: string
}

const DateInput = (props: Props) => {
    return (
        props.readonly
            ? <div className={'readonly'}>{FormatValue(props.value) || '-'}</div>
            : <DatePicker
                dateFormat='dd.MM.yyyy'
                strictParsing={true}
                placeholderText=''
                name={props.name}
                selected={props.value}
                onChange={(date) => {
                    props.onChange(date)
                }}
                onChangeRaw={() => {
                    props.onChange(null)
                }}
                className={props.className}
            />
    );
}

export default DateInput;