import { PropertyDescription, BoolPropertyDescription, DataObjectDescription, ViewProperty, DataObjectPropertyDescription, DatePropertyDescription, ExpandViewProperty } from './DescriptionTypes'

export const AdresFiasDesc = new DataObjectDescription('SkyoriEAAdresFiass', {
    'AdresStrokoi': new PropertyDescription(),
    'AOguid': new BoolPropertyDescription()
}, {
    'webL': {
        'AdresStrokoi': new ViewProperty('Адрес')
    },
    'webE': {
        'AdresStrokoi': new ViewProperty('Адрес')
    }
})

export const AdresKladrDesc = new DataObjectDescription('SkyoriEAAdresKladrs', {
    'AdresStrokoi': new PropertyDescription(),
    'AOguid': new BoolPropertyDescription()
}, {
    'webL': {
        'AdresStrokoi': new ViewProperty('Адрес')
    },
    'webE': {
        'AdresStrokoi': new ViewProperty('Адрес')
    }
})

export const EdIzmereniaDesc = new DataObjectDescription('SkyoriEAEdIzmerenias', {
    'Naimenovanie': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipSooruzheniyaDesc = new DataObjectDescription('SkyoriEATipSooruzheniyas', {
    'Naimenovanie': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const FondDesc = new DataObjectDescription('SkyoriEAFonds', {
    'Naimenovanie': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const VidTehPasportaDesc = new DataObjectDescription('SkyoriEAVidTehPasportas', {
    'Naimenovanie': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const VidIznosaDesc = new DataObjectDescription('SkyoriEAVidIznosas', {
    'Naimenovanie': new PropertyDescription(),
    'Tip': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Tip': new ViewProperty('Наименование'),
        'Vid': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Tip': new ViewProperty('Наименование'),
        'Vid': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const MaterialDesc = new DataObjectDescription('SkyoriEAMaterials', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()    
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipStenDesc = new DataObjectDescription('SkyoriEATipStens', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipSantehnikiDesc = new DataObjectDescription('SkyoriEATipSantehnikis', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const EtazhiDesc = new DataObjectDescription('SkyoriEAEtazhis', {
    'Naimenovanie': new PropertyDescription(),
    'NaimenovanieRP': new PropertyDescription(),
    'NaimenovaniePP': new PropertyDescription(),
    'NaimenovanieSokr': new PropertyDescription(),
    'NaimenovanieSokr2': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty(''),
        'NaimenovanieRP': new ViewProperty(''),
        'NaimenovaniePP': new ViewProperty(''),
        'NaimenovanieSokr': new ViewProperty(''),
        'NaimenovanieSokr2': new ViewProperty(''),
        'Aktualno': new ViewProperty('')
    }
}, { 'Aktualno': true })

export const ElementDesc = new DataObjectDescription('SkyoriEAElements', {
    'Naimenovanie': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const PomeshenieDesc = new DataObjectDescription('SkyoriEAPomeshenies', {
    'Naimenovanie': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const VidSobstvennostiDesc = new DataObjectDescription('SkyoriEAVidSobstvennostis', {
    'Naimenovanie': new PropertyDescription(),
    'Tip': new PropertyDescription(),
    'Fraza': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Tip': new ViewProperty('Тип'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Tip': new ViewProperty('Тип'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipVladeniyaDesc = new DataObjectDescription('SkyoriEATipVladeniyas', {
    'Naimenovanie': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const BlagoustroistvoDesc = new DataObjectDescription('SkyoriEABlagoustroistvos', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Priznak': new PropertyDescription('number'),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Priznak': new ViewProperty('Признак'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipFundamentaDesc = new DataObjectDescription('SkyoriEATipFundamentas', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipPerekritiaDesc = new DataObjectDescription('SkyoriEATipPerekritias', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipKrovliDesc = new DataObjectDescription('SkyoriEATipKrovlis', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipPolaDesc = new DataObjectDescription('SkyoriEATipPolas', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipProemaDesc = new DataObjectDescription('SkyoriEATipProemas', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipOtdelkiDesc = new DataObjectDescription('SkyoriEATipOtdelkis', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const TipPeregorodokDesc = new DataObjectDescription('SkyoriEATipPeregorodoks', {
    'Naimenovanie': new PropertyDescription(),
    'Kod': new PropertyDescription(),
    'Vid': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Kod': new ViewProperty('Код'),
        'Vid': new ViewProperty('Вид'),
        'Aktualno': new ViewProperty('Актуально')
    }
}, { 'Aktualno': true })

export const VidSooruzheniyaDesc = new DataObjectDescription('SkyoriEAVidSooruzheniyas', {
    'Naimenovanie': new PropertyDescription(),
    'Aktualno': new BoolPropertyDescription(),
    'Nomm': new PropertyDescription('number'),
    'Nomsbor': new PropertyDescription('number'),
    'Grupkap': new PropertyDescription('number'),
    'Obem1': new PropertyDescription('number'),
    'Obem2': new PropertyDescription('number'),
    'Obem3': new PropertyDescription('number'),
    'Obem4': new PropertyDescription('number'),
    'Obem5': new PropertyDescription('number'),
    'Dopoln': new PropertyDescription('number'),
    'EdIzmerenia': new DataObjectPropertyDescription(EdIzmereniaDesc),
    'TipSten': new DataObjectPropertyDescription(TipStenDesc),
    'TipFundamenta': new DataObjectPropertyDescription(TipFundamentaDesc),
    'TipProema': new DataObjectPropertyDescription(TipProemaDesc),
    'TipPola': new DataObjectPropertyDescription(TipPolaDesc),
    'TipKrovli': new DataObjectPropertyDescription(TipKrovliDesc),
    'TipPerekritia': new DataObjectPropertyDescription(TipPerekritiaDesc)

}, {
    'webL': {
        'Naimenovanie': new ViewProperty('Наименование'),
        'Aktualno': new ViewProperty('Актуально')
    },
    'webE': {
        'Naimenovanie': new ViewProperty(''),
        'Aktualno': new ViewProperty(''),
        'Nomm': new ViewProperty(''),
        'Nomsbor': new ViewProperty(''),
        'Grupkap': new ViewProperty(''),
        'Obem1': new ViewProperty(''),
        'Obem2': new ViewProperty(''),
        'Obem3': new ViewProperty(''),
        'Obem4': new ViewProperty(''),
        'Obem5': new ViewProperty(''),
        'Dopoln': new ViewProperty(''),
        'EdIzmerenia': new ExpandViewProperty('', {
            'Naimenovanie': new ViewProperty('')
        }),
        'TipSten': new ExpandViewProperty('', {
            'Naimenovanie': new ViewProperty('')
        }),
        'TipFundamenta': new ExpandViewProperty('', {
            'Naimenovanie': new ViewProperty('')
        }),
        'TipProema': new ExpandViewProperty('', {
            'Naimenovanie': new ViewProperty('')
        }),
        'TipPola': new ExpandViewProperty('', {
            'Naimenovanie': new ViewProperty('')
        }),
        'TipKrovli': new ExpandViewProperty('', {
            'Naimenovanie': new ViewProperty('')
        }),
        'TipPerekritia': new ExpandViewProperty('', {
            'Naimenovanie': new ViewProperty('')
        }),
    }
}, { 'Aktualno': true })

export const KontragentDesc = new DataObjectDescription('SkyoriEAKontragents', {
    'Naimenovanie': new PropertyDescription(),
    'AdressProzhivaniya': new PropertyDescription(),
    'Pasport': new PropertyDescription(),
    'DataVydachi': new DatePropertyDescription()
}, {
    'webL': {
        'Naimenovanie': new ViewProperty('ФИО/Наименование'),
        'AdressProzhivaniya': new ViewProperty('Адрес проживания/юр. адрес'),
        'Pasport': new ViewProperty('Паспорт'),
        'DataVydachi': new ViewProperty('Дата выдачи'),
    },
    'webE': {
        'Naimenovanie': new ViewProperty('ФИО/Наименование'),
        'AdressProzhivaniya': new ViewProperty('Адрес проживания/юр. адрес'),
        'Pasport': new ViewProperty('Паспорт'),
        'DataVydachi': new ViewProperty('Дата выдачи'),
    }
})