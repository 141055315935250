import { makeAutoObservable } from 'mobx';
import Keycloak from 'keycloak-js';
import { config } from '../utils/config'

const keycloak = new Keycloak({
    url: config.REACT_APP_KEYCLOAK_URL,
    realm: config.REACT_APP_KEYCLOAK_REALM || '',
    clientId: config.REACT_APP_KEYCLOAK_CLIENT || ''
});

export class UserStore {
    private _isLoading: boolean;
    private _keycloak: Keycloak | null;

    private static instance: UserStore;

    static Create() {
        if (this.instance) return this.instance;
        return new UserStore()
    }

    private constructor() {
        this._keycloak = null;
        this._isLoading = true

        localStorage.removeItem('token')

        keycloak.onTokenExpired = () => {
            this.refreshToken();
        };

        keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then(authenticated => {
            this._keycloak = keycloak
            this._isLoading = false

            localStorage.setItem('token', keycloak.token || '')

            console.log('Get token:' + keycloak.token)
        }).catch((e) => {
            console.error(e)
            this._isLoading = false
        })

        makeAutoObservable(this)
    }

    refreshToken() {
        if (this._keycloak) {
            this._keycloak.updateToken(5).then((refreshed: boolean) => {
                if (refreshed) {
                    localStorage.setItem('token', this._keycloak?.token || '')
                    console.log('Token refreshed: ' + this._keycloak?.token || '')
                }
            }).catch(() => {
                console.error('Failed to refresh the token')
            })
        }
    }

    logout() {
        if (this._keycloak) {
            this._keycloak.logout();
        }
    }

    get Loading() {
        return this._isLoading
    }

    get authenticated() {
        return this._keycloak?.authenticated || false
    }

    get userName() {
        return this._keycloak?.tokenParsed?.preferred_username || ''
    }
}

export const userStore = UserStore.Create();