
import React, { useEffect, useRef, useState } from 'react'
import { fetchOneOdata } from '../http/ODataApi'

import Input from '../components/Input'
import DateInput from '../components/DateInput'
import CheckBoxInput from '../components/CheckBoxInput'
import NumberInput from '../components/NumberInput'
import Lookup from '../components/Lookup/Lookup'

import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'

import { Formik, Form, FormikProps, FormikValues } from 'formik';

import CheckAccessForm from '../components/CheckAccessForm'

import { AdrPlanDesc } from '../descriptions/Descriptions';
import { AdresFiasDesc, AdresKladrDesc, FondDesc, VidTehPasportaDesc } from '../descriptions/Dictionaries';

import { State } from '../descriptions/State'

import ClosableAlert from '../components/ClosableAlert'

import { TipObjekta, StatusObjekta } from '../descriptions/Enums'
import DropDown from '../components/DropDown'
import FiasItem from './FiasItem'
import FondItem from './FondItem'
import KladrItem from './KladrItem'
import VidTehPasportaItem from './VidTehPasportaItem'

interface Props {
    access: { read: boolean, full: boolean }
    onClose: () => void
    onSave: (values: any) => void
    edit: boolean
    id: string | null
}

const AdrPlanForm = (props: Props) => {
    const newObject = !props.id;

    const [loading, setLoading] = useState(true)
    const [showValidation, setShowValidation] = useState(false)

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [readonly, setReadonly] = useState(!((newObject || props.edit) && props.access.full))

    const [error, setError] = useState<string | null>(null)
    const [saveError, setSaveError] = useState<string | null>(null)
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
    const [item, setItem] = useState<any>()

    const formRef = useRef<FormikProps<FormikValues>>(null)

    useEffect(() => {
        setLoading(true)

        if (props.id) {
            fetchOneOdata(AdrPlanDesc, AdrPlanDesc.views.webE, props.id).then((result: any | null) => {
                setItem(result || {});
                setLoading(false)
            }).catch(() => {
                setLoading(false)
                setError('Невозможно получить данные')
            });
        } else {
            setLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validate = (values: any) => {
        const errors: any = {};

        if (!values['TipObjekta'])
            errors['TipObjekta'] = 'Required';

        if (!values['Status'])
            errors['Status'] = 'Required';

        return errors;
    };

    function onSubmit(fields: any, actions: any): Promise<any> {
        return AdrPlanDesc.save({ state: newObject ? State.created : State.altered, item: fields }).then((data) => {
            actions.setSubmitting(false);
            actions.resetForm({
                values: fields
            });
            return { values: data, id: data.id }
        }).catch((e) => {
            console.error(e)
            setSaveError('Произошла ошибка при сохранении')
        }).finally(() => {
            actions.setSubmitting(false);
        })
    }

    return (
        <Container fluid className={'edit-form'}>
            <Row className='edit-form-content'>
                {loading
                    ? <Container fluid className='d-flex justify-content-center save-loader'><Spinner animation='border' variant='primary' aria-flowto='' />Загрузка...</Container>
                    : error !== null
                        ? <div>{error}</div>
                        :
                        <Formik innerRef={formRef} enableReinitialize={true} initialValues={item || {}} validateOnMount={true} validate={validate} onSubmit={onSubmit}>
                            {({ errors, values, touched, setFieldValue, isSubmitting }) => (
                                <Form>
                                    {isSubmitting
                                        ? <Container fluid className='save-loader d-flex justify-content-center'><Spinner animation='border' variant='primary' aria-flowto='' />Сохранение...</Container>
                                        : <div>
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <label>Тип объекта</label>
                                                            <div className={'wrapper'}>
                                                                <DropDown value={values['TipObjekta']} items={TipObjekta} readonly={readonly} onChange={(e: string) => { setFieldValue('TipObjekta', e) }}></DropDown>
                                                                {(touched['TipObjekta'] || newObject) && errors['TipObjekta'] && (
                                                                    <div className='validation'>*</div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Статус объекта</label>
                                                            <div className={'wrapper'}>
                                                                <DropDown value={values['Status']} items={StatusObjekta} readonly={readonly} onChange={(e: string) => { setFieldValue('Status', e) }}></DropDown>
                                                                {(touched['Status'] || newObject) && errors['Status'] && (
                                                                    <div className='validation'>*</div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Дата паспорта</label>
                                                            <DateInput readonly={readonly}
                                                                name={'DataPasporta'}
                                                                value={values['DataPasporta']}
                                                                onChange={(date: Date | null) => { setFieldValue('DataPasporta', date) }}
                                                            ></DateInput>
                                                        </Col>
                                                        <Col>
                                                            <label>Реестровая запись</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['ReestrZap']}
                                                                    onChange={(v: string | null) => { setFieldValue('ReestrZap', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Инв. номер</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['InventNomer']}
                                                                    onChange={(v: string | null) => { setFieldValue('InventNomer', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>

                                                    </Row>

                                                    <Row>
                                                        <Col className="col-8-of-5">
                                                            <label>Наименование</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['Naimenovanie']}
                                                                    onChange={(v: string | null) => { setFieldValue('Naimenovanie', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-2-of-5">
                                                            <label>Номер бокса</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['NomerBoksa']}
                                                                    onChange={(v: string | null) => { setFieldValue('NomerBoksa', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label>Адрес ФИАС</label>
                                                            <div className={'wrapper'}>
                                                                <Lookup readonly={readonly}
                                                                    onLookupClick={(id: string, obj: any) => { setFieldValue('Fias', obj) }}
                                                                    id={values['Fias']?.id || ''}
                                                                    initialVal={values['Fias']}
                                                                    dataLoaded={!loading}
                                                                    modelDescription={AdresFiasDesc}
                                                                    view={AdresFiasDesc.views.webL}
                                                                    autocompleteProp='AdresStrokoi'
                                                                    defaultSort={{ sortField: 'AdresStrokoi', sortOrder: 'asc' }}
                                                                    title={'Адрес ФИАС'}
                                                                    showObjectForm={(viewedId: string) => { return (<FiasItem id={viewedId} view={true} />) }}
                                                                ></Lookup>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label>Адрес КЛАДР</label>
                                                            <div className={'wrapper'}>
                                                                <Lookup readonly={readonly}
                                                                    onLookupClick={(id: string, obj: any) => { setFieldValue('Kladr', obj) }}
                                                                    id={values['Kladr']?.id || ''}
                                                                    initialVal={values['Kladr']}
                                                                    dataLoaded={!loading}
                                                                    modelDescription={AdresKladrDesc}
                                                                    view={AdresKladrDesc.views.webL}
                                                                    autocompleteProp='AdresStrokoi'
                                                                    defaultSort={{ sortField: 'AdresStrokoi', sortOrder: 'asc' }}
                                                                    title={'Адрес КЛАДР'}
                                                                    showObjectForm={(viewedId: string) => { return (<KladrItem id={viewedId} view={true} />) }}
                                                                ></Lookup>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <label>Описание адреса</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['PrimAdr']}
                                                                    onChange={(v: string | null) => { setFieldValue('PrimAdr', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col>
                                                            <label>Исполнитель</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['Ispolnitel']}
                                                                    onChange={(v: string | null) => { setFieldValue('Ispolnitel', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Реестр РКН</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['ReestrRKN']}
                                                                    onChange={(v: string | null) => { setFieldValue('ReestrRKN', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Стоимость</label>
                                                            <div className={'wrapper'}>
                                                                <NumberInput readonly={readonly} int={false}
                                                                    value={values['Stoimost']}
                                                                    onChange={(v: string) => { setFieldValue('Stoimost', v) }}
                                                                ></NumberInput>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Год стоимости</label>
                                                            <div className={'wrapper'}>
                                                                <NumberInput readonly={readonly}
                                                                    value={values['GodStoimosti']}
                                                                    onChange={(v: string) => { setFieldValue('GodStoimosti', v) }}
                                                                ></NumberInput>
                                                            </div>
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>

                                                    <Row>
                                                        <Col className="col-6-of-5">
                                                            <label>Вид тех. паспорта</label>
                                                            <div className={'wrapper'}>
                                                                <Lookup readonly={readonly}
                                                                    onLookupClick={(e: string) => { setFieldValue('TehPasport', e ? { id: e } : null) }}
                                                                    id={values['TehPasport']?.id || ''}
                                                                    initialVal={values['TehPasport']}
                                                                    dataLoaded={!loading}
                                                                    modelDescription={VidTehPasportaDesc}
                                                                    view={VidTehPasportaDesc.views.webL}
                                                                    autocompleteProp='Naimenovanie'
                                                                    defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                    title={'Вид тех. паспорта'}
                                                                    showObjectForm={(viewedId: string) => { return (<VidTehPasportaItem id={viewedId} view={true} />) }}
                                                                ></Lookup>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-4-of-5">
                                                            <label>Фонд</label>
                                                            <div className={'wrapper'}>
                                                                <Lookup readonly={readonly}
                                                                    onLookupClick={(e: string) => { setFieldValue('Fond', e ? { id: e } : null) }}
                                                                    id={values['Fond']?.id || ''}
                                                                    initialVal={values['Fond']}
                                                                    dataLoaded={!loading}
                                                                    modelDescription={FondDesc}
                                                                    view={FondDesc.views.webL}
                                                                    autocompleteProp='Naimenovanie'
                                                                    defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                    title={'Фонд'}
                                                                    showObjectForm={(viewedId: string) => { return (<FondItem id={viewedId} view={true} />) }}
                                                                ></Lookup>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col className="col-6-of-5">
                                                            <label>Исполнитель ОТУТИ</label>
                                                            <Input readonly={readonly} value={values['IspolnitelOTUTI']}
                                                                onChange={(v: string) => { setFieldValue('IspolnitelOTUTI', v) }}
                                                            ></Input>
                                                        </Col>
                                                        <Col className="col-4-of-5">
                                                            <label>База Росреестра</label>
                                                            <CheckBoxInput readonly={readonly} value={values['BDRosreestra']}
                                                                onChange={(v: boolean) => { setFieldValue('BDRosreestra', v) }}
                                                                className={"inline"}
                                                            ></CheckBoxInput>
                                                        </Col>
                                                    </Row>

                                                </Col>
                                            </Row>
                                            <Row className='edit-form-buttons'>
                                                <div className='text-center'>
                                                    {props.access.full && !readonly && !error &&
                                                        <Button size='sm' onClick={() => {
                                                            setShowValidation(true)
                                                            if (formRef.current) {
                                                                formRef.current.submitForm().then((data: any) => {
                                                                    if (formRef.current?.isValid && data)
                                                                        props.onSave(data.values)
                                                                })
                                                            }
                                                        }}>
                                                            Сохранить
                                                        </Button>}
                                                    <Button size='sm' onClick={() => { props.onClose(); }}> Закрыть </Button>
                                                    {saveError
                                                        ? <ClosableAlert className={'error'} text={saveError} onClose={() => setSaveError(null)} />
                                                        : ''
                                                    }
                                                </div>
                                            </Row>
                                        </div>
                                    }
                                </Form>
                            )}
                        </Formik>
                }
            </Row>
        </Container>
    );
}

const AdrPlanItem = (props: { onClose: () => void, onSave: (values: any) => void, edit: boolean, id: string | null }) => {
    return (
        <CheckAccessForm modelName={AdrPlanDesc.odataClassName}>
            {(access: { read: boolean, full: boolean }) => {
                return (
                    <AdrPlanForm access={access} onClose={props.onClose} onSave={props.onSave} edit={props.edit} id={props.id}></AdrPlanForm>
                )
            }}
        </CheckAccessForm>
    );
}

AdrPlanItem.defaultProps = {
    edit: false,
    onClose: () => { },
    onSave: () => { }
}

export default AdrPlanItem;