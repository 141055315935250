import { makeAutoObservable } from 'mobx';
import { UserStore } from './UserStore'
import { checkAccess } from '../http/SecurityApi';

export default class AccessStore {
    private _cache: any;
    private _userStore: UserStore;

    constructor(userStore: UserStore) {
        this._cache = {};
        this._userStore = userStore;

        makeAutoObservable(this)
    }

    check(modelName: string): Promise<{ read: boolean, full: boolean }> {
        let access = { read: false, full: false }
        let promises = []

        promises.push(this.checkFull(modelName)
            .then((full) => {
                access.full = full;

                if (!full) {
                    return this.checkRead(modelName).then((read) => { access.read = read })
                } else {
                    access.read = true
                }
            }))

        return Promise.allSettled(promises).then((results) => {
            return access
        })
    }

    checkRead(modelName: string): Promise<boolean> {
        if (this._userStore.authenticated) {
            const key = modelName + this._userStore.userName + 'Read'
            if (this._cache[key]) {
                return Promise.resolve(this._cache[key])
            } else {
                return checkAccess(modelName, 'Read').then((result) => {
                    this._cache[key] = result
                    return result
                })
            }
        } else {
            return Promise.resolve(false);
        }
    }

    checkFull(modelName: string): Promise<boolean> {
        if (this._userStore.authenticated) {
            const key = modelName + this._userStore.userName + 'Full'
            if (this._cache[key]) {
                return Promise.resolve(this._cache[key])
            } else {
                return checkAccess(modelName, 'Full').then((result) => {
                    this._cache[key] = result
                    return result
                })
            }
        } else {
            return Promise.resolve(false);
        }
    }

}