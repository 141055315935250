import React from "react";

interface Props {
    onChange: any
    onKeyDown?: any
    value?: string | null
    readonly: boolean
    className?: string
}

const Input = (props: Props) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const v = e.target.value
        props.onChange(v);
    }

    return (
        props.readonly
            ? <div className={'readonly'}>{props.value || "-"}</div>
            : <input className={props.className} type="text" onChange={handleChange} onKeyDown={props.onKeyDown} value={props.value || ""} />
    );
}

Input.defaultProps = {
    readonly: false
};

export default Input;