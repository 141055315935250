import { $securityHost } from "./index";

export const checkAccess = async (edmTypeName: string, accessType: string): Promise<boolean> => {
    const { data } = await $securityHost.get(`model/${edmTypeName}/${accessType}`, { })
    return data
}

export const checkOperation = async (operationName: string): Promise<boolean> => {
    const { data } = await $securityHost.get(`model/operation/${operationName}`, { })
    return data
}