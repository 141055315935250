import { DataObjectDescription, PartialRecord, PropertyDescriptions, ViewProperty } from "../../descriptions/DescriptionTypes";
import List from '../List'

interface Props<T extends PropertyDescriptions> {
    onLookupClick: any,
    view: PartialRecord<keyof T, ViewProperty>
    modelDescription: DataObjectDescription<T>
    defaultSort?: { sortField: string, sortOrder: 'asc' | 'desc' } 
}

const LookupForm = <T extends PropertyDescriptions>(props: Props<T>) => {
    return (
        <List defaultSort={props.defaultSort} toolbar={false} view={props.view} modelDescription={props.modelDescription} onRowClick={props.onLookupClick}></List>
    );

}

export default LookupForm;