import React, { useEffect, useState } from "react";
import { Button, Row, Stack } from "react-bootstrap";
import { IWithWebFile } from "../../descriptions/DescriptionTypes";
import { getIcon } from './WebFilesControl'
import './web-files-control.css';

export interface IOrdered extends IWithWebFile {
    index: number
    preview?: any
}

export interface IChecked {
    checked: boolean,
    index: number,
    order: number
}

interface ICheckedObject {
    [k: string]: IChecked
}

interface Props {
    files?: IWithWebFile[] | null,

    onClose: any,
    onSubmit: any,
    submitTitle: string,
    closeTitle: string,

    preview?: { [k: string]: any } | null
}

const WebFileSelect = (props: Props) => {
    const [files, setFiles] = useState<IOrdered[]>([])
    const [checked, setChecked] = useState<ICheckedObject>({})
    const [error, setError] = useState<string | null>(null)
    const [preview, setPreview] = useState<string | null>(null)

    useEffect(() => {
        if (props.files && props.files.length > 0) {
            let sorted = props.files.sort((a, b) => { return a.Fajl.fileName === b.Fajl.fileName ? 0 : a.Fajl.fileName > b.Fajl.fileName ? 1 : -1; })
                .map((f: IWithWebFile, index: number) => {
                    return Object.assign(f, { index: index, preview: null })
                });

            setFiles(sorted)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function Click(id: string, index: number, e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        const check = (e.target as HTMLInputElement).checked;
        let c: ICheckedObject = Object.assign({}, checked)

        // Найдем объект, по которому щелкали в последний раз
        let lastIndex = 0;
        let order = 0;
        let sortedByOrder = Object.entries(c).map(([, value]) => { return value }).sort((a, b) => b.order - a.order);
        if (sortedByOrder && sortedByOrder.length > 0) {
            lastIndex = sortedByOrder[0].index
            order = sortedByOrder[0].order
        }

        if (e.shiftKey) {
            // порядок не важен, надо выбрать все  с последнего по текущий. Только выделяем, не сбрасываем
            for (let i = lastIndex; (lastIndex <= index && i <= index) || (lastIndex >= index && i >= index); lastIndex <= index ? i++ : i--) {
                let curId = files.filter(f => f.index === i)[0].id
                c[curId] = { checked: true, index: i, order: ++order }
            }

            setChecked(c)
        } else {
            if (!check && id in c) delete c[id];
            if (check) {
                c[id] = { checked: true, index: index, order: ++order }
            }

            setChecked(c)
        }
    }

    const getSidebarPreview = (previewData: any) => {
        const size = 300;
        if (previewData) return <img src={previewData} width={size} alt=""></img>
    }

    return (
        <div className="web-files-control">
            <fieldset>
                <legend>Файлы {error && <span className={'alarm'}>{error}</span>}</legend>

                <div>
                    <span className={'select-link'} onClick={() => { setChecked(Object.fromEntries(files.map((f, i) => { return [f.id, { checked: true, index: f.index, order: i }] }))) }}>Выбрать все</span><span className={'select-link'} onClick={() => { setChecked({}) }}>Снять выделение</span>
                </div>
                <div className="web-files-control">
                    <Stack direction="horizontal" gap={4} className='fieldset-content col'>
                        {files && files.map((f: any) => {
                            return (
                                <div key={f.id} className={"web-file"}>
                                    <div id={f.id}>
                                        {getIcon(f.Fajl.fileName, props.preview ? props.preview[f.id] : null, false)}
                                    </div>

                                    {props.preview && props.preview[f.id] && <span className="web-file-preview" onClick={() => { setPreview(f.id) }}>
                                        <i className="icon-guideline-search-r"></i>
                                    </span>}

                                    <span className="web-file-check" >
                                        <input type="checkbox" className="age-checkbox"
                                            checked={checked[f.id]?.checked || false}
                                            onChange={() => { }}
                                            onClick={
                                                (e) => {
                                                    Click(f.id, f.index, e)
                                                }
                                            } />
                                    </span>

                                    <div className={'web-filename'} title={f.Fajl.fileName}>
                                        {f.Fajl.fileName}
                                    </div>
                                </div>
                            )
                        })}
                    </Stack>
                    <div className="right-sidebar">
                        {preview && props.preview && props.preview[preview]
                            ? <div className="sidebar-preview">
                                {getSidebarPreview(props.preview ? props.preview[preview] : null)}
                            </div>
                            : <span>Выберите файл для предварительного просмотра</span>}
                    </div>
                </div>

            </fieldset>
            <Row className='edit-form-buttons'>
                <div className='text-center'>
                    <Button size='sm' onClick={() => {
                        let keys = Object.entries(checked).filter(([key, value]) => { return value.checked }).map(([key, value]) => { return key });
                        if (keys.length > 0) {
                            setError(null)
                            props.onSubmit(keys)
                        } else {
                            setError('Для формирования документа нужно выбрать хотя бы один файл')
                        }
                    }}>
                        {props.submitTitle}
                    </Button>

                    <Button size='sm' onClick={() => { props.onClose() }}>
                        {props.closeTitle}
                    </Button>
                </div>
            </Row>
        </div>
    );
}

WebFileSelect.defaultProps = {
};

export default WebFileSelect;
