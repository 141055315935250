import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";

interface Props {
    onClose: any
    text: string
    className?: string
}

const ClosableAlert = (props: Props) => {
    const [show, setShow] = useState(true)

    const timer = useRef<NodeJS.Timeout | null>(null);

    const [second, setSecond] = useState(5)
    const secondRef = useRef<number>(second);

    useEffect(() => {
        secondRef.current = second;
    }, [second]);

    useEffect(() => {
        const callback = () => {
            if (secondRef.current > 1) {
                setSecond((v) => v - 1)
                timer.current = setTimeout(callback, 1000);
            } else {
                onClose()
            }
        }

        timer.current = setTimeout(callback, 1000);

        return () => {
            if (timer.current) clearInterval(timer.current);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onClose = () => {
        if (timer.current) clearTimeout(timer.current)
        setShow(false)
        props.onClose()
    }

    return (
        <Modal
            show={show}
            className={props.className}
            onHide={() => onClose()}
            size="lg"
            centered>
            <Modal.Header closeButton>
                {props.text} ({second})
            </Modal.Header>
        </Modal>
    );
}

export default ClosableAlert;