import { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom"
import { useQuery } from "../hooks/useQuery"
import List from '../components/List'
import CheckAccessForm from '../components/CheckAccessForm'
import { TehnicheskoeDeloDesc } from '../descriptions/Descriptions';
import { ComplexPredicate, Filter, Condition, Predicate, SimplePredicate, ContainsPredicate } from "../utils/filters";
import Input from '../components/Input'
import DropDown from '../components/DropDown'
import { TipObjekta } from '../descriptions/Enums'
import * as Routes from '../utils/consts';
import CheckBoxInput from '../components/CheckBoxInput'

interface ISearch {
    adres?: string | null
    tip?: string | null
    invN?: string | null
    ros?: boolean
}

const TehnicheskoeDeloList = () => {
    const history = useHistory()
    const route = Routes.TD_ROUTE

    const query = useQuery();

    const p: ISearch = { adres: query.get('adres'), tip: query.get('tip'), invN: query.get('invN'), ros: query.get('ros') === 'true' }

    const [search, setSearch] = useState<ISearch>(p)

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setFilter(updatedFilter())
        }
    };

    const clearFilter = () => {
        setSearch({ adres: null, invN: null, tip: null, ros: false })
    }

    const updatedFilter = () => {
        let predicates = []

        if (search.adres) {
            const adr = search.adres.replaceAll(' ', '*');
            predicates.push(new ComplexPredicate(Condition.Or,
                [new ContainsPredicate('AdrPlan.AdresStrokoi', adr),
                new ContainsPredicate('AdrPlan.AdresStrokoi', adr)]));
        }

        if (search.tip)
            predicates.push(new SimplePredicate('AdrPlan.TipObjekta', Filter.Eq, search.tip))

        if (search.invN)
            predicates.push(new SimplePredicate('AdrPlan.InventNomer', Filter.Eq, search.invN))

        if (search.ros)
            predicates.push(new SimplePredicate('AdrPlan.BDRosreestra', Filter.Eq, true))

        if (predicates.length > 0) {
            return new ComplexPredicate(Condition.And, predicates)
        } else {
            return undefined
        }
    }

    const [filter, setFilter] = useState<Predicate | undefined>(updatedFilter())

    const getDefaultPage = (): number => {
        const p = parseInt(query.get('page') || '');
        if (isNaN(p)) { return 1; }

        return p
    }

    const getReturnInfo = (page: number): string => {
        let queryObject: any = {}

        Object.entries(Object.assign(search as any || {}, { page: page })).forEach(([key, val]) => {
            if (val) {
                queryObject[key] = val
            }
        })

        return encodeURIComponent(new URLSearchParams(queryObject).toString())
    }

    return (
        <CheckAccessForm modelName={TehnicheskoeDeloDesc.odataClassName}>
            {(access: { read: boolean, full: boolean }) => {
                return (
                    <Container fluid className={'list-form'}>
                        <Row className='list-form-title'><div>Инвентарные дела</div></Row>
                        <Row className='list-form-search'>
                            <Col sm={2}>
                                <label>Адрес</label>
                                <Input value={search.adres}
                                    onChange={(v: string | null) => { setSearch({ ...search, adres: v }) }}
                                    onKeyDown={onKeyDown}
                                ></Input>
                            </Col>
                            <Col sm={2}>
                                <label>Тип объекта</label>
                                <DropDown value={search.tip} items={TipObjekta} onChange={(e: string) => { setSearch({ ...search, tip: e }) }}></DropDown>
                            </Col>
                            <Col sm={2}>
                                <label>Инвентарный номер</label>
                                <Input value={search.invN}
                                    onChange={(v: string | null) => { setSearch({ ...search, invN: v }) }}
                                    onKeyDown={onKeyDown}
                                ></Input>
                            </Col>
                            <Col className={"fixed-125"}>
                                <label>БД Росреестра</label>
                                <CheckBoxInput value={search.ros} className={"inline"}
                                    onChange={(v: boolean | null) => { setSearch({ ...search, ros: v || false }); }}
                                ></CheckBoxInput>
                            </Col>
                            <Col sm={2} className="align-self-end toolbar-custom-buttons">
                                <Button size="sm" onClick={() => { setFilter(updatedFilter()) }}>
                                    Найти
                                </Button>
                                <Button size="sm" className='btn-danger' onClick={() => { clearFilter(); }}>
                                    Очистить
                                </Button>
                            </Col>
                        </Row>

                        <List filter={filter} access={access} route={route} defaultSort={{ sortField: 'AdrPlan.InventNomer', sortOrder: 'asc' }}
                            defaultPage={getDefaultPage()}
                            view={TehnicheskoeDeloDesc.views.webL}
                            modelDescription={TehnicheskoeDeloDesc}
                            onRowClick={(info: any, page: number) => { history.push(route + '/' + info.id + '?returnInfo=' + getReturnInfo(page)) }}></List>
                    </Container>
                )
            }}
        </CheckAccessForm>
    );
}

export default TehnicheskoeDeloList;
