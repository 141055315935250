import { Switch, Route } from 'react-router-dom';

import {observer} from "mobx-react-lite";
import { useContext } from "react";
import {Context} from "../index";

import { publicRoutes, authRoutes } from '../routes'

const Content = observer(() => {

  const context = useContext(Context)  

  return (
    <div className='content'>
      <Switch>
        {context?.user?.authenticated && authRoutes.map(({ path, Component }) =>
          <Route key={path} path={path} component={Component} exact />
        )}
        {publicRoutes.map(({ path, Component }) =>
          <Route key={path} path={path} component={Component} exact />
        )}
      </Switch>
    </div>
  );
})

export default Content;          