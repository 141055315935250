interface Props {
    onChange: any
    value?: string | Number | null
    readonly: boolean
    className?: string
    int: boolean
}

const NumberInput = (props: Props) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const pattern = props.int ? /[^0-9]/ig : /[^0-9.]/ig
        let v = e.target.value.replaceAll(',', '.').replaceAll(pattern, '')

        // если несколько точек
        if (v.replaceAll(/[^.]/ig, '').length > 1) {
            v = props.value?.toString() || ''
        }

        if (v) props.onChange(v);
        else props.onChange(null);
    }

    return (
        props.readonly
            ? <div className={'readonly'}>{props.value?.toString() || "-"}</div>
            : <input className={props.className} type="text" onChange={handleChange} value={props.value?.toString() || ""} />
    );
}

NumberInput.defaultProps = {
    int: true
}

export default NumberInput;