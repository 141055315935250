
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchOneOdata } from '../http/ODataApi'
import { useHistory } from 'react-router'

import Input from '../components/Input'
import CheckBoxInput from '../components/CheckBoxInput'

import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'

import { Formik, Form, FormikProps, FormikValues } from 'formik';

import * as Icon from 'react-bootstrap-icons';
import CheckAccessForm from '../components/CheckAccessForm'

import { EtazhiDesc } from '../descriptions/Dictionaries';

import { State } from '../descriptions/State'

import ClosableAlert from '../components/ClosableAlert'

import * as Routes from '../utils/consts';

import { save, saveAndClose, reload } from '../utils/pageHelpers'

interface Params {
    id?: string
}

const EtazhiForm = (props: { access: { read: boolean, full: boolean }, id?: string | null, lookupForm?: boolean }) => {
    const params = useParams<Params>()
    
    const [objectId, setObjectId] = useState<string | null>(props.id || params.id || null)
    const [newObject, setNewObject] = useState<boolean>(!objectId)

    const [loading, setLoading] = useState(true)
    const [showValidation, setShowValidation] = useState(false)

    const [readonly, setReadonly] = useState(!(newObject && props.access.full))

    const [error, setError] = useState<string | null>(null)
    const [saveError, setSaveError] = useState<string | null>(null)
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
    const [item, setItem] = useState<any>()

    const history = useHistory()
    const route = Routes.ETAZHI_ROUTE

    const formRef = useRef<FormikProps<FormikValues>>(null)

    useEffect(() => {
        setLoading(true)

        const id = props.id || params.id

        if (id) {
            fetchOneOdata(EtazhiDesc, EtazhiDesc.views.webE, id).then((result: any | null) => {
                setItem(result || {});
                setLoading(false)
            }).catch(() => {
                setLoading(false)
                setError('Невозможно получить данные')
            });
        } else {
            setItem(EtazhiDesc.defaultValues);
            setLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.Naimenovanie) {
            errors.Naimenovanie = 'Required';
        }

        return errors;
    };

    function onSubmit(fields: any, actions: any): Promise<any> {
        return EtazhiDesc.save({ state: newObject ? State.created : State.altered, item: fields }).then((data) => {
            actions.setSubmitting(false);

            if (newObject) {
                setObjectId(data.id)
                setNewObject(false)
            }

            setItem(data)
            formRef.current?.setValues(data)
            formRef.current?.resetForm({
                values: data
            })

            setSaveSuccess(true)
            setReadonly(true)

            return { newObject: newObject, route: route, id: data.id }
        }).catch((e) => {
            console.error(e)
            setSaveError('Произошла ошибка при сохранении')
        }).finally(() => {
            actions.setSubmitting(false);
        })
    }

    return (
        <Container fluid className={'edit-form'}>
            {!props.lookupForm && <Row className='edit-form-title'><div>Вид этажа</div></Row>}
            {!props.lookupForm && <Row className='edit-form-buttons'>
                <div className='edit-form-toolbar'>
                    <div className="toolbar-default-buttons">
                        <Button variant='light' size='sm' title={'К списку'} onClick={() => { history.push(route) }}>
                            <i className='icon-guideline-Arrows-max-left'></i>
                        </Button>
                        <div className='divider'></div>
                        <Button variant='light' size='sm' title={'Обновить'} onClick={() => reload(history, route + '/' + (objectId || 'new'))}>
                            <i className='icon-guideline-refresh-r'></i>
                        </Button>
                        {props.access.full && readonly && <div className='divider'></div>}
                        {props.access.full && readonly &&
                            <Button variant='light' size='sm' onClick={() => { setReadonly(false) }}>
                                <i className="icon-guideline-edit-r"></i>Редактировать
                            </Button>}
                    </div>
                    {props.access.full && !readonly &&
                        <div className="toolbar-custom-buttons">
                            <Button size='sm' onClick={() => { setShowValidation(true); save(formRef, history, null) }}>
                                Сохранить
                            </Button>
                            <Button size='sm' onClick={() => { setShowValidation(true); saveAndClose(formRef, history, route) }}>
                                Сохранить и закрыть
                            </Button>
                            <div className={'error'}>{saveError}</div>
                        </div>}
                </div>
            </Row>}
            <Row className='edit-form-content'>
                {loading
                    ? <Container fluid className='d-flex justify-content-center save-loader'><Spinner animation='border' variant='primary' aria-flowto='' />Загрузка...</Container>
                    : error !== null
                        ? <div>{error}</div>
                        :
                        <Formik innerRef={formRef} enableReinitialize={true} initialValues={item || {}} validateOnMount={true} validate={validate} onSubmit={onSubmit}>
                            {({ errors, values, touched, setFieldValue, isSubmitting }) => (
                                <Form>
                                    {isSubmitting
                                        ? <Container fluid className='save-loader d-flex justify-content-center'><Spinner animation='border' variant='primary' aria-flowto='' />Сохранение...</Container>
                                        : <Row>
                                            <Col sm={6}>
                                                <Row>
                                                    <label>Наименование</label>
                                                    <div className={'wrapper'}>
                                                        <Input readonly={readonly}
                                                            className={showValidation && errors['Naimenovanie'] ? 'error' : ''}
                                                            value={values['Naimenovanie']}
                                                            onChange={(v: string | null) => { setFieldValue('Naimenovanie', v) }}
                                                        ></Input>
                                                        {(touched['Naimenovanie'] || newObject) && errors['Naimenovanie'] && (
                                                            <div className='validation'>*</div>
                                                        )}
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <Col sm={6}>
                                                        <label>Предложный</label>
                                                        <div className={'wrapper'}>
                                                            <Input readonly={readonly}
                                                                value={values['NaimenovaniePP']}
                                                                onChange={(v: string | null) => { setFieldValue('NaimenovaniePP', v) }}
                                                            ></Input>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <label>Родительный</label>
                                                        <div className={'wrapper'}>
                                                            <Input readonly={readonly}
                                                                value={values['NaimenovanieRP']}
                                                                onChange={(v: string | null) => { setFieldValue('NaimenovanieRP', v) }}
                                                            ></Input>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={6}>
                                                        <label>Сокращенное</label>
                                                        <div className={'wrapper'}>
                                                            <Input readonly={readonly}
                                                                value={values['NaimenovanieSokr']}
                                                                onChange={(v: string | null) => { setFieldValue('NaimenovanieSokr', v) }}
                                                            ></Input>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <label></label>
                                                        <div className={'wrapper'}>
                                                            <Input readonly={readonly}
                                                                value={values['NaimenovanieSokr2']}
                                                                onChange={(v: string | null) => { setFieldValue('NaimenovanieSokr2', v) }}
                                                            ></Input>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <label>Актуально</label>
                                                    <div className={'wrapper'}>
                                                        <CheckBoxInput readonly={readonly}
                                                            value={values['Aktualno']}
                                                            onChange={(v: boolean | null) => { setFieldValue('Aktualno', v) }}
                                                        ></CheckBoxInput>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                    }
                                </Form>
                            )}
                        </Formik>
                }
            </Row>
        </Container>
    );
}

const EtazhiItem = (props: { view: boolean, id?: string | null }) => {
    return (
        <CheckAccessForm modelName={EtazhiDesc.odataClassName} onlyView={props.view}>
            {(access: { read: boolean, full: boolean }) => {
                return (
                    <EtazhiForm access={access} lookupForm={props.view} id={props.id}></EtazhiForm>
                )
            }}
        </CheckAccessForm>
    );
}

EtazhiItem.defaultProps = {
    view: false,
    id: null
}

export default EtazhiItem;