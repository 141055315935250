
import List from '../components/List'
import ListToolbar from '../components/ListToolbar'
import CheckAccessForm from '../components/CheckAccessForm'
import { Container, Row } from 'react-bootstrap';
import * as Routes from '../utils/consts';
import { useHistory } from 'react-router-dom'
import { KontragentDesc } from '../descriptions/Dictionaries';

const KontragentList = () => {
    const history = useHistory()
    const route = Routes.KONTRAGENT_ROUTE

    return (
        <CheckAccessForm modelName={KontragentDesc.odataClassName}>
            {(access: { read: boolean, full: boolean }) => {
                return (
                    <Container fluid className={'list-form'}>
                        <Row className='list-form-title'><div>Физ./юр. лица</div></Row>

                        <List defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }} access={access} route={route}
                            view={KontragentDesc.views.webL}
                            modelDescription={KontragentDesc}
                            onRowClick={(info: any) => history.push(route + '/' + info.id)}></List>
                    </Container>
                )
            }}
        </CheckAccessForm>
    );
}

export default KontragentList;