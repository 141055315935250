import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dictionariesLinks } from '../routes'

const Dictionaries = () => {
  return (
    <Container fluid>
      <Row className='list-form-title'><div>Справочники</div></Row>
      <Row className='dictionary-block'>
        <div className='col-md-5'>
          {dictionariesLinks.map((group, i) => {
            if (i <= 1) return (<fieldset key={i}>
              <legend key={i}>{group.title}</legend>
              <div className='dictionary-inner-block'>
                {group.nodes.map((node, j) => {
                  return (
                    <Link key={j} to={node.path} className="dictionary-link"><div>{node.caption}</div></Link>
                  )
                })}
              </div>
            </fieldset>)
          })}
        </div>
        <div className='col-md-5'>
          {dictionariesLinks.map((group, i) => {
            if (i > 1) return (<fieldset key={i}>
              <legend key={i}>{group.title}</legend>
              <div className='dictionary-inner-block'>
                {group.nodes.map((node, j) => {
                  return (
                    <Link key={j} to={node.path} className="dictionary-link"><div>{node.caption}</div></Link>
                  )
                })}
              </div>
            </fieldset>)
          })}
        </div>
      </Row>
    </Container>
  );
}

export default Dictionaries;