import { DataObjectDescription, DataObjectPropertyDescription, EnumPropertyDescription, PropertyDescriptions } from "../descriptions/DescriptionTypes";

export enum Filter {
    Eq = 'eq',
    Neq = 'ne'
}

export enum Condition {
    And = 'and',
    Or = 'or'
}

export interface Predicate {
    build: (d: DataObjectDescription<PropertyDescriptions>) => string
}

export class SimplePredicate implements Predicate {
    filter: Filter
    property: string
    value: any

    constructor(p: string, f: Filter, v: any) {
        this.filter = f;
        this.property = p;
        this.value = v;
    }

    build(d: DataObjectDescription<PropertyDescriptions>) {
        const buildProp = (prop: string, desc: DataObjectDescription<PropertyDescriptions>): string => {

            if (prop.indexOf('.') > -1) {
                const masterName = prop.split('.')[0]
                const master = desc.properties[masterName]
                if (!master || master.type !== 'lookup') return '';

                return (master.sourceKey || masterName) + '/' + buildProp(prop.replace(masterName + '.', ''), (master as DataObjectPropertyDescription).odata)

            } else {
                const p = desc.properties[prop];
                if (!p) {
                    console.error('Не найдено свойство ' + prop + ' в классе ' + desc.odataClassName)
                    return '';
                }

                const name = p.sourceKey || prop
                let condition = ''

                switch (p.type) {
                    case 'lookup':
                        //User/__PrimaryKey+eq+ee5f2ade-765e-4439-89a7-ceca40022f04
                        condition = name + '/__PrimaryKey+' + this.filter + ' ' + this.value
                        break;
                    case 'text':
                        condition = name + ' ' + this.filter + ' \'' + this.value + '\''
                        break;
                    case 'bool':
                        condition = name + ' ' + this.filter + ' ' + (this.value ? 'true' : 'false')
                        break;
                    case 'enum':
                        //State+ne+Iis.PubIsogd.tRequestState'Архив'
                        condition = name + ' ' + this.filter + ' ' + (p as EnumPropertyDescription).backendName + '\'' + this.value + '\''
                        break;
                }

                return condition;
            }
        }

        return buildProp(this.property, d)
    }
}

export class ContainsPredicate implements Predicate {
    property: string
    value: any

    constructor(p: string, v: any) {
        this.property = p;
        this.value = v;
    }

    build(d: DataObjectDescription<PropertyDescriptions>) {

        const buildProp = (prop: string, desc: DataObjectDescription<PropertyDescriptions>, prefix: string): string => {

            if (prop.indexOf('.') > -1) {
                const masterName = prop.split('.')[0]
                const master = desc.properties[masterName]
                if (!master || master.type !== 'lookup') return '';

                return buildProp(prop.replace(masterName + '.', ''), (master as DataObjectPropertyDescription).odata, (master.sourceKey || masterName) + '/')

            } else {
                const p = desc.properties[prop];
                if (!p) {
                    console.error('Не найдено свойство ' + prop + ' в классе ' + desc.odataClassName)
                    return '';
                }

                const name = p.sourceKey || prop

                return 'contains(' + prefix + name + ', \'' + this.value + '\')'
            }
        }

        return buildProp(this.property, d, '')
    }
}

export class ComplexPredicate implements Predicate {
    condition: Condition
    predicates: Predicate[]

    constructor(c: Condition, p: Predicate[]) {
        this.condition = c;
        this.predicates = p;
    }

    build(d: DataObjectDescription<PropertyDescriptions>) {
        return '(' + this.predicates.map((p) => {
            return p.build(d)
        }).join(' ' + this.condition + ' ') + ')'
    }
}