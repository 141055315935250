import React, { useEffect, useState } from "react";
import { Table, Row, Col, Button } from "react-bootstrap";
import NumberInput from './NumberInput';
import Lookup from './Lookup/Lookup';

import { v4 } from 'uuid';
import { State } from '../descriptions/State'

import { DataObjectDescription, DataObjectPropertyDescription, DataObjectViewProperty, IDataObjectChangedElement, PartialRecord, PropertyDescriptions, ViewProperty } from "../descriptions/DescriptionTypes";
import * as Icon from 'react-bootstrap-icons';
import DateInput from "./DateInput";
import Input from "./Input";
import { FormatValue } from "../utils/formats";

interface Props<T extends PropertyDescriptions> {
    onChange: any,
    items?: T[] | null,
    view: PartialRecord<keyof T, ViewProperty>
    modelDescription: DataObjectDescription<T>
    dataLoaded: boolean
    readonly: boolean
    title?: string
    headers?: boolean
    lookupSettings?: { [key: string]: { showObjectForm?: (id: string) => React.ReactElement, title?: string } }
    errors: any
    showErrors: boolean
}

const GroupEdit = <P extends PropertyDescriptions>(props: Props<P>) => {
    const [items, setItems] = useState<IDataObjectChangedElement[]>([])
    const [checked, setChecked] = useState<any>({})

    useEffect(() => {
        if (props.dataLoaded && props.items && props.items.length > 0) {
            setItems(props.items.map((i) => { return { state: State.unAltered, item: i } as IDataObjectChangedElement }))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataLoaded])

    function Add() {
        const newItems = items.concat([{ state: State.created, item: { id: v4() } } as IDataObjectChangedElement])
        setItems(newItems)
        props.onChange(newItems)
    }

    function Remove() {
        const ids = Object.keys(checked)

        let arr = [...items].filter((i) => {
            return ids.indexOf(i.item.id) < 0 || i.state !== State.created
        })

        ids.forEach((id: string) => {
            const i = arr.find((i) => { return i.item.id === id })
            if (i) {
                i.state = State.deleted
            }
        })

        setItems(arr)

        if (props.onChange) {
            props.onChange(arr)
        }
    }

    function Check(id: string, check: boolean) {
        let c: any = Object.assign({}, checked)
        if (!check && id in c) delete c[id];
        if (check) c[id] = true
        setChecked(c)
    }

    function updateValue(id: string, field: keyof P, value: any) {
        let arr = [...items]
        const i = arr.find((i) => { return i.item.id === id })
        if (i) {
            i.item[field] = value;
            i.state = i.state === State.unAltered ? State.altered : i.state
        }

        setItems(arr)
        if (props.onChange) {
            props.onChange(arr)
        }
    }

    function updateObject(id: string, field: keyof P, value: string) {
        let arr = [...items]
        const i = arr.find((i) => { return i.item.id === id })
        if (i) {
            i.item[field] = { id: value }
            i.state = i.state === State.unAltered ? State.altered : i.state
        }

        setItems(arr)
        if (props.onChange) {
            props.onChange(arr)
        }
    }

    return (
        <div>
            <fieldset>
                <legend>{props.title}</legend>
                <div className="fieldset-content groupEdit list-without-border">
                    {!props.readonly && <div className="groupedit-toolbar">
                            <Button variant='' className="icon-btn" title={'Добавить'} onClick={() => Add()}><i className="icon-guideline-plus-r"></i></Button>
                            <Button variant='' className="icon-btn" title={'Удалить'} onClick={() => Remove()}><i className="icon-guideline-minus-r"></i></Button>
                        </div>}
                    <Row>
                        {!props.readonly

                            ? <Table bordered responsive>

                                {props.headers
                                    ?
                                    <thead>
                                        <tr className={props.readonly ? 'readonly' : ''}>
                                            {!props.readonly && <th style={{ width: "30px" }} key="0" className='hidden'></th>}
                                            {Object.values(props.view).filter((value) => { return (value as ViewProperty).visible }).map((p: ViewProperty | undefined, index) => {
                                                if (!p) return ('')

                                                return (<th key={index}>{p.caption}</th>)
                                            })}
                                        </tr>
                                    </thead>
                                    : ''
                                }

                                <tbody>
                                    {items.filter((item) => { return item.state !== State.deleted }).map((item: IDataObjectChangedElement) => {
                                        return (
                                            <tr key={item.item.id} className={props.readonly ? 'readonly' : ''}>
                                                {!props.readonly && <td style={{ width: "30px" }}>
                                                    <div className="checkbox">
                                                        <input type="checkbox" className="age-checkbox" onChange={(e) => { Check(item.item.id, e.target.checked) }} />
                                                        <label></label>
                                                    </div>
                                                </td>}

                                                {Object.entries(props.view).filter(([, value]) => { return (value as ViewProperty).visible }).map(([field, value]) => {
                                                    if (!value) return ('')

                                                    const p = item.item[field]

                                                    const id = p instanceof Object ? p.id : ''

                                                    const type = props.modelDescription.properties[field].type

                                                    let lookupDescription = new DataObjectDescription('', {}, {})
                                                    let lookupProperty = new DataObjectViewProperty('')
                                                    if (props.modelDescription.properties[field] instanceof DataObjectPropertyDescription && value instanceof DataObjectViewProperty) {
                                                        lookupDescription = (props.modelDescription.properties[field] as DataObjectPropertyDescription)?.odata
                                                        lookupProperty = (value as DataObjectViewProperty)
                                                    }

                                                    const className = props.showErrors && props.errors[item.item.id] && props.errors[item.item.id][field] ? 'error' : ''

                                                    const container =
                                                        type === 'number'
                                                            ? <NumberInput className={className} readonly={props.readonly} value={p as Number}
                                                                onChange={(v: string) => { updateValue(item.item.id, field as (keyof P), v) }}></NumberInput>
                                                            : type === 'date'
                                                                ? <DateInput className={className} readonly={props.readonly}
                                                                    value={p as Date}
                                                                    onChange={(date: Date | null) => { updateValue(item.item.id, field as (keyof P), date) }}
                                                                ></DateInput>
                                                                : type === 'lookup'
                                                                    ? <Lookup className={className} readonly={props.readonly}
                                                                        onLookupClick={(e: string) => { updateObject(item.item.id, field as (keyof P), e) }}
                                                                        modelDescription={lookupDescription}
                                                                        view={lookupProperty.expand || {}}
                                                                        dataLoaded={props.dataLoaded}
                                                                        title={props.lookupSettings && props.lookupSettings[field] ? props.lookupSettings[field].title : ''}
                                                                        id={id}
                                                                        initialVal={p}
                                                                        autocompleteProp={lookupProperty.property || ''}
                                                                        showObjectForm={props.lookupSettings && props.lookupSettings[field] ? props.lookupSettings[field].showObjectForm : undefined}
                                                                    ></Lookup>
                                                                    : <Input value={p || ''} onChange={(v: string | null) => { updateValue(item.item.id, field as (keyof P), v) }}></Input>

                                                    return (<td key={field}>
                                                        <div className={'wrapper'}>
                                                            {container}
                                                            {props.errors[item.item.id] && props.errors[item.item.id][field]
                                                                && (<span className='validation'>*</span>)}
                                                        </div>
                                                    </td>)
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>

                            : <div>
                                {items.length > 0
                                    ? items.filter((item) => { return item.state !== State.deleted }).map((item: IDataObjectChangedElement, i) => {
                                        return (
                                            <div key={i} className="fieldset-content"><Row key={i}>
                                                {items.length > 1 && <div className="fieldset-content-header">
                                                    <label>{props.title} {++i}</label>
                                                </div>}
                                                {Object.entries(props.view).filter(([, value]) => { return (value as ViewProperty).visible }).map(([field, value]) => {
                                                    if (!value) return ('')

                                                    const p = item.item[field]

                                                    const type = props.modelDescription.properties[field].type

                                                    let lookupVal = ''
                                                    if (props.modelDescription.properties[field] instanceof DataObjectPropertyDescription && value instanceof DataObjectViewProperty) {
                                                        const prop = (value as DataObjectViewProperty)?.property
                                                        lookupVal = prop && p ? p[prop] : ''
                                                    }

                                                    const text =
                                                        type === 'lookup'
                                                            ? lookupVal
                                                            : FormatValue(p)

                                                    return (<Col className='col-2-of-5' key={field}>
                                                        <div className={'title'}>
                                                            <label>{value.caption}</label>
                                                        </div>
                                                        <div className={'value'}>
                                                            {text || "-"}
                                                        </div>
                                                    </Col>)
                                                })}
                                            </Row>
                                            </div>

                                        )
                                    })
                                    : <div className={'noData'}>Нет данных</div>}
                            </div>
                        }
                    </Row>
                </div>
            </fieldset>
        </div>
    );
}

GroupEdit.defaultProps = {
    headers: true
}

export default GroupEdit;