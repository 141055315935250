
import { useEffect, useRef, useState } from 'react'
import { fetchOneOdata } from '../http/ODataApi'

import Input from '../components/Input'
import DateInput from '../components/DateInput'
import Lookup from '../components/Lookup/Lookup'

import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'

import { Formik, Form, FormikProps, FormikValues } from 'formik';

import CheckAccessForm from '../components/CheckAccessForm'

import { SobstvennikDesc } from '../descriptions/Descriptions';

import { TipVladeniyaDesc, VidSobstvennostiDesc, KontragentDesc } from '../descriptions/Dictionaries';

import { State } from '../descriptions/State'

import ClosableAlert from '../components/ClosableAlert'
import KontragentItem from './KontragentItem'
import TipVladeniyaItem from './TipVladeniyaItem'
import VidSobstvItem from './VidSobstvItem'

interface Props {
    access: { read: boolean, full: boolean }
    onClose: () => void
    onSave: (values: any) => void
    edit: boolean
    id: string | null
    tehDelo: string
}

const SobstvennikForm = (props: Props) => {
    const newObject = !props.id;

    const [loading, setLoading] = useState(true)
    const [showValidation, setShowValidation] = useState(false)

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [readonly, setReadonly] = useState(!((newObject || props.edit) && props.access.full))

    const [error, setError] = useState<string | null>(null)
    const [saveError, setSaveError] = useState<string | null>(null)
    const [item, setItem] = useState<any>()

    const formRef = useRef<FormikProps<FormikValues>>(null)

    useEffect(() => {
        setLoading(true)

        if (props.id) {
            fetchOneOdata(SobstvennikDesc, SobstvennikDesc.views.webE, props.id).then((result: any | null) => {
                setItem(result || {});
                setLoading(false)
            }).catch(() => {
                setLoading(false)
                setError('Невозможно получить данные')
            });
        } else {
            setLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.Kontragent || !values.Kontragent?.id) {
            errors.Kontragent = 'Required';
        }

        return errors;
    };

    function onSubmit(fields: any, actions: any): Promise<any> {
        return SobstvennikDesc.save({ state: newObject ? State.created : State.altered, item: Object.assign(fields, { TehDelo: { id: props.tehDelo } }) }).then((data) => {
            actions.setSubmitting(false);

            return { values: data, id: data.id }
        }).catch((e) => {
            console.error(e)
            setSaveError('Произошла ошибка при сохранении')
        }).finally(() => {
            actions.setSubmitting(false);
        })
    }

    return (
        <Container fluid className={'edit-form'}>

            <Row className='edit-form-content'>
                {loading
                    ? <Container fluid className='d-flex justify-content-center save-loader'><Spinner animation='border' variant='primary' aria-flowto='' />Загрузка...</Container>
                    : error !== null
                        ? <div>{error}</div>
                        :
                        <Formik innerRef={formRef} enableReinitialize={true} initialValues={item || {}} validateOnMount={true} validate={validate} onSubmit={onSubmit}>
                            {({ errors, values, touched, setFieldValue, isSubmitting }) => (
                                <Form>
                                    {isSubmitting
                                        ? <Container fluid className='save-loader d-flex justify-content-center'><Spinner animation='border' variant='primary' aria-flowto='' />Сохранение...</Container>
                                        : <div>
                                            <Row>
                                                <Col sm={12}>
                                                    <Row>
                                                        <Col>
                                                            <label>Контрагент</label>
                                                            <div className={'wrapper'}>
                                                                <Lookup readonly={readonly}
                                                                    onLookupClick={(id: string, obj: any) => { setFieldValue('Kontragent', obj) }}
                                                                    className={showValidation && errors['Kontragent'] ? 'error' : ''}
                                                                    id={values['Kontragent']?.id || ''}
                                                                    initialVal={values['Kontragent']}
                                                                    dataLoaded={!loading}
                                                                    modelDescription={KontragentDesc}
                                                                    view={KontragentDesc.views.webL}
                                                                    autocompleteProp='Naimenovanie'
                                                                    defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                    title={'Контрагент'}
                                                                    showObjectForm={(viewedId: string) => { return (<KontragentItem id={viewedId} view={true} />) }}
                                                                ></Lookup>
                                                                {(touched['Kontragent'] || newObject) && errors['Kontragent'] && (
                                                                    <div className='validation'>*</div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col sm={3}>
                                                            <label>Дата начала</label>
                                                            <DateInput readonly={readonly}
                                                                name={'DataNachala'}
                                                                value={values['DataNachala']}
                                                                onChange={(date: Date | null) => { setFieldValue('DataNachala', date) }}
                                                            ></DateInput>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <label>Доля</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['Dolya']}
                                                                    onChange={(v: string | null) => { setFieldValue('Dolya', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <label>Нотариус</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['Notarius']}
                                                                    onChange={(v: string | null) => { setFieldValue('Notarius', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col sm={6}>
                                                            <label>Тип владения</label>
                                                            <div className={'wrapper'}>
                                                                <Lookup readonly={readonly}
                                                                    onLookupClick={(e: string) => { setFieldValue('TipVladeniya', e ? { id: e } : null) }}
                                                                    id={values['TipVladeniya']?.id || ''}
                                                                    initialVal={values['TipVladeniya']}
                                                                    dataLoaded={!loading}
                                                                    modelDescription={TipVladeniyaDesc}
                                                                    view={TipVladeniyaDesc.views.webL}
                                                                    autocompleteProp='Naimenovanie'
                                                                    defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                    title={'Тип владения'}
                                                                    showObjectForm={(viewedId: string) => { return (<TipVladeniyaItem id={viewedId} view={true} />) }}
                                                                ></Lookup>
                                                            </div>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <label>Вид собственности</label>
                                                            <div className={'wrapper'}>
                                                                <Lookup readonly={readonly}
                                                                    onLookupClick={(e: string) => { setFieldValue('VidSobstvennosti', e ? { id: e } : null) }}
                                                                    id={values['VidSobstvennosti']?.id || ''}
                                                                    initialVal={values['VidSobstvennosti']}
                                                                    dataLoaded={!loading}
                                                                    modelDescription={VidSobstvennostiDesc}
                                                                    view={VidSobstvennostiDesc.views.webL}
                                                                    autocompleteProp='Naimenovanie'
                                                                    defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                    title={'Вид собственности'}
                                                                    showObjectForm={(viewedId: string) => { return (<VidSobstvItem id={viewedId} view={true} />) }}
                                                                ></Lookup>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col sm={12}>
                                                            <label>Документ</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['Dokument']}
                                                                    onChange={(v: string | null) => { setFieldValue('Dokument', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className='edit-form-buttons'>
                                                <div className='text-center'>
                                                    {props.access.full && !readonly && !error &&
                                                        <Button size='sm' onClick={() => {
                                                            setShowValidation(true)
                                                            if (formRef.current) {
                                                                formRef.current.submitForm().then((data: any) => {
                                                                    if (formRef.current?.isValid && data)
                                                                        props.onSave(data.values)
                                                                })
                                                            }
                                                        }}>
                                                            Сохранить
                                                        </Button>}

                                                    <Button size='sm' onClick={() => {
                                                        props.onClose();
                                                    }}>
                                                        Закрыть
                                                    </Button>

                                                    {saveError
                                                        ? <ClosableAlert className={'error'} text={saveError} onClose={() => setSaveError(null)} />
                                                        : ''
                                                    }
                                                </div>
                                            </Row>
                                        </div>
                                    }
                                </Form>
                            )}
                        </Formik>
                }
            </Row>
        </Container>
    );
}

const SobstvennikItem = (props: { onClose: () => void, onSave: (values: any) => void, edit: boolean, id: string | null, tehDelo: string }) => {
    return (
        <CheckAccessForm modelName={SobstvennikDesc.odataClassName}>
            {(access: { read: boolean, full: boolean }) => {
                return (
                    <SobstvennikForm access={access} onClose={props.onClose} onSave={props.onSave} edit={props.edit} id={props.id} tehDelo={props.tehDelo}></SobstvennikForm>
                )
            }}
        </CheckAccessForm>
    );
}

SobstvennikItem.defaultProps = {
    edit: false,
    onClose: () => { },
    onSave: () => { }
}

export default SobstvennikItem;