import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Context } from "../index";

import { headerLinks } from '../routes'

const Header = observer(() => {

  const context = useContext(Context)

  return (
    <Navbar fixed="top" className="header">
      <Container fluid>
        <Navbar.Text className="menu"><Icon.List size={30} onClick={() => { context?.menu.toggle() }}></Icon.List> </Navbar.Text>
        <Link to={'/'} className={'navbar-brand'}>
          <img
            src="/logo.svg"
            height="54"
            className="d-inline-block align-top"
            alt=""
          />
        </Link>
        <Nav>
          <Link to={'/'}>
            <div className="title">
              <div>
                Электронный архив
              </div>
              <div className="small">
                ГБУ ЦТИ Пермского края
              </div>
            </div>
          </Link>
        </Nav>
        <Nav className="ms-auto">
          {headerLinks.map((link, i) => {
            return (context?.user?.authenticated || !link.auth ? <Link key={i} to={link.path} className="nav-link">{link.caption}</Link> : '')
          })}

          {context?.user?.authenticated && <Navbar.Text className='username'>{context.user.userName}</Navbar.Text>}
          {context?.user?.authenticated &&
            <Icon.BoxArrowRight className='logout-btn' size={25} title={'Выйти'} onClick={() => { if (context?.user) { context.user.logout() } }}>Выйти</Icon.BoxArrowRight>}
        </Nav>
      </Container>
    </Navbar>
  )
})

export default Header;