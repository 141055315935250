import React, { useContext, useEffect, useState } from "react";
import * as Icon from 'react-bootstrap-icons';
import { Button, Stack } from "react-bootstrap";
import { v4 } from "uuid";
import { State } from '../../descriptions/State'
import { IDetailWithWebFile, IWebFile, IWithWebFile } from "../../descriptions/DescriptionTypes";
import './web-files-control.css';
import { $baseHost as $host } from "../../http/index";
import { PreviewStore } from "../../store/PreviewStore";

interface Props {
    onChange?: any,
    readonly: boolean,
    list: boolean,
    files?: IWithWebFile[] | null,
    context: React.Context<PreviewStore | null>,
    dataLoaded: boolean
}

export const getIcon = (fileName: string | null, previewData: any, large: boolean): JSX.Element => {
    const size = large ? 300 : 50;
    
    if (previewData) return <img src={previewData} {...(large ? {} : { width: size })} height={size} alt=""></img>

    const dictionary: { [key: string]: JSX.Element } = {
        'doc': <Icon.FiletypeDoc size={size}></Icon.FiletypeDoc>,
        'docx': <Icon.FiletypeDocx size={size}></Icon.FiletypeDocx>,
        'jpg': <Icon.FiletypeJpg size={size}></Icon.FiletypeJpg>,
        'jpeg': <Icon.FiletypeJpg size={size}></Icon.FiletypeJpg>,
        'png': <Icon.FiletypePng size={size}></Icon.FiletypePng>,
        'txt': <Icon.FiletypeTxt size={size}></Icon.FiletypeTxt>,
        'pdf': <Icon.FiletypePdf size={size}></Icon.FiletypePdf>
    }

    if (!fileName) return <Icon.FileEarmark size={size}></Icon.FileEarmark>

    const fileArray = fileName?.split('.')
    const ext = fileArray[fileArray?.length - 1]

    return dictionary[ext] ? dictionary[ext] : <Icon.FileEarmark size={size}></Icon.FileEarmark>;
}

const WebFilesControl = (props: Props) => {
    const [files, setFiles] = useState<IDetailWithWebFile[]>([])
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [preview, setPreview] = useState<string | null>(null)

    const context = useContext(props.context)

    useEffect(() => {
        if (props.dataLoaded && props.files && props.files.length > 0) {
            const initialFiles = props.files
                ? props.files
                    .sort((a, b) => { return a.Fajl.fileName === b.Fajl.fileName ? 0 : a.Fajl.fileName > b.Fajl.fileName ? 1 : -1; })
                    .map((f: IWithWebFile) => { return { item: f, state: State.unAltered } as IDetailWithWebFile })
                : []

            setFiles(initialFiles)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataLoaded])

    const noPropagation = (e: React.MouseEvent) => e.stopPropagation()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length === 1) {
            const file = e.target.files[0];

            var blob = new Blob([file]);
            var url = URL.createObjectURL(blob);

            const innerFile = {
                state: State.created,
                file: file,
                item: {
                    id: v4(),
                    Fajl: {
                        fileUrl: url,
                        previewUrl: null,
                        fileName: file.name,
                        fileSize: file.size,
                        fileMimeType: file.type
                    } as IWebFile
                } as IWithWebFile
            } as IDetailWithWebFile

            const newFiles = files.concat([innerFile])

            setFiles(newFiles)

            if (props.onChange) {
                props.onChange(newFiles)
            }
        }
    }

    const download = (e: React.MouseEvent, detail: IDetailWithWebFile) => {
        e.stopPropagation()

        const getFile = (url: string, filename: string) => {
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        }

        if (!detail.item.Fajl.fileUrl) {
            return;
        }

        if (detail.state === State.created) {
            getFile(detail.item.Fajl.fileUrl, detail.item.Fajl.fileName);
        }

        if (detail.state === State.unAltered) {
            $host.get(detail.item.Fajl.fileUrl,
                { responseType: 'blob' })
                .then((data: any) => {
                    var url = window.URL.createObjectURL(data.data);
                    getFile(url, detail.item.Fajl.fileName);
                }).catch((e) => {
                    console.error(e);
                });
        }
    }

    const removeFile = (id: string) => {
        let arr = [...files]
        const i = arr.find((i) => { return i.item.id === id })
        if (i) {
            if (i.state === State.created) {
                setFiles(files.filter((f: IDetailWithWebFile) => f.item.id !== id));

                if (props.onChange) {
                    props.onChange(files.filter((f: IDetailWithWebFile) => f.item.id !== id))
                }
            } else {
                i.state = State.deleted
                i.file = null
                setFiles(arr)

                if (props.onChange) {
                    props.onChange(arr)
                }
            }
        }
    }

    return (
        <div className="web-files-control">
            {!props.readonly
                ? <div className="fieldset-toolbar">
                    <Button size="sm" onClick={(e) => { noPropagation(e); if (inputRef.current) inputRef.current.click(); }}>
                        Добавить файл
                    </Button>
                    <input
                        ref={inputRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            handleChange(e);
                            if (inputRef.current) inputRef.current.value = '';
                        }}
                    />
                </div>
                : !props.list && files.length === 0 ? 'Файлы отсутствуют' : ''
            }
            <Stack direction="horizontal" gap={4} className='fieldset-content'>
                {files.filter((f) => { return f.state !== State.deleted }).map((f: IDetailWithWebFile) => {
                    return (
                        <div id={"div" + f.item.id} key={f.item.id} className={"web-file " + (f.state === State.created ? "created" : f.state === State.altered ? "altered" : "")}>
                            <div id={f.item.id} title={f.item.Fajl.fileName} onClick={(e) => { download(e, f); }}>
                                {getIcon(f.item.Fajl.fileName, context?.preview[f.item.id], false)}
                            </div>

                            {f.item.id === preview && <div className="large-preview" onMouseLeave={() => { setPreview(null) }}>
                                {getIcon(f.item.Fajl.fileName, context?.preview[f.item.id], true)}
                            </div>}

                            {context?.preview[f.item.id] && <span className="web-file-preview" onClick={(e) => { setPreview(f.item.id) }}>
                                <i className="icon-guideline-search-r"></i>
                            </span>}

                            {!props.readonly
                                ? <span className="web-file-remove" onClick={(e) => { removeFile(f.item.id); noPropagation(e); }}>
                                    <span aria-hidden="true">&times;</span>
                                </span>
                                : ""}
                            <div className={'web-filename'} title={f.item.Fajl.fileName}>
                                {f.item.Fajl.fileName}
                            </div>
                        </div>
                    )
                })}
            </Stack>
        </div>
    );
}

WebFilesControl.defaultProps = {
    list: false,
    context: null
};

export default WebFilesControl;
