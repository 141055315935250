import { useEffect, useState } from "react";
import { Container, Row, Table, Button } from "react-bootstrap";
import { getHeaders, getValues } from '../utils/viewHelpers'
import { DataObjectDescription, PartialRecord, ViewProperty, PropertyDescriptions } from "../descriptions/DescriptionTypes";
import Modal from "react-bootstrap/Modal";
import { State } from "../descriptions/State";
import ClosableAlert from "./ClosableAlert";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import * as Icon from 'react-bootstrap-icons';

interface Props<T extends PropertyDescriptions> {
  dataLoaded: boolean
  items: any[]
  update?: any,

  view: PartialRecord<keyof T, ViewProperty>
  modelDescription: DataObjectDescription<T>

  access?: { read: boolean, full: boolean }

  form: (id: string | null, onClose: any) => React.ReactElement

  title: string
}

const DetailList = <T extends PropertyDescriptions>(props: Props<T>) => {
  const [items, setItems] = useState<any[]>([])
  const [showForm, setShowForm] = useState<boolean>(false)
  const [viewedId, setViewedId] = useState<string | null>(null)
  const [checked, setChecked] = useState<any>({})

  const [removeError, setRemoveError] = useState<boolean>(false)
  const [message, setMessage] = useState<string | null>(null)

  useEffect(() => {
    if (props.dataLoaded && props.items && props.items.length > 0) {
      setItems(props.items)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataLoaded, props.items])

  const Sort = (field: string) => {
  }

  const Check = (id: string, check: boolean) => {
    let c: any = Object.assign({}, checked)
    if (!check && id in c) delete c[id];
    if (check) c[id] = true
    setChecked(c)
  }

  const Remove = (id: string | null) => {
    const ids = id ? [id] : Object.keys(checked)
    if (ids.length > 0) {
      confirmAlert({
        title: 'Подтвердите удаление',
        message: 'Удалить элемент(ы)?',
        buttons: [
          {
            label: 'Да',
            onClick: () => {
              let promises = ids.map((id) => {
                return props.modelDescription.save({
                  state: State.deleted, item: { id: id }
                })
              })

              Promise.allSettled(promises).then((results) => {
                if (results.filter((r) => { return r.status !== 'fulfilled'; }).length === 0) {

                  let arr = [...items].filter((i) => {
                    return ids.indexOf(i.id) < 0
                  })

                  setItems(arr)
                  props.update(arr)

                  setMessage('Объект(ы) успешно удален(ы)')
                } else {
                  setRemoveError(true)
                }
              }).catch(() => {
                setRemoveError(true)
              })
            }
          },
          {
            label: 'Нет'
          }
        ]
      });
    } else {
      setMessage('Не выбрано ни одного объекта')
    }
  }

  const headers = getHeaders(props.view, Sort)

  return (
    <div>
      {props.access?.full &&
        <Row className='detail-form-buttons'>
          <div className='detail-form-toolbar'>
            <Button size="sm" onClick={() => { setViewedId(null); setShowForm(true); }}>
              Создать
            </Button>
            <Button className="btn-danger" size="sm" onClick={() => { Remove(null) }} {...(Object.keys(checked).length > 0 ? {} : { disabled: true })}>
              Удалить
            </Button>
          </div>
        </Row>
      }

      <Row>
        {removeError
          ? <ClosableAlert className={'error'} text={'Произошла ошибка при удалении'} onClose={() => setRemoveError(false)} />
          : ''}

        {message
          ? <ClosableAlert className={'success'} text={message} onClose={() => setMessage(null)} />
          : ''}

<Table striped hover responsive size="lg">
            {items.length > 0
              ? <thead><tr><th style={{ width: "30px" }}></th>{headers}</tr></thead>
              : ''
            }
            <tbody>
              {items.length > 0
                ? items.map((info) => {
                  return (
                    <tr key={info.id}>
                      {props.access?.full
                        ? <td className="list-helper-column-layout">
                          <div className="checkbox">
                            <input type="checkbox" className="list-checkbox" onChange={(e) => { Check(info.id, e.target.checked) }} />
                            <label></label>
                          </div>
                          <Button variant='' className="icon-btn" title={'Удалить'} onClick={() => Remove(info.id)}>
                            <i className="icon-guideline-delete-r"></i>
                          </Button>
                        </td>
                        : <td className="list-helper-column-layout">
                          <Button variant='light' size='sm' onClick={() => { setViewedId(info.id); setShowForm(true); }}>
                            <i className="icon-guideline-show"></i>
                          </Button>
                        </td>}
                      {getValues(props.modelDescription, props.view, info, () => { if (props.access?.full) { setViewedId(info.id); setShowForm(true); } })}
                    </tr>)
                })
                : <tr><td className={'noData'} colSpan={headers.length + 1}>Нет данных</td></tr>}
            </tbody>
          </Table>
      </Row >

      <Modal
        show={showForm}
        onHide={() => setShowForm(false)}
        size="lg"
        scrollable={true}
        centered>
        <Modal.Header>
          {props.title}
        </Modal.Header>
        <Modal.Body className={'edit-modal-form'}>
          {props.form ? props.form(viewedId, () => { setShowForm(false) }) : ''}
        </Modal.Body>
      </Modal>

    </div >
  );
}

export default DetailList;