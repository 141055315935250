
import List from '../components/List'
import ListToolbar from '../components/ListToolbar'
import CheckAccessForm from '../components/CheckAccessForm'
import { Container, Row } from 'react-bootstrap';
import * as Routes from '../utils/consts';
import { useHistory } from 'react-router-dom'
import { TipFundamentaDesc } from '../descriptions/Dictionaries';

const TipFundamentaList = () => {
    const history = useHistory()
    const route = Routes.TIPFUNDAMENTA_ROUTE

    return (
        <CheckAccessForm modelName={TipFundamentaDesc.odataClassName}>
            {(access: { read: boolean, full: boolean }) => {
                return (
                    <Container fluid className={'list-form'}>
                        <Row className='list-form-title'><div>Типы фундамента</div></Row>

                        <List defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }} access={access} route={route}
                            view={TipFundamentaDesc.views.webL}
                            modelDescription={TipFundamentaDesc}
                            onRowClick={(info: any) => history.push(route + '/' + info.id)}></List>
                    </Container>
                )
            }}
        </CheckAccessForm>
    );
}

export default TipFundamentaList;