
import { useEffect, useRef, useState } from 'react'
import { fetchOneOdata } from '../http/ODataApi'

import Input from '../components/Input'
import DateInput from '../components/DateInput'
import NumberInput from '../components/NumberInput'
import GroupEdit from '../components/GroupEdit'

import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'

import { Formik, Form, FormikProps, FormikValues } from 'formik';

import CheckAccessForm from '../components/CheckAccessForm'

import { KvartiraDesc, TehSostoyanieDesc, OtdelkaDesc } from '../descriptions/Descriptions';

import { State } from '../descriptions/State'

import ClosableAlert from '../components/ClosableAlert'
import { IsDataObjectChangedElement } from '../descriptions/DescriptionTypes'

interface Props {
    access: { read: boolean, full: boolean }
    onClose: () => void
    onSave: (values: any) => void
    id: string | null
    edit: boolean
    tehDelo: string
}

const KvartiraForm = (props: Props) => {
    const newObject = !props.id;

    const [loading, setLoading] = useState(true)
    const [showValidation, setShowValidation] = useState(false)

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [readonly, setReadonly] = useState(!((newObject || props.edit) && props.access.full))

    const [error, setError] = useState<string | null>(null)
    const [saveError, setSaveError] = useState<string | null>(null)
    const [item, setItem] = useState<any>()

    const formRef = useRef<FormikProps<FormikValues>>(null)

    useEffect(() => {
        setLoading(true)

        if (props.id) {
            fetchOneOdata(KvartiraDesc, KvartiraDesc.views.webE, props.id).then((result: any | null) => {
                setItem(result || {});
                setLoading(false)
            }).catch(() => {
                setLoading(false)
                setError('Невозможно получить данные')
            });
        } else {
            setLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.NomerKvartiry)
            errors.NomerKvartiry = 'Required'

        let otdelka: any = {}
        if (values.Otdelka)
            values.Otdelka.forEach((s: any) => {
                let item = IsDataObjectChangedElement(s) ? s.item : s
                let e: any = {}

                if (!item.Pomeshenie || !item.Pomeshenie.id)
                    e.Pomeshenie = 'Required'

                if (!item.Element || !item.Element.id)
                    e.Element = 'Required'

                if (!item.Material || !item.Material.id)
                    e.Material = 'Required'

                if (Object.keys(e).length > 0)
                    otdelka[item.id] = e
            })

        if (Object.keys(otdelka).length > 0)
            errors.Otdelka = otdelka

        let teh: any = {}
        if (values.TehSostoyanie)
            values.TehSostoyanie.forEach((s: any) => {
                let item = IsDataObjectChangedElement(s) ? s.item : s
                if (!item.Blagoustroistvo || !item.Blagoustroistvo.id)
                    teh[item.id] = { Blagoustroistvo: 'Required' }
            })

        if (Object.keys(teh).length > 0)
            errors.TehSostoyanie = teh

        return errors;
    };

    function onSubmit(fields: any, actions: any): Promise<any> {
        return KvartiraDesc.save({ state: newObject ? State.created : State.altered, item: Object.assign(fields, { TehDelo: { id: props.tehDelo } }) }).then((data) => {
            actions.setSubmitting(false);
            return { values: data, id: data.id }
        }).catch((e) => {
            console.error(e)
            setSaveError('Произошла ошибка при сохранении')
        }).finally(() => {
            actions.setSubmitting(false);
        })
    }

    return (
        <Container fluid>
            <Row className='edit-form'>
                {loading
                    ? <Container fluid className='d-flex justify-content-center save-loader'><Spinner animation='border' variant='primary' aria-flowto='' />Загрузка...</Container>
                    : error !== null
                        ? <div>{error}</div>
                        :
                        <Formik innerRef={formRef} enableReinitialize={true} initialValues={item || {}} validateOnMount={true} validate={validate} onSubmit={onSubmit}>
                            {({ errors, values, touched, setFieldValue, isSubmitting }) => (
                                <Form>
                                    {isSubmitting
                                        ? <Container fluid className='save-loader d-flex justify-content-center'><Spinner animation='border' variant='primary' aria-flowto='' />Сохранение...</Container>
                                        : <div>
                                            <Row className='edit-form-content'>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <label>Номер этажа</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    value={values['NomerEtazha']}
                                                                    onChange={(v: string | null) => { setFieldValue('NomerEtazha', v) }}
                                                                ></Input>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Номер квартиры</label>
                                                            <div className={'wrapper'}>
                                                                <Input readonly={readonly}
                                                                    className={showValidation && errors['NomerKvartiry'] ? 'error' : ''}
                                                                    value={values['NomerKvartiry']}
                                                                    onChange={(v: string | null) => { setFieldValue('NomerKvartiry', v) }}
                                                                ></Input>
                                                                {(touched['NomerKvartiry'] || newObject) && errors['NomerKvartiry'] && (
                                                                    <div className='validation'>*</div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Уровень этажа</label>
                                                            <div className={'wrapper'}>
                                                                <NumberInput readonly={readonly} int={false}
                                                                    value={values['UrEtazha']}
                                                                    onChange={(v: string) => { setFieldValue('UrEtazha', v) }}
                                                                ></NumberInput>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label>Основная площадь</label>
                                                            <div className={'wrapper'}>
                                                                <NumberInput readonly={readonly} int={false}
                                                                    value={values['OsnovnayaPloshad']}
                                                                    onChange={(v: string) => { setFieldValue('OsnovnayaPloshad', v) }}
                                                                ></NumberInput>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Вспом. площадь</label>
                                                            <div className={'wrapper'}>
                                                                <NumberInput readonly={readonly} int={false}
                                                                    value={values['VspomPloshad']}
                                                                    onChange={(v: string) => { setFieldValue('VspomPloshad', v) }}
                                                                ></NumberInput>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Прочая площадь</label>
                                                            <div className={'wrapper'}>
                                                                <NumberInput readonly={readonly} int={false}
                                                                    value={values['PrPloshad']}
                                                                    onChange={(v: string) => { setFieldValue('PrPloshad', v) }}
                                                                ></NumberInput>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label>Количество комнат</label>
                                                            <div className={'wrapper'}>
                                                                <NumberInput readonly={readonly} int={false}
                                                                    value={values['KolKomnat']}
                                                                    onChange={(v: string) => { setFieldValue('KolKomnat', v) }}
                                                                ></NumberInput>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Количество кухонь</label>
                                                            <div className={'wrapper'}>
                                                                <NumberInput readonly={readonly} int={false}
                                                                    value={values['KolKuhon']}
                                                                    onChange={(v: string) => { setFieldValue('KolKuhon', v) }}
                                                                ></NumberInput>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <label>Высота</label>
                                                            <div className={'wrapper'}>
                                                                <NumberInput readonly={readonly} int={false}
                                                                    value={values['Hrwart']}
                                                                    onChange={(v: string) => { setFieldValue('Hrwart', v) }}
                                                                ></NumberInput>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label>Дата инвентаризации</label>
                                                            <DateInput readonly={readonly}
                                                                name={'DataInventarizacii'}
                                                                value={values['DataInventarizacii']}
                                                                onChange={(date: Date | null) => { setFieldValue('DataInventarizacii', date) }}
                                                            ></DateInput>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <GroupEdit readonly={readonly} errors={errors.TehSostoyanie || {}} showErrors={showValidation}
                                                            title={'Благоустройство'} view={TehSostoyanieDesc.views.webD} modelDescription={TehSostoyanieDesc} dataLoaded={!loading}
                                                            items={values['TehSostoyanie']}
                                                            onChange={(v: any[]) => {
                                                                setFieldValue('TehSostoyanie', v)
                                                            }}
                                                            lookupSettings={{ 'Blagoustroistvo': { title: 'Вид благоустройства' } }} ></GroupEdit>
                                                    </Row>
                                                    <Row>
                                                        <GroupEdit readonly={readonly} errors={errors.Otdelka || {}} showErrors={showValidation}
                                                            title={'Отделка'} view={OtdelkaDesc.views.webD} modelDescription={OtdelkaDesc} dataLoaded={!loading}
                                                            items={values['Otdelka']}
                                                            onChange={(v: any[]) => {
                                                                setFieldValue('Otdelka', v)
                                                            }}
                                                            lookupSettings={{ 'Pomeshenie': { title: 'Помещение' }, 'Element': { title: 'Констр. элемент' }, 'Material': { title: 'Материал' } }} ></GroupEdit>
                                                    </Row>
                                                </Col>
                                            </Row>                
                                            <Row className='edit-form-buttons'>
                                                <div>
                                                    {props.access.full && !readonly && !error &&
                                                        <div style={{ display: 'inline-flex' }}>
                                                            <Button size='sm' onClick={() => {
                                                                setShowValidation(true)
                                                                if (formRef.current) {
                                                                    formRef.current.submitForm().then((data: any) => {
                                                                        if (formRef.current?.isValid && data)
                                                                            props.onSave(data.values)
                                                                    })
                                                                }
                                                            }}>
                                                                Сохранить
                                                            </Button>
                                                        </div>
                                                    }
                                                    <Button size='sm' onClick={() => { props.onClose();}}>Закрыть</Button>
                                                    {saveError
                                                        ? <ClosableAlert className={'error'} text={saveError} onClose={() => setSaveError(null)} />
                                                        : ''
                                                    }
                                                </div>
                                            </Row>
                                        </div>
                                    }
                                </Form>
                            )}
                        </Formik>
                }
            </Row>
        </Container>
    );
}

const KvartiraItem = (props: { onClose: () => void, onSave: (values: any) => void, edit: boolean, id: string | null, tehDelo: string }) => {
    return (
        <CheckAccessForm modelName={KvartiraDesc.odataClassName}>
            {(access: { read: boolean, full: boolean }) => {
                return (
                    <KvartiraForm access={access} onClose={props.onClose} onSave={props.onSave} edit={props.edit} id={props.id} tehDelo={props.tehDelo}></KvartiraForm>
                )
            }}
        </CheckAccessForm>
    );
}

KvartiraItem.defaultProps = {
    edit: false,
    onClose: () => { },
    onSave: () => { }
}

export default KvartiraItem;