import { FormatValue } from './formats'
import WebFilesControl from '../components/WebFilesControl/WebFilesControl'
import { DataObjectDescription, DataObjectViewProperty, DetailViewProperty, ExpandViewProperty, PartialRecord, ViewProperty, PropertyDescriptions, EnumPropertyDescription, DataObjectPropertyDescription } from "../descriptions/DescriptionTypes";

export const getHeaders = (view: PartialRecord<keyof any, ViewProperty>, onClick: any, sort?: { sortField: string, sortOrder: 'asc' | 'desc' }): any => {

    const h = (view: PartialRecord<keyof any, ViewProperty>, master: string = '', prefixIndex: string = '') => {
        let html: any[] = []
        Object.entries(view).forEach(([field, p], index) => {
            if (!p) return;

            if (p instanceof ExpandViewProperty) {
                if (p.visible) html.push(
                    <th onClick={() => { onClick(master + field) }} key={prefixIndex + index.toString()}>
                        {p.caption}
                        {master + field === sort?.sortField ? <i className={'icon-guideline-sort-' + sort.sortOrder + 'end'}></i> : ''}
                    </th>)

                html = html.concat(h((p as ExpandViewProperty).expand || {}, field + '.', index.toString()))

            } else if (p.visible) html.push(
                <th onClick={() => { onClick(master + field) }} key={prefixIndex + index.toString()}>
                    <div className="th-cell-layout">
                        {p.caption}
                        {master + field === sort?.sortField ? <i className={'icon-guideline-sort-' + sort.sortOrder + 'end'}></i> : ''}
                    </div>
                </th>)
        })

        return html
    }

    return h(view)
}

export const getValues = <T extends PropertyDescriptions>(modelDescription: DataObjectDescription<T>, view: PartialRecord<keyof any, ViewProperty>, info: any, rowClick: any, prefix: string = ''): any => {
    let html: any[] = []
    Object.entries(view).forEach(([field, p], index) => {
        if (!p) return;

        if (p instanceof ExpandViewProperty) {
            if (p.visible) {
                if (p instanceof DataObjectViewProperty) {
                    html.push(<td onClick={rowClick} key={prefix + index.toString()}> {info[field] && info[field]['id'] ? info[field]['id'].toString() : ''} </td>)
                }

                if (p instanceof DetailViewProperty) {
                    if (modelDescription.isFailDetail(field)) {
                        html.push((<td key={prefix + index.toString()}><WebFilesControl list={true} readonly={true} dataLoaded={true} files={info[field] || []}></WebFilesControl></td>))
                    }
                }
            }

            html = html.concat(getValues((modelDescription.properties[field] as DataObjectPropertyDescription).odata, (p as ExpandViewProperty).expand || {}, info[field], rowClick, index.toString()));
        } else {
            let val = info ? info[field] : null;
            if (modelDescription.properties[field] instanceof EnumPropertyDescription) {
                val = (modelDescription.properties[field] as EnumPropertyDescription)?.linkedEnum[info[field] || '']
            }

            let minWidth = val ? val.length : null;

            html.push(<td style={{minWidth: minWidth && minWidth > 32 ? '20rem' : ''}} onClick={rowClick} key={prefix + index.toString()}> {FormatValue(val)} </td>)
        }
    })

    return html
}