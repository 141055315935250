import { config } from '../utils/config'

const About = () => {
  return (
    <div>
      <h2>Something about</h2>
      {config.REACT_APP_TEST}
    </div>
  );
}

export default About;