import React from "react";

interface Props {
    onChange: any
    value?: string | null
    items: { [s: string]: string }
    readonly: boolean
}

const DropDown = (props: Props) => {
    function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
        let v: string | null = e.target.value
        if (v === '') v = null;
        props.onChange(v);
    }

    const items =
        Object.entries(props.items).map(([val, caption]) => {
            return { value: val || '', caption: caption.toString() || ' ' }
        })

    return (
        props.readonly
            ? <div>{props.items[props.value || ''] || ''}</div>
            : <select value={props.value || ''} onChange={handleChange}>
                <option value={''}></option>
                {items.map((i: { value: string, caption: string }) => {
                    return (<option key={i.value} value={i.value}>{i.caption}</option>)
                })}
            </select>
    );
}

DropDown.defaultProps = {
    readonly: false
};

export default DropDown;


