import React from "react";

interface Props {
    onChange: any
    value?: boolean | null
    readonly: boolean
    className?: string
    label: string
    name: string
}

const CheckBoxInput = (props: Props) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const v = e.target.checked
        props.onChange(v);
    }

    return (
        props.readonly
            ? <div className={'readonly label-checkbox ' + (props.value ? 'checked' : '')}>{props.label}</div>
            : <div className={'checkbox'}>
                <input id={props.name} type='checkbox' className={props.className} onChange={handleChange} checked={props.value || false} />
                <label htmlFor={props.name}>{props.label}</label>
            </div>
    );
}

CheckBoxInput.defaultProps = {
    readonly: false
};

export default CheckBoxInput;