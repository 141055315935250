import React from "react";

interface Props {
    onChange: any
    value?: boolean | null
    readonly: boolean
    className?: string
}

const CheckBoxInput = (props: Props) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const v = e.target.checked
        props.onChange(v);
    }

    return (
        props.readonly
            ? <div className={'readonly'}>{props.value ? 'Да' : 'Нет'}</div>
            : <div className={'checkbox' + (' ' + props.className || '')}>
                <input type='checkbox' className={props.className} onChange={handleChange} checked={props.value || false} />
                <label></label>
              </div>
    );
}

CheckBoxInput.defaultProps = {
    readonly: false
};

export default CheckBoxInput;