import { Row, Button } from "react-bootstrap";
import { reload } from "../utils/pageHelpers";
import * as Icon from 'react-bootstrap-icons';
import { useHistory } from "react-router-dom";

interface Props {
    access?: { read: boolean, full: boolean }
    route: string
    onDelete?: any
    checked?: any
}

const ListToolbar = (props: Props) => {
    const history = useHistory()

    return (
        <Row className='list-form-buttons'>
            <div className='list-form-toolbar' style={{ display: 'inline-flex' }}>
                <div className="toolbar-default-buttons">
                    <Button variant='light' size='sm' title={'Обновить'} onClick={() => reload(history, props.route)}>
                        <i className='icon-guideline-refresh-r'></i>
                    </Button>
                    <div className='divider'></div>
                    {/* <Button variant='light' size='sm' title={'Настройка столбцов'}>
                        <i className='icon-guideline-columns'></i>
                        <i className='icon-guideline-arrow-short-down-filled'></i>
                    </Button>
                    <Button variant='light' size='sm' title={'Экспорт'}>
                        <i className='icon-guideline-excel'></i>
                        <i className='icon-guideline-arrow-short-down-filled'></i>
                    </Button>
                    <Button variant='light' size='sm' title={'Фильтр'}>
                        <i className='icon-guideline-filter'></i>
                    </Button> */}
                </div>
                <div className="toolbar-custom-buttons">
                    {props.access?.full && <Button size='sm' onClick={() => { history.push(props.route + '/new'); }}>
                        Создать
                    </Button>}
                    {props.access?.full && props.onDelete && <Button className='btn-danger' size='sm' onClick={() => { props.onDelete(); }} {...(Object.keys(props.checked || {}).length > 0 ? {} : { disabled: true })}>
                        Удалить
                    </Button>}
                </div>
            </div>
        </Row>)
}
export default ListToolbar;