import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Context } from "../index";
import { Spinner, Container } from 'react-bootstrap'

interface Props {
    modelName: string
    extraModels?: { modelName: string, key: string }[]
    onlyView: boolean
    children: (access: Access) => React.ReactElement | React.ReactElement[]
}

export interface Access {
    read: boolean
    full: boolean
    extra?: { [key: string]: { read: boolean, full: boolean } }
}

const CheckAccess = (props: Props) => {
    const context = useContext(Context)
    const [access, setAccess] = useState<Access | null>(null)

    useEffect(() => {
        let a: Access = { read: false, full: false, extra: {} }
        let promises = []

        promises.push(
            context?.access.check(props.modelName).then((access) => {
                a = Object.assign(a, access)
            }))

        if (props.extraModels) {
            props.extraModels.forEach((d: { modelName: string, key: string }) => {
                promises.push(
                    context?.access.check(d.modelName).then((access) => {
                        if (!a.extra) a.extra = {}
                        a.extra[d.key] = Object.assign(a.extra[d.key] || {}, access)
                    })
                )
            })
        }

        Promise.allSettled(promises).then(() => {
            setAccess(a)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {access !== null
                ? (access.read
                    ? props.children({ read: true, full: access.full && !props.onlyView, extra: access?.extra })
                    : <Container fluid className='d-flex justify-content-center save-loader'>Недостаточно полномочий</Container>)
                : <Container fluid className='d-flex justify-content-center save-loader'><Spinner animation='border' variant='primary' aria-flowto='' />Проверка полномочий...</Container>
            }
        </div>
    )
}

CheckAccess.defaultProps = {
    onlyView: false
};

const CheckAccessForm = observer(CheckAccess)

export default CheckAccessForm;