import { makeAutoObservable, toJS } from "mobx";
import { IWithWebFile } from "../descriptions/DescriptionTypes";
import { $baseHost as $host } from "../http/index";

export class PreviewStore {
    private _previews: { [k: string]: any } = {};
    private _controllers: AbortController[] = [];
    private _current: string | null = null;

    private static instance: PreviewStore;

    static Create(clear: boolean) {
        if (this.instance && !clear) return this.instance;

        this.instance = new PreviewStore();
        return this.instance;
    }

    private constructor() {
        makeAutoObservable(this)
    }

    load = async (id: string, files: IWithWebFile[]) => {
        let promises: any[] = []
        let preview: { [k: string]: any } = {};
        let controllers: AbortController[] = [];

        if (this._controllers) {
            this._controllers.forEach((c) => {
                try {
                    if (c) c.abort();
                }
                catch { }
            })
        }

        this._current = id

        if (!files)
            return

        files.forEach((f) => {
            if (f.Fajl.previewUrl) {
                const controller = new AbortController();
                const signal = controller.signal;
                controllers.push(controller)

                promises.push(
                    $host.get(f.Fajl.previewUrl, { signal: signal }).then((response: any) => {
                        if (response && response.data) preview[f.id] = response.data;
                    }))
            }
        })

        this._controllers = controllers

        Promise.allSettled(promises).then(() => {
            // Проверим, что мы все еще на том же техделе, а не перешли на другое, пока превью загружались
            if (id === this._current) {
                this._previews = preview
            }
        })
    }

    get preview() {
        return this._previews
    }
}

export const previewStore = PreviewStore.Create(false);