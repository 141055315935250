import { FormikProps, FormikValues } from 'formik';
import * as H from 'history';

export const reload = (history: H.History, route: string) => {
    history.push('/')

    setTimeout(() => {
        history.push(route)
    }, 0)
}

export const saveAndClose = (formRef: React.RefObject<FormikProps<FormikValues> | null>, history: H.History, route: string) => {
    if (formRef.current) {
        formRef.current.submitForm().then((data: any | null) => {
            if (formRef.current?.isValid)
                history.push(route)
        })
    }
}

export const save = (formRef: React.RefObject<FormikProps<FormikValues> | null>, history: H.History, callback: any) => {
    if (formRef.current) {
        formRef.current.submitForm().then((data: any | null) => {
            if (formRef.current?.isValid) {
                if (data && data.newObject && data.id && data.route) {
                    //history.push(data.route + '/' + data.id)
                    const href = window.location.href.replace('/new', '/' + data.id)
                    window.history.replaceState({ ...window.history.state, as: href, url: href }, '', href);
                }

                if (callback) callback()
            }
        })
    }
}