import Home from './pages/Home';
import Dictionaries from './pages/Dictionaries';
import About from './pages/About';
import TehnicheskoeDeloList from './pages/TehnicheskoeDeloList'
import TehnicheskoeDeloItem from './pages/TehnicheskoeDeloItem'

import * as Routes from "./utils/consts";
import BlagoustrItem from './pages/BlagoustrItem';
import BlagoustrList from './pages/BlagoustrList';
import EdIzmereniaItem from './pages/EdIzmereniaItem';
import EdIzmereniaList from './pages/EdIzmereniaList';
import ElementItem from './pages/ElementItem';
import ElementList from './pages/ElementList';
import EtazhiItem from './pages/EtazhiItem';
import EtazhiList from './pages/EtazhiList';
import FiasItem from './pages/FiasItem';
import FiasList from './pages/FiasList';
import FondItem from './pages/FondItem';
import FondList from './pages/FondList';
import KladrItem from './pages/KladrItem';
import KladrList from './pages/KladrList';
import KontragentItem from './pages/KontragentItem';
import KontragentList from './pages/KontragentList';
import MaterialItem from './pages/MaterialItem';
import MaterialList from './pages/MaterialList';
import PomeshenieItem from './pages/PomeshenieItem';
import PomeshenieList from './pages/PomeshenieList';
import TipFundamentaItem from './pages/TipFundamentaItem';
import TipFundamentaList from './pages/TipFundamentaList';
import TipKrovliItem from './pages/TipKrovliItem';
import TipKrovliList from './pages/TipKrovliList';
import TipOtdelkiItem from './pages/TipOtdelkiItem';
import TipOtdelkiList from './pages/TipOtdelkiList';
import TipPeregorodokItem from './pages/TipPeregorodokItem';
import TipPeregorodokList from './pages/TipPeregorodokList';
import TipPerekritiaItem from './pages/TipPerekritiaItem';
import TipPerekritiaList from './pages/TipPerekritiaList';
import TipPolaItem from './pages/TipPolaItem';
import TipPolaList from './pages/TipPolaList';
import TipProemaItem from './pages/TipProemaItem';
import TipProemaList from './pages/TipProemaList';
import TipSantehnikiItem from './pages/TipSantehnikiItem';
import TipSantehnikiList from './pages/TipSantehnikiList';
import TipSooruzhItem from './pages/TipSooruzhItem';
import TipSooruzhList from './pages/TipSooruzhList';
import TipStenItem from './pages/TipStenItem';
import TipStenList from './pages/TipStenList';
import TipVladeniyaItem from './pages/TipVladeniyaItem';
import TipVladeniyaList from './pages/TipVladeniyaList';
import VidIznosaItem from './pages/VidIznosaItem';
import VidIznosaList from './pages/VidIznosaList';
import VidSobstvItem from './pages/VidSobstvItem';
import VidSobstvList from './pages/VidSobstvList';
import VidSooruzhItem from './pages/VidSooruzhItem';
import VidSooruzhList from './pages/VidSooruzhList';
import VidTehPasportaItem from './pages/VidTehPasportaItem';
import VidTehPasportaList from './pages/VidTehPasportaList';

export const headerLinks = [
    {
        caption: 'Главная',
        path: Routes.HOME_ROUTE,
        auth: true
    },
    {
        caption: 'Инвентарные дела',
        path: Routes.TD_ROUTE,
        auth: true
    }
]

export const leftMenuLinks: { caption: string, icon: JSX.Element, path: string, auth: boolean }[] = [
    {
        caption: 'Инвентарные дела',
        icon: <i className='icon-guideline-archive-box'></i>,
        path: Routes.TD_ROUTE,
        auth: true
    },
    {
        caption: 'Справочники',
        icon: <i className='icon-guideline-show'></i>,
        path: Routes.SPRAVOCHNIK_ROUTE,
        auth: true
    }
]

export const dictionariesLinks: { title: string, nodes: { caption: string, path: string }[] }[] = [
    {
        title: 'Адрес',
        nodes: [
            { caption: 'КЛАДР', path: Routes.KLADR_ROUTE },
            { caption: 'ФИАС', path: Routes.FIAS_ROUTE }
        ]
    },
    {
        title: 'Виды материалов',
        nodes: [
            { caption: 'Материалы', path: Routes.MATERIAL_ROUTE },
            { caption: 'Типы стен', path: Routes.TIPSTEN_ROUTE },
            { caption: 'Типы сантехники', path: Routes.TIPSANTEHNIKI_ROUTE },
            { caption: 'Типы фундамента', path: Routes.TIPFUNDAMENTA_ROUTE },
            { caption: 'Типы перекрытия', path: Routes.TIPPEREKRITIA_ROUTE },
            { caption: 'Типы кровли', path: Routes.TIPKROVLI_ROUTE },
            { caption: 'Типы пола', path: Routes.TIPPOLA_ROUTE },
            { caption: 'Типы проемов', path: Routes.TIPPROEMA_ROUTE },
            { caption: 'Типы отделки', path: Routes.TIPOTDELKI_ROUTE },
            { caption: 'Типы перегородок', path: Routes.TIPPEREGORODOK_ROUTE }
        ]
    },
    {
        title: 'Основные характеристики объектов',
        nodes: [
            { caption: 'Единицы измерения', path: Routes.EDIZMERENIA_ROUTE },
            { caption: 'Фонды', path: Routes.FOND_ROUTE },
            { caption: 'Типы сооружений', path: Routes.TIPSOORUZHENIYA_ROUTE },
            { caption: 'Виды сооружений', path: Routes.VIDSOORUZHENIYA_ROUTE },
            { caption: 'Виды тех. паспорта', path: Routes.VIDTEHPASPORTA_ROUTE },
            { caption: 'Виды износа', path: Routes.VIDIZNOSA_ROUTE },
            { caption: 'Виды этажей', path: Routes.ETAZHI_ROUTE },
            { caption: 'Виды констр. элементов', path: Routes.ELEMENT_ROUTE },
            { caption: 'Виды помещений', path: Routes.POMESHENIE_ROUTE },
            { caption: 'Виды благоустройства', path: Routes.BLAGOUSTROISTVO_ROUTE }
        ]
    },
    {
        title: 'Информация о владельцах',
        nodes: [
            { caption: 'Виды собственности', path: Routes.VIDSOBSTVENNOSTI_ROUTE },
            { caption: 'Типы владения', path: Routes.TIPVLADENIYA_ROUTE },
            { caption: 'Физ./юр. лица', path: Routes.KONTRAGENT_ROUTE }
        ]
    }
]

export const authRoutes = [
    { path: Routes.HOME_ROUTE, Component: Home },
    { path: Routes.ABOUT_ROUTE, Component: About },
    { path: Routes.SPRAVOCHNIK_ROUTE, Component: Dictionaries },

    { path: Routes.TD_ROUTE + '/new', Component: TehnicheskoeDeloItem },
    { path: Routes.TD_ROUTE + '/:id', Component: TehnicheskoeDeloItem },
    { path: Routes.TD_ROUTE + '/:useState?', Component: TehnicheskoeDeloList },
    
    { path: Routes.FIAS_ROUTE + '/new', Component: FiasItem },
    { path: Routes.FIAS_ROUTE + '/:id', Component: FiasItem },
    { path: Routes.FIAS_ROUTE, Component: FiasList },

    { path: Routes.KLADR_ROUTE + '/new', Component: KladrItem },
    { path: Routes.KLADR_ROUTE + '/:id', Component: KladrItem },
    { path: Routes.KLADR_ROUTE, Component: KladrList },

    { path: Routes.MATERIAL_ROUTE + '/new', Component: MaterialItem },
    { path: Routes.MATERIAL_ROUTE + '/:id', Component: MaterialItem },
    { path: Routes.MATERIAL_ROUTE, Component: MaterialList },

    { path: Routes.TIPSTEN_ROUTE + '/new', Component: TipStenItem },
    { path: Routes.TIPSTEN_ROUTE + '/:id', Component: TipStenItem },
    { path: Routes.TIPSTEN_ROUTE, Component: TipStenList },

    { path: Routes.TIPSANTEHNIKI_ROUTE + '/new', Component: TipSantehnikiItem },
    { path: Routes.TIPSANTEHNIKI_ROUTE + '/:id', Component: TipSantehnikiItem },
    { path: Routes.TIPSANTEHNIKI_ROUTE, Component: TipSantehnikiList },

    { path: Routes.TIPFUNDAMENTA_ROUTE + '/new', Component: TipFundamentaItem },
    { path: Routes.TIPFUNDAMENTA_ROUTE + '/:id', Component: TipFundamentaItem },
    { path: Routes.TIPFUNDAMENTA_ROUTE, Component: TipFundamentaList },

    { path: Routes.TIPPEREKRITIA_ROUTE + '/new', Component: TipPerekritiaItem },
    { path: Routes.TIPPEREKRITIA_ROUTE + '/:id', Component: TipPerekritiaItem },
    { path: Routes.TIPPEREKRITIA_ROUTE, Component: TipPerekritiaList },

    { path: Routes.TIPKROVLI_ROUTE + '/new', Component: TipKrovliItem },
    { path: Routes.TIPKROVLI_ROUTE + '/:id', Component: TipKrovliItem },
    { path: Routes.TIPKROVLI_ROUTE, Component: TipKrovliList },

    { path: Routes.TIPPOLA_ROUTE + '/new', Component: TipPolaItem },
    { path: Routes.TIPPOLA_ROUTE + '/:id', Component: TipPolaItem },
    { path: Routes.TIPPOLA_ROUTE, Component: TipPolaList },

    { path: Routes.TIPPROEMA_ROUTE + '/new', Component: TipProemaItem },
    { path: Routes.TIPPROEMA_ROUTE + '/:id', Component: TipProemaItem },
    { path: Routes.TIPPROEMA_ROUTE, Component: TipProemaList },

    { path: Routes.TIPOTDELKI_ROUTE + '/new', Component: TipOtdelkiItem },
    { path: Routes.TIPOTDELKI_ROUTE + '/:id', Component: TipOtdelkiItem },
    { path: Routes.TIPOTDELKI_ROUTE, Component: TipOtdelkiList },

    { path: Routes.TIPPEREGORODOK_ROUTE + '/new', Component: TipPeregorodokItem },
    { path: Routes.TIPPEREGORODOK_ROUTE + '/:id', Component: TipPeregorodokItem },
    { path: Routes.TIPPEREGORODOK_ROUTE, Component: TipPeregorodokList },

    { path: Routes.EDIZMERENIA_ROUTE + '/new', Component: EdIzmereniaItem },
    { path: Routes.EDIZMERENIA_ROUTE + '/:id', Component: EdIzmereniaItem },
    { path: Routes.EDIZMERENIA_ROUTE, Component: EdIzmereniaList },

    { path: Routes.TIPSOORUZHENIYA_ROUTE + '/new', Component: TipSooruzhItem },
    { path: Routes.TIPSOORUZHENIYA_ROUTE + '/:id', Component: TipSooruzhItem },
    { path: Routes.TIPSOORUZHENIYA_ROUTE, Component: TipSooruzhList },

    { path: Routes.FOND_ROUTE + '/new', Component: FondItem },
    { path: Routes.FOND_ROUTE + '/:id', Component: FondItem },
    { path: Routes.FOND_ROUTE, Component: FondList },

    { path: Routes.VIDTEHPASPORTA_ROUTE + '/new', Component: VidTehPasportaItem },
    { path: Routes.VIDTEHPASPORTA_ROUTE + '/:id', Component: VidTehPasportaItem },
    { path: Routes.VIDTEHPASPORTA_ROUTE, Component: VidTehPasportaList },

    { path: Routes.VIDIZNOSA_ROUTE + '/new', Component: VidIznosaItem },
    { path: Routes.VIDIZNOSA_ROUTE + '/:id', Component: VidIznosaItem },
    { path: Routes.VIDIZNOSA_ROUTE, Component: VidIznosaList },

    { path: Routes.ETAZHI_ROUTE + '/new', Component: EtazhiItem },
    { path: Routes.ETAZHI_ROUTE + '/:id', Component: EtazhiItem },
    { path: Routes.ETAZHI_ROUTE, Component: EtazhiList },

    { path: Routes.ELEMENT_ROUTE + '/new', Component: ElementItem },
    { path: Routes.ELEMENT_ROUTE + '/:id', Component: ElementItem },
    { path: Routes.ELEMENT_ROUTE, Component: ElementList },

    { path: Routes.POMESHENIE_ROUTE + '/new', Component: PomeshenieItem },
    { path: Routes.POMESHENIE_ROUTE + '/:id', Component: PomeshenieItem },
    { path: Routes.POMESHENIE_ROUTE, Component: PomeshenieList },

    { path: Routes.BLAGOUSTROISTVO_ROUTE + '/new', Component: BlagoustrItem },
    { path: Routes.BLAGOUSTROISTVO_ROUTE + '/:id', Component: BlagoustrItem },
    { path: Routes.BLAGOUSTROISTVO_ROUTE, Component: BlagoustrList },

    { path: Routes.VIDSOORUZHENIYA_ROUTE + '/new', Component: VidSooruzhItem },
    { path: Routes.VIDSOORUZHENIYA_ROUTE + '/:id', Component: VidSooruzhItem },
    { path: Routes.VIDSOORUZHENIYA_ROUTE, Component: VidSooruzhList },

    { path: Routes.VIDSOBSTVENNOSTI_ROUTE + '/new', Component: VidSobstvItem },
    { path: Routes.VIDSOBSTVENNOSTI_ROUTE + '/:id', Component: VidSobstvItem },
    { path: Routes.VIDSOBSTVENNOSTI_ROUTE, Component: VidSobstvList },

    { path: Routes.TIPVLADENIYA_ROUTE + '/new', Component: TipVladeniyaItem },
    { path: Routes.TIPVLADENIYA_ROUTE + '/:id', Component: TipVladeniyaItem },
    { path: Routes.TIPVLADENIYA_ROUTE, Component: TipVladeniyaList },

    { path: Routes.KONTRAGENT_ROUTE + '/new', Component: KontragentItem },
    { path: Routes.KONTRAGENT_ROUTE + '/:id', Component: KontragentItem },
    { path: Routes.KONTRAGENT_ROUTE, Component: KontragentList }
]

export const publicRoutes = [

]