import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts/guideline-font/guideline-icons.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import { UserStore, userStore } from "./store/UserStore";
import { MenuStore, menuStore } from "./store/MenuStore";
import AccessStore from "./store/AccessStore";

import './fonts/GOSTUI2/GOSTUI2-w170-regular_g_temp.ttf';
import './fonts/GOSTUI2/GOSTUI2-w706-bold_g_temp.ttf';

interface AppContextInterface {
  user: UserStore;
  menu: MenuStore;
  access: AccessStore;
}

export const Context = createContext<AppContextInterface | null>(null)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const accessStore = new AccessStore(userStore)

root.render(
  <HashRouter>
    <Context.Provider value={{ user: userStore, menu: menuStore, access: accessStore }}>
      <App />
    </Context.Provider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
