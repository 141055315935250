export enum ObjectType {
    Здание = 'Здание',
    Сооружение = 'Сооружение',
    НекотораяПостройка = 'Некоторая постройка'
}

export enum StatusObjekta {
    Текущее = 'Текущее',
    Архивное = 'Архивное',
    Снос = 'Снос'
}

export enum TipObjekta {
    Гаражи = 'Гаражи',
    ЖилыеЗдания = 'Жилые здания',
    Сады = 'Сады',
    Сети = 'Сети',
    Иное = 'Иное'
}

export enum ExecutionStatus {
    Unexecuted = 'Unexecuted',
    Executed = 'Executed'
}

export enum OperationType {
    Insert = 'Insert',
    Update = 'Update',
    Delete = 'Delete',
    Ratify = 'Ratify'
}