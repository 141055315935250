import { $odataHost as $host } from "./index";
import { DataObjectDescription, PartialRecord, PropertyDescriptions, ViewProperty } from "../descriptions/DescriptionTypes";
import { Predicate } from "../utils/filters";

export const fetchAllOdata = async <T extends PropertyDescriptions>(modelDescription: DataObjectDescription<T>, view: PartialRecord<keyof T, ViewProperty>,
    options?: { search?: Predicate, signal?: AbortSignal, limit?: number, page?: number, sort?: string, sortOrder?: 'asc' | 'desc' }): Promise<{ count: number, jsonItems: any[] }> => {

    const { select, expand } = modelDescription.viewToQuery(view)
    const filter = options?.search ? options?.search.build(modelDescription) : undefined

    const count = 'true'

    const orderby = options?.sort && options.sortOrder ? options?.sort.replaceAll('.', '/') + ' ' + options.sortOrder : undefined

    const type = modelDescription.odataClassName;

    const skip = options?.page && options?.limit ? (options?.page - 1) * options?.limit : undefined;
    const { data } = await $host.get(type, { signal: options?.signal, params: { $top: options?.limit, $skip: skip, $select: select, $expand: expand, $count: count, $filter: filter, $orderby: orderby } })

    const items = data["value"].map((p: any) => {
        return modelDescription.deserialize(p);
    });

    return { jsonItems: items, count: data["@odata.count"] };
}

export const fetchOneOdata = async <T extends PropertyDescriptions>(modelDescription: DataObjectDescription<T>, view: PartialRecord<keyof T, ViewProperty>, id: string): Promise<any | null> => {

    const { select, expand } = modelDescription.viewToQuery(view)

    const filter = '__PrimaryKey eq ' + id

    const type = modelDescription.odataClassName;

    const { data } = await $host.get(type, { params: { $select: select, $expand: expand, $filter: filter } })

    if (data["value"].length === 0) return null;

    return modelDescription.deserialize(data["value"][0])
}

export const odataCreate = async <T extends PropertyDescriptions>(modelDescription: DataObjectDescription<T>, object: string): Promise<any | null> => {
    const type = modelDescription.odataClassName;

    return $host.post(type, object, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(({ data }) => {
        return data
    }).catch((error) => {
        console.log(error);
        throw error;
    })
}

export const odataUpdate = async <T extends PropertyDescriptions>(modelDescription: DataObjectDescription<T>, object: string, id: string): Promise<any | null> => {
    const type = modelDescription.odataClassName;

    const result = await $host.patch(type + '(' + id + ')', object, {
        headers: {
            'Content-Type': 'application/json'
        }
    })

    return result.data
}

export const odataDelete = async <T extends PropertyDescriptions>(modelDescription: DataObjectDescription<T>, id: string): Promise<any | null> => {
    const type = modelDescription.odataClassName;

    const result = await $host.delete(type + '(' + id + ')')

    return result.data
}

export const loadFile = async (file: File): Promise<any | null> => {
    var formData = new FormData();
    formData.append("files[]", file);
    const { data } = await $host.post('File', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })

    return data
}
