import { useEffect, useRef, useState } from 'react'

import { useParams } from 'react-router-dom'
import { fetchOneOdata } from '../http/ODataApi'
import { $apiHost } from '../http/index'
import { useHistory } from 'react-router'

import Lookup from '../components/Lookup/Lookup'
import CheckAccessForm from '../components/CheckAccessForm'
import NumberInput from '../components/NumberInput'
import LabelCheckBoxInput from '../components/LabelCheckBoxInput'
import Input from '../components/Input'
import DropDown from '../components/DropDown'
import GroupEdit from '../components/GroupEdit'
import DateInput from '../components/DateInput'
import DetailList from '../components/DetailList'
import WebFilesControl from '../components/WebFilesControl/WebFilesControl'

import { Button, Col, Container, Modal, Row, Spinner, Stack } from 'react-bootstrap'
import { Formik, Form, FormikProps, FormikValues } from 'formik';

import { AdrPlanDesc, KvartiraDesc, SobstvennikDesc, TehnicheskoeDeloDesc, ZemelnyUchastokDesc } from '../descriptions/Descriptions';

import { State } from '../descriptions/State'

import { TipObjekta, StatusObjekta } from '../descriptions/Enums'

import {
    TipSooruzheniyaDesc, VidIznosaDesc, TipSantehnikiDesc, TipFundamentaDesc, TipPerekritiaDesc, TipKrovliDesc, TipPolaDesc,
    TipProemaDesc, TipOtdelkiDesc, TipPeregorodokDesc, VidSooruzheniyaDesc, TipStenDesc
} from '../descriptions/Dictionaries'

import KvartiraItem from './KvartiraItem'
import SobstvennikItem from './SobstvennikItem'

import * as Routes from '../utils/consts';

import { save, saveAndClose, reload } from '../utils/pageHelpers'

import AdrPlanItem from './AdrPlanItem'
import ClosableAlert from '../components/ClosableAlert'
import TipSooruzhItem from './TipSooruzhItem'
import TipFundamentaItem from './TipFundamentaItem'
import TipKrovliItem from './TipKrovliItem'
import TipOtdelkiItem from './TipOtdelkiItem'
import TipPeregorodokItem from './TipPeregorodokItem'
import TipPerekritiaItem from './TipPerekritiaItem'
import TipPolaItem from './TipPolaItem'
import TipProemaItem from './TipProemaItem'
import TipSantehnikiItem from './TipSantehnikiItem'
import VidIznosaItem from './VidIznosaItem'
import VidSooruzhItem from './VidSooruzhItem'
import ObjectAudit from '../components/ObjectAudit'
import TipStenItem from './TipStenItem'
import CheckBoxInput from '../components/CheckBoxInput'
import WebFileSelect from '../components/WebFilesControl/WebFileSelect'
import { Access } from '../components/CheckAccessForm'

import { createContext, useContext } from 'react';
import { previewStore, PreviewStore } from "../store/PreviewStore";
import { observer } from "mobx-react-lite";
import { useQuery } from '../hooks/useQuery'

interface Params {
    id?: string
}

const TehnicheskoeDeloForm = observer((props: { access: Access, context: React.Context<PreviewStore | null> }) => {
    const params = useParams<Params>()

    const [objectId, setObjectId] = useState<string | null>(params.id || null)
    const [newObject, setNewObject] = useState<boolean>(!objectId)

    const [loading, setLoading] = useState(true)
    const [docsPreparing, setDocsPreparing] = useState(false)
    const [selectDocs, setSelectDocs] = useState(false)
    const [showValidation, setShowValidation] = useState(false)

    const [readonly, setReadonly] = useState(!(newObject && props.access.full))

    const [error, setError] = useState<string | null>(null)
    const [saveError, setSaveError] = useState<string | null>(null)
    const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
    const [item, setItem] = useState<any>()

    const [showAudit, setShowAudit] = useState<boolean>(false)

    const history = useHistory()

    const formRef = useRef<FormikProps<FormikValues>>(null)

    const route = Routes.TD_ROUTE

    const context = useContext(props.context)

    const query = useQuery();
    
    useEffect(() => {
        setLoading(true)

        if (params.id) {
            fetchOneOdata(TehnicheskoeDeloDesc, TehnicheskoeDeloDesc.views.webE, params.id).then((result: any | null) => {
                setItem(result || {});

                context?.load(params.id || '', result?.FajlTD)

                setLoading(false)
            }).catch(() => {
                setLoading(false)
                setError('Невозможно получить данные')
            });
        } else {
            setLoading(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reloadDetList = (item: any, name: string): void => {
        if (!formRef.current) return

        let detail = formRef.current.values[name] as Array<any>
        if (!detail) detail = []
        if (detail.find((j) => { return j.id === item.id })) {
            detail = detail.map((j) => { return j.id === item.id ? Object.assign({}, item) : j; })
        } else {
            detail.push(item)
        }

        formRef.current.setFieldValue(name, [...detail])
    }

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.AdrPlan) {
            errors.AdrPlan = 'Required';
        }

        return errors;
    };

    const getReturnQuery = ():string => {
        const info = query.get('returnInfo')
        if (info) {
            return '?' + decodeURIComponent(info)
        }

        return ""
    }

    function onSubmit(fields: any, actions: any): Promise<any> {
        return TehnicheskoeDeloDesc.save({ state: newObject ? State.created : State.altered, item: fields }).then((data) => {
            actions.setSubmitting(false);

            if (newObject) {
                setObjectId(data.id)
                setNewObject(false)
            }

            setItem(data)

            context?.load(data.id || '', data?.FajlTD)

            formRef.current?.setValues(data)
            formRef.current?.resetForm({
                values: data
            })

            setSaveSuccess(true)
            setReadonly(true)

            return { newObject: newObject, route: route, id: data.id }
        }).catch((e) => {
            console.error(e)
            setSaveError('Произошла ошибка при сохранении')
        }).finally(() => {
            actions.setSubmitting(false);
        })
    }

    return (
        <Container fluid className={'edit-form'}>
            <Row className='edit-form-title'><div>Инвентарное дело</div></Row>
            <Row className='edit-form-buttons'>
                <div className='edit-form-toolbar'>
                    <div className='toolbar-default-buttons'>
                        <Button variant='light' size='sm' title={'К списку'} onClick={() => { history.push(route + getReturnQuery()) }}>
                            <i className='icon-guideline-Arrows-max-left'></i>
                        </Button>
                        <div className='divider'></div>
                        <Button variant="light" size="sm" title={'Обновить'} onClick={() => reload(history, route + '/' + (objectId || 'new'))}>
                            <i className='icon-guideline-refresh-r'></i>
                        </Button>
                        <div className='divider'></div>
                        {props.access.full && readonly &&
                            <Button variant="light" size="sm" onClick={() => { setReadonly(false) }}>
                                <i className="icon-guideline-edit-r me-1"></i>Редактировать
                            </Button>
                        }
                        {!newObject && <Button variant="light" size="sm" onClick={() => { setShowAudit(true) }}>
                            <i className="icon-guideline-show me-1"></i>Аудит
                        </Button>
                        }
                        {props.access.read && readonly && !newObject && <Button variant="light" size="sm" {...item?.FajlTD?.length > 0 ? {} : { disabled: true }}
                            onClick={() => {
                                setSelectDocs(true)
                            }}>
                            <i className="icon-guideline-book me-1"></i>Показать документы дела
                            {docsPreparing && <Spinner animation='border' variant='primary' aria-flowto='' />}
                        </Button>}
                    </div>
                    {props.access.full && !readonly &&
                        <div className="toolbar-custom-buttons">
                            <Button size="sm" onClick={() => { setShowValidation(true); save(formRef, history, null) }}>
                                Сохранить
                            </Button>
                            <Button size="sm" onClick={() => { setShowValidation(true); saveAndClose(formRef, history, route + getReturnQuery()) }}>
                                Сохранить и закрыть
                            </Button>
                        </div>
                    }
                    {saveError
                        ? <ClosableAlert className={'error'} text={saveError} onClose={() => setSaveError(null)} />
                        : ''
                    }
                    {saveSuccess
                        ? <ClosableAlert className={'success'} text={'Успешно сохранено!'} onClose={() => setSaveSuccess(false)} />
                        : ''
                    }
                </div>
            </Row>
            <Row className='edit-form-content'>
                {loading
                    ? <Container fluid className='d-flex justify-content-center save-loader'><Spinner animation='border' variant='primary' aria-flowto='' />Загрузка...</Container>
                    : error !== null
                        ? <div>{error}</div>
                        :
                        <Formik innerRef={formRef} enableReinitialize={true} initialValues={item || {}} validateOnMount={true} validate={validate} onSubmit={onSubmit}>
                            {({ errors, values, touched, setFieldValue, setTouched, dirty, isSubmitting, submitCount, handleReset }) => (
                                <Form>
                                    {isSubmitting
                                        ? <Container fluid className='save-loader d-flex justify-content-center'><Spinner animation='border' variant='primary' aria-flowto='' />Сохранение...</Container>
                                        : <Row className='two-columns-form-divider'>
                                            <Col sm={6}>
                                                <fieldset>
                                                    <legend>
                                                        Основная информация
                                                        <div className='caption'>
                                                            <label>Инв. номер&nbsp;</label>
                                                            <span>{values['AdrPlan']?.InventNomer}</span>
                                                        </div>
                                                        <div>
                                                            <div className={'wrapper'}>
                                                                <Lookup readonly={readonly}
                                                                    className={'modal-wide'}
                                                                    onLookupClick={(id: string, obj: any) => { setFieldValue('AdrPlan', obj) }}
                                                                    id={values['AdrPlan']?.id || ''}
                                                                    initialVal={values['AdrPlan']}
                                                                    dataLoaded={!loading} modelDescription={AdrPlanDesc} view={AdrPlanDesc.views.webD}
                                                                    autocomplete={false}
                                                                    autocompleteProp={'AdresStrokoi'}
                                                                    defaultSort={{ sortField: 'AdresStrokoi', sortOrder: 'asc' }}
                                                                    title={'Адресный паспорт'}
                                                                    edit={true}
                                                                    editForm={(id: string | null, onClose: any) => {
                                                                        return (<AdrPlanItem id={id} edit={true}
                                                                            onSave={(values: any) => {
                                                                                setFieldValue('AdrPlan', values)
                                                                                onClose()
                                                                            }}
                                                                            onClose={() => {
                                                                                onClose()
                                                                            }}></AdrPlanItem>)
                                                                    }}
                                                                ></Lookup>
                                                                {(touched['AdrPlan'] || newObject) && errors['AdrPlan'] && (
                                                                    <div className='validation'>*</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </legend>
                                                    <div className="fieldset-content">
                                                        <Row>
                                                            <Col sm={3}>
                                                                <label>Тип объекта</label>
                                                                <div className={'wrapper'}>
                                                                    <DropDown value={values['AdrPlan']?.TipObjekta} items={TipObjekta} readonly={true} onChange={() => { }}
                                                                    ></DropDown>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Статус</label>
                                                                <div className={'wrapper'}>
                                                                    <DropDown value={values['AdrPlan']?.Status} items={StatusObjekta} readonly={true} onChange={() => { }}
                                                                    ></DropDown>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Дата паспорта</label>
                                                                <div className={'wrapper'}>
                                                                    <DateInput readonly={true} name={'DataPasporta'} value={values['AdrPlan']?.DataPasporta} onChange={() => { }}
                                                                    ></DateInput>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Реестровая запись</label>
                                                                <div className={'wrapper'}>
                                                                    <Input readonly={true} value={values['AdrPlan']?.ReestrZap} onChange={() => { }}
                                                                    ></Input>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={9}>
                                                                <label>Наименование</label>
                                                                <div className={'wrapper'}>
                                                                    <Input readonly={true} value={values['AdrPlan']?.Naimenovanie} onChange={() => { }}
                                                                    ></Input>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Номер бокса</label>
                                                                <div className={'wrapper'}>
                                                                    <Input readonly={true} value={values['AdrPlan']?.NomerBoksa} onChange={() => { }}
                                                                    ></Input>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <label>Адрес объекта</label>
                                                                <div className={'wrapper'}>
                                                                    <Input readonly={true} value={values['AdrPlan']?.AdresStrokoi} onChange={() => { }}
                                                                    ></Input>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={3}>
                                                                <label>Исполнитель</label>
                                                                <div className={'wrapper'}>
                                                                    <Input readonly={true} value={values['AdrPlan']?.Ispolnitel} onChange={() => { }}
                                                                    ></Input>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Реестр РКН</label>
                                                                <div className={'wrapper'}>
                                                                    <Input readonly={true} value={values['AdrPlan']?.Ispolnitel} onChange={() => { }}
                                                                    ></Input>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Стоимость</label>
                                                                <div className={'wrapper'}>
                                                                    <NumberInput readonly={true} value={values['AdrPlan']?.Stoimost} onChange={() => { }}
                                                                    ></NumberInput>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Год стоимости</label>
                                                                <div className={'wrapper'}>
                                                                    <NumberInput readonly={true} value={values['AdrPlan']?.GodStoimosti} onChange={() => { }}
                                                                    ></NumberInput>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={9}>
                                                                <label>Вид тех. паспорта</label>
                                                                <Input readonly={true} value={values['AdrPlan']?.TehPasport?.Naimenovanie} onChange={() => { }}
                                                                ></Input>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Фонд</label>
                                                                <Input readonly={true} value={values['AdrPlan']?.Fond?.Naimenovanie} onChange={() => { }}
                                                                ></Input>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={9}>
                                                                <label>Исполнитель ОТУТИ</label>
                                                                <Input readonly={true} value={values['AdrPlan']?.IspolnitelOTUTI} onChange={() => { }}
                                                                ></Input>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>База Росреестра</label>
                                                                <CheckBoxInput readonly={true} value={values['AdrPlan']?.BDRosreestra} onChange={() => { }}
                                                                ></CheckBoxInput>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <legend>Собственники</legend>
                                                    <div className="fieldset-content detail-list list-without-border list-without-thead sobstvenniki-list">
                                                        {newObject
                                                            ? <div>Перед добавлением зависимых объектов, необходимо сохранить инвентарное дело</div>
                                                            : <DetailList access={props.access.extra && props.access.extra['Sobstvennik'] ? { read: props.access.extra['Sobstvennik'].read, full: props.access.extra['Sobstvennik'].full && !readonly } : { read: false, full: false }}
                                                                dataLoaded={!loading && !isSubmitting}
                                                                items={values['Sobstvennik']}
                                                                view={SobstvennikDesc.views.webD}
                                                                modelDescription={SobstvennikDesc}
                                                                title={'Собственник'}
                                                                update={(v: any[]) => { setFieldValue('Sobstvennik', v) }}
                                                                form={(id: string | null, onClose: any) => {
                                                                    return (
                                                                        <SobstvennikItem id={id} edit={!readonly} tehDelo={objectId || ''}
                                                                            onClose={() => { onClose() }}
                                                                            onSave={(item: any) => { reloadDetList(item, 'Sobstvennik'); onClose(); }}
                                                                        ></SobstvennikItem>
                                                                    )
                                                                }}></DetailList>
                                                        }
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <div className="fieldset-content">
                                                        <Row>
                                                            <Col sm={3}>
                                                                <label>Год постройки</label>
                                                                <div className={'wrapper'}>
                                                                    <NumberInput readonly={readonly} value={values['GodPostroiki']} onChange={(v: string) => { setFieldValue('GodPostroiki', v) }}
                                                                    ></NumberInput>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Год преобразования</label>
                                                                <div className={'wrapper'}>
                                                                    <NumberInput readonly={readonly} value={values['GodPriobreteniya']} onChange={(v: string) => { setFieldValue('GodPriobreteniya', v) }}
                                                                    ></NumberInput>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Год кап. ремонта</label>
                                                                <div className={'wrapper'}>
                                                                    <NumberInput readonly={readonly} value={values['GodKapRemonta']} onChange={(v: string) => { setFieldValue('GodKapRemonta', v) }}
                                                                    ></NumberInput>
                                                                </div>
                                                            </Col>
                                                            <Col sm={3}>
                                                                <label>Дата сноса</label>
                                                                <div className={'wrapper'}>
                                                                    <DateInput readonly={readonly}
                                                                        name={'DataSnosa'}
                                                                        value={values['DataSnosa']}
                                                                        onChange={(date: Date | null) => { setFieldValue('DataSnosa', date) }}
                                                                    ></DateInput>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <label>Тип сооружения</label>
                                                                <div className={'wrapper'}>
                                                                    <Lookup readonly={readonly}
                                                                        onLookupClick={(e: string) => { setFieldValue('TipSooruzheniya', e ? { id: e } : null) }}
                                                                        id={values['TipSooruzheniya']?.id || ''}
                                                                        initialVal={values['TipSooruzheniya']}
                                                                        dataLoaded={!loading} modelDescription={TipSooruzheniyaDesc} view={TipSooruzheniyaDesc.views.webL}
                                                                        autocompleteProp='Naimenovanie'
                                                                        defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                        title={'Тип сооружения'}
                                                                        showObjectForm={(viewedId: string) => { return (<TipSooruzhItem id={viewedId} view={true} />) }}
                                                                    ></Lookup>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <label>Вид сооружения</label>
                                                                <div className={'wrapper'}>
                                                                    <Lookup readonly={readonly}
                                                                        onLookupClick={(e: string) => { setFieldValue('VidSooruzheniya', e ? { id: e } : null) }}
                                                                        id={values['VidSooruzheniya']?.id || ''}
                                                                        initialVal={values['VidSooruzheniya']}
                                                                        dataLoaded={!loading} modelDescription={VidSooruzheniyaDesc} view={VidSooruzheniyaDesc.views.webL}
                                                                        autocompleteProp='Naimenovanie'
                                                                        defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                        title={'Вид сооружения'}
                                                                        showObjectForm={(viewedId: string) => { return (<VidSooruzhItem id={viewedId} view={true} />) }}
                                                                    ></Lookup>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={6}>
                                                                <label>Износ стен</label>
                                                                <div className={'wrapper'}>
                                                                    <NumberInput readonly={readonly} int={false} value={values['IznosStenObshii']} onChange={(v: string) => { setFieldValue('IznosStenObshii', v) }}
                                                                    ></NumberInput>
                                                                </div>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <label>Износ фундамента</label>
                                                                <div className={'wrapper'}>
                                                                    <NumberInput readonly={readonly} int={false} value={values['IznosFundamentaObshii']} onChange={(v: string) => { setFieldValue('IznosFundamentaObshii', v) }}
                                                                    ></NumberInput>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm={12}>
                                                                <label>Вид износа</label>
                                                                <div className={'wrapper'}>
                                                                    <Lookup readonly={readonly}
                                                                        onLookupClick={(e: string) => { setFieldValue('VidIznosa', e ? { id: e } : null) }}
                                                                        id={values['VidIznosa']?.id || ''}
                                                                        initialVal={values['VidIznosa']}
                                                                        dataLoaded={!loading} modelDescription={VidIznosaDesc} view={VidIznosaDesc.views.webL}
                                                                        autocompleteProp='Naimenovanie'
                                                                        defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                        title={'Вид износа'}
                                                                        showObjectForm={(viewedId: string) => { return (<VidIznosaItem id={viewedId} view={true} />) }}
                                                                    ></Lookup>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <legend>Благоустройство</legend>
                                                    <div className="fieldset-content chips">
                                                        <LabelCheckBoxInput readonly={readonly} label={'Центральное отопление'} name={'NalichieCentrOtopleniya'} value={values['NalichieCentrOtopleniya']} onChange={(v: boolean) => { setFieldValue('NalichieCentrOtopleniya', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Электричество'} name={'NalichieElektrichestva'} value={values['NalichieElektrichestva']} onChange={(v: boolean) => { setFieldValue('NalichieElektrichestva', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Печное отопление'} name={'NaliciePechOtopleniya'} value={values['NaliciePechOtopleniya']} onChange={(v: boolean) => { setFieldValue('NaliciePechOtopleniya', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Радио'} name={'NalichieRadio'} value={values['NalichieRadio']} onChange={(v: boolean) => { setFieldValue('NalichieRadio', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Телевидение'} name={'NalichieTV'} value={values['NalichieTV']} onChange={(v: boolean) => { setFieldValue('NalichieTV', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Ванная комната'} name={'NalichieVannoi'} value={values['NalichieVannoi']} onChange={(v: boolean) => { setFieldValue('NalichieVannoi', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Водоснабжение'} name={'NalichieVodosnabzheniya'} value={values['NalichieVodosnabzheniya']} onChange={(v: boolean) => { setFieldValue('NalichieVodosnabzheniya', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Горячая вода'} name={'NalichieGorVody'} value={values['NalichieGorVody']} onChange={(v: boolean) => { setFieldValue('NalichieGorVody', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Вентиляция'} name={'NalichieVentilyacii'} value={values['NalichieVentilyacii']} onChange={(v: boolean) => { setFieldValue('NalichieVentilyacii', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Газ'} name={'NalichieGazosnabzheniya'} value={values['NalichieGazosnabzheniya']} onChange={(v: boolean) => { setFieldValue('NalichieGazosnabzheniya', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Мусоропровод'} name={'NalichieMusoroprovoda'} value={values['NalichieMusoroprovoda']} onChange={(v: boolean) => { setFieldValue('NalichieMusoroprovoda', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Лифт'} name={'NalichieLifta'} value={values['NalichieLifta']} onChange={(v: boolean) => { setFieldValue('NalichieLifta', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Канализация'} name={'NalichieKanalizacii'} value={values['NalichieKanalizacii']} onChange={(v: boolean) => { setFieldValue('NalichieKanalizacii', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Телефон'} name={'NalichieTelefona'} value={values['NalichieTelefona']} onChange={(v: boolean) => { setFieldValue('NalichieTelefona', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Эл. плита'} name={'NalichieElPlity'} value={values['NalichieElPlity']} onChange={(v: boolean) => { setFieldValue('NalichieElPlity', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Дополнительное телевидение'} name={'NalichieDopTV'} value={values['NalichieDopTV']} onChange={(v: boolean) => { setFieldValue('NalichieDopTV', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Другое отопление'} name={'NalichieDrugogoOtopleniya'} value={values['NalichieDrugogoOtopleniya']} onChange={(v: boolean) => { setFieldValue('NalichieDrugogoOtopleniya', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Бассейн'} name={'NalicieBasseina'} value={values['NalicieBasseina']} onChange={(v: boolean) => { setFieldValue('NalicieBasseina', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Сауна'} name={'NalicieSauny'} value={values['NalicieSauny']} onChange={(v: boolean) => { setFieldValue('NalicieSauny', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Сигнализация'} name={'NalichieSignalizacii'} value={values['NalichieSignalizacii']} onChange={(v: boolean) => { setFieldValue('NalichieSignalizacii', v) }}
                                                        ></LabelCheckBoxInput>
                                                        <LabelCheckBoxInput readonly={readonly} label={'Другая вентиляция'} name={'NalichieDopVentilyazii'} value={values['NalichieDopVentilyazii']} onChange={(v: boolean) => { setFieldValue('NalichieDopVentilyazii', v) }}
                                                        ></LabelCheckBoxInput>
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <legend>Файлы</legend>
                                                    <div className="fieldset-content">
                                                        <WebFilesControl readonly={readonly} context={props.context}
                                                            onChange={(files: any[]) => { setFieldValue('FajlTD', files) }}
                                                            dataLoaded={!loading} list={false} files={item?.FajlTD}
                                                        ></WebFilesControl>
                                                    </div>
                                                </fieldset>
                                            </Col>
                                            <Col sm={6}>
                                                <fieldset>
                                                    <legend>Конструктивные элементы</legend>
                                                    <div className={readonly ? 'fieldset-content structural-elements-sheet readonly' : 'fieldset-content structural-elements-sheet'}>
                                                        {!readonly &&
                                                            <Row className="structural-elements-thead">
                                                                <Col>
                                                                    <Row>
                                                                        <Col sm={2}><label>Элемент</label></Col>
                                                                        <Col sm={4}><label>Материал</label></Col>
                                                                        <Col sm={4}><label>Тех. состояние</label></Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col sm={2}>
                                                                    <Row>
                                                                        <Col><label>Износ</label></Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Фундамент</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Фундамент</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipFundamenta', e ? { id: e } : null) }}
                                                                                id={values['TipFundamenta']?.id || ''}
                                                                                initialVal={values['TipFundamenta']}
                                                                                dataLoaded={!loading} modelDescription={TipFundamentaDesc} view={TipFundamentaDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип фундамента'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipFundamentaItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostFundamenta']} onChange={(v: string | null) => { setFieldValue('TehSostFundamenta', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosFundamenta']} onChange={(v: string) => { setFieldValue('IznosFundamenta', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Стены наружные</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Стены наружные</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipStenNaruzh', e ? { id: e } : null) }}
                                                                                id={values['TipStenNaruzh']?.id || ''}
                                                                                initialVal={values['TipStenNaruzh']}
                                                                                dataLoaded={!loading} modelDescription={TipStenDesc} view={TipStenDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип стен'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipStenItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostNaruzhSten']} onChange={(v: string | null) => { setFieldValue('TehSostNaruzhSten', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Стены внутренние</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Стены внутренние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipStenVnutr', e ? { id: e } : null) }}
                                                                                id={values['TipStenVnutr']?.id || ''}
                                                                                initialVal={values['TipStenVnutr']}
                                                                                dataLoaded={!loading} modelDescription={TipStenDesc} view={TipStenDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип стен'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipStenItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostVnutrSten']} onChange={(v: string | null) => { setFieldValue('TehSostVnutrSten', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosSten']} onChange={(v: string) => { setFieldValue('IznosSten', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Перегородки</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Перегородки</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipPeregorodok', e ? { id: e } : null) }}
                                                                                id={values['TipPeregorodok']?.id || ''}
                                                                                initialVal={values['TipPeregorodok']}
                                                                                dataLoaded={!loading} modelDescription={TipPeregorodokDesc} view={TipPeregorodokDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип перегородок'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipPeregorodokItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostPeregorodok']} onChange={(v: string | null) => { setFieldValue('TehSostPeregorodok', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosPeregorodok']} onChange={(v: string) => { setFieldValue('IznosPeregorodok', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Перекрытия чердачные</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Перекрытия чердачные</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipPerekritiaCher', e ? { id: e } : null) }}
                                                                                id={values['TipPerekritiaCher']?.id || ''}
                                                                                initialVal={values['TipPerekritiaCher']}
                                                                                dataLoaded={!loading} modelDescription={TipPerekritiaDesc} view={TipPerekritiaDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип перекрытия'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipPerekritiaItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostPerekrytCher']} onChange={(v: string | null) => { setFieldValue('TehSostPerekrytCher', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Перекрытия межэтажные</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Перекрытия межэтажные</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipPerekritiaMezh', e ? { id: e } : null) }}
                                                                                id={values['TipPerekritiaMezh']?.id || ''}
                                                                                initialVal={values['TipPerekritiaMezh']}
                                                                                dataLoaded={!loading} modelDescription={TipPerekritiaDesc} view={TipPerekritiaDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип перекрытия'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipPerekritiaItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostPerekrMezh']} onChange={(v: string | null) => { setFieldValue('TehSostPerekrMezh', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Перекрытия цокольные</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Перекрытия цокольные</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipPerekritiaPodv', e ? { id: e } : null) }}
                                                                                id={values['TipPerekritiaPodv']?.id || ''}
                                                                                initialVal={values['TipPerekritiaPodv']}
                                                                                dataLoaded={!loading} modelDescription={TipPerekritiaDesc} view={TipPerekritiaDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип перекрытия'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipPerekritiaItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostPerekrPodv']} onChange={(v: string | null) => { setFieldValue('TehSostPerekrPodv', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosPerekryt']} onChange={(v: string) => { setFieldValue('IznosPerekryt', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Крыша</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Крыша</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipKrovli', e ? { id: e } : null) }}
                                                                                id={values['TipKrovli']?.id || ''}
                                                                                initialVal={values['TipKrovli']}
                                                                                dataLoaded={!loading} modelDescription={TipKrovliDesc} view={TipKrovliDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип кровли'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipKrovliItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostKrysh']} onChange={(v: string | null) => { setFieldValue('TehSostKrysh', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosKryshi']} onChange={(v: string) => { setFieldValue('IznosKryshi', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Полы</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Полы</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipPola', e ? { id: e } : null) }}
                                                                                id={values['TipPola']?.id || ''}
                                                                                initialVal={values['TipPola']}
                                                                                dataLoaded={!loading} modelDescription={TipPolaDesc} view={TipPolaDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип пола'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipPolaItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostPola']} onChange={(v: string | null) => { setFieldValue('TehSostPola', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosPolov']} onChange={(v: string) => { setFieldValue('IznosPolov', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Оконные проемы</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Оконные проемы</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipOkonProema', e ? { id: e } : null) }}
                                                                                id={values['TipOkonProema']?.id || ''}
                                                                                initialVal={values['TipOkonProema']}
                                                                                dataLoaded={!loading} modelDescription={TipProemaDesc} view={TipProemaDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип оконного проема'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipProemaItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostOkonProem']} onChange={(v: string | null) => { setFieldValue('TehSostOkonProem', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Дверные проемы</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Дверные проемы</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipDvernProema', e ? { id: e } : null) }}
                                                                                id={values['TipDvernProema']?.id || ''}
                                                                                initialVal={values['TipDvernProema']}
                                                                                dataLoaded={!loading} modelDescription={TipProemaDesc} view={TipProemaDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип дверного проема'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipProemaItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostDvernProem']} onChange={(v: string | null) => { setFieldValue('TehSostDvernProem', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosOkon']} onChange={(v: string) => { setFieldValue('IznosOkon', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Наружная отделка</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Наружная отделка</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipOtdelkiNaruzh', e ? { id: e } : null) }}
                                                                                id={values['TipOtdelkiNaruzh']?.id || ''}
                                                                                initialVal={values['TipOtdelkiNaruzh']}
                                                                                dataLoaded={!loading} modelDescription={TipOtdelkiDesc} view={TipOtdelkiDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип отделки'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipOtdelkiItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostOtdelkaNar']} onChange={(v: string | null) => { setFieldValue('TehSostOtdelkaNar', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Внутренняя отделка</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Внутренняя отделка</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipOtdelkiVn', e ? { id: e } : null) }}
                                                                                id={values['TipOtdelkiVn']?.id || ''}
                                                                                initialVal={values['TipOtdelkiVn']}
                                                                                dataLoaded={!loading} modelDescription={TipOtdelkiDesc} view={TipOtdelkiDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип отделки'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipOtdelkiItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostOtdelkaVnutr']} onChange={(v: string | null) => { setFieldValue('TehSostOtdelkaVnutr', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosOtdelki']} onChange={(v: string) => { setFieldValue('IznosOtdelki', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Сантехническое состояние</label></Col>}
                                                                    <Col sm={4}>
                                                                        {readonly && <label>Сантехническое состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Lookup readonly={readonly}
                                                                                onLookupClick={(e: string) => { setFieldValue('TipSantehniki', e ? { id: e } : null) }}
                                                                                id={values['TipSantehniki']?.id || ''}
                                                                                initialVal={values['TipSantehniki']}
                                                                                dataLoaded={!loading} modelDescription={TipSantehnikiDesc} view={TipSantehnikiDesc.views.webL}
                                                                                autocompleteProp='Naimenovanie'
                                                                                defaultSort={{ sortField: 'Naimenovanie', sortOrder: 'asc' }}
                                                                                title={'Тип сантехники'}
                                                                                showObjectForm={(viewedId: string) => { return (<TipSantehnikiItem id={viewedId} view={true} />) }}
                                                                            ></Lookup>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        {readonly && <label>Тех. состояние</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostSanteh']} onChange={(v: string | null) => { setFieldValue('TehSostSanteh', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosSantehniki']} onChange={(v: string) => { setFieldValue('IznosSantehniki', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className="row-first-level">
                                                            <Col>
                                                                <Row>
                                                                    {!readonly && <Col sm={2}><label>Прочее</label></Col>}
                                                                    <Col>
                                                                        {readonly && <label>Прочее</label>}
                                                                        <div className={'wrapper'}>
                                                                            <Input readonly={readonly} value={values['TehSostProch']} onChange={(v: string | null) => { setFieldValue('TehSostProch', v) }}
                                                                            ></Input>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            {readonly && <Col sm={1} className="decoration-border"></Col>}
                                                            <Col sm={2}>
                                                                <Row>
                                                                    <Col>
                                                                        {readonly && <label>Износ</label>}
                                                                        <div className={'wrapper'}>
                                                                            <NumberInput readonly={readonly} int={false} value={values['IznosProchee']} onChange={(v: string) => { setFieldValue('IznosProchee', v) }}
                                                                            ></NumberInput>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Row>
                                                        <GroupEdit readonly={readonly || !props.access.extra || !props.access.extra['ZemelnyUchastok']?.full}
                                                            errors={errors.ZemelnyUchastok || {}} showErrors={showValidation}
                                                            title={'Земельный участок'} view={ZemelnyUchastokDesc.views.webD} modelDescription={ZemelnyUchastokDesc}
                                                            dataLoaded={!loading && !isSubmitting}
                                                            headers={true}
                                                            items={values['ZemelnyUchastok']}
                                                            onChange={(v: any[]) => {
                                                                setFieldValue('ZemelnyUchastok', v)
                                                            }}
                                                        ></GroupEdit>
                                                    </Row>
                                                    <Row>
                                                        <fieldset>
                                                            <legend>Квартира</legend>
                                                            <div className="fieldset-content detail-list list-without-border">
                                                                {newObject
                                                                    ? <div>Перед добавлением зависимых объектов, необходимо сохранить инвентарное дело</div>
                                                                    : <DetailList access={props.access.extra && props.access.extra['Kvartira'] ? { read: props.access.extra['Kvartira'].read, full: props.access.extra['Kvartira'].full && !readonly } : { read: false, full: false }}
                                                                        dataLoaded={!loading && !isSubmitting}
                                                                        items={values['Kvartira']}
                                                                        view={KvartiraDesc.views.webD}
                                                                        modelDescription={KvartiraDesc}
                                                                        title={'Квартира'}
                                                                        update={(v: any[]) => { setFieldValue('Kvartira', v) }}
                                                                        form={(id: string | null, onClose: any) => {
                                                                            return (
                                                                                <KvartiraItem id={id} edit={!readonly} tehDelo={objectId || ''}
                                                                                    onClose={() => { onClose() }}
                                                                                    onSave={(item: any) => { reloadDetList(item, 'Kvartira'); onClose(); }}
                                                                                ></KvartiraItem>
                                                                            )
                                                                        }}></DetailList>
                                                                }
                                                            </div>
                                                        </fieldset>
                                                    </Row>
                                                </fieldset>
                                            </Col>

                                        </Row>
                                    }
                                </Form>
                            )}
                        </Formik>
                }
            </Row>

            <Modal
                show={showAudit}
                onHide={() => { setShowAudit(false) }}
                size="lg"
                scrollable={true}
                centered>
                <Modal.Header closeButton>
                    Аудит
                </Modal.Header>
                <Modal.Body>
                    <ObjectAudit id={objectId || ''}></ObjectAudit>
                </Modal.Body>
            </Modal>

            <Modal
                show={selectDocs}
                onHide={() => { setSelectDocs(false) }}
                size="lg"
                scrollable={true}
                centered>
                <Modal.Body>
                    <WebFileSelect
                        files={item?.FajlTD}
                        preview={context?.preview}
                        onSubmit={(keys: any[]) => {
                            setSelectDocs(false)
                            setDocsPreparing(true);
                            let headers = { 'Content-Type': 'application/json; charset=utf-8' }
                            $apiHost.post('pdf/getDocs',
                                { pk: objectId, docs: keys },
                                { responseType: 'arraybuffer', headers }).then((data: any) => {
                                    var contentType = 'application/pdf';
                                    var blob = new Blob([data.data], { type: contentType });
                                    var url = window.URL.createObjectURL(blob);
                                    window.open(url, 'f');
                                    setDocsPreparing(false);
                                }).catch((e) => {
                                    setSaveError('Произошла ошибка при формировании документа')
                                    console.error(e);
                                    setDocsPreparing(false);
                                });
                        }}
                        onClose={() => { setSelectDocs(false) }}
                        submitTitle={'Сформировать документ'}
                        closeTitle={'Закрыть'}></WebFileSelect>
                </Modal.Body>
            </Modal>
        </Container>
    );
})

const TehnicheskoeDeloItem = () => {
    const Context = createContext<PreviewStore | null>(null)

    return (
        <CheckAccessForm modelName={TehnicheskoeDeloDesc.odataClassName}
            extraModels={[{ key: 'Sobstvennik', modelName: SobstvennikDesc.odataClassName },
            { key: 'ZemelnyUchastok', modelName: ZemelnyUchastokDesc.odataClassName },
            { key: 'Kvartira', modelName: KvartiraDesc.odataClassName }]}>
            {(access: Access) => {
                return (
                    <Context.Provider value={previewStore}>
                        <TehnicheskoeDeloForm access={access} context={Context}></TehnicheskoeDeloForm>
                    </Context.Provider>
                )
            }}
        </CheckAccessForm>
    );
}

export default TehnicheskoeDeloItem;