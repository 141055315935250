import { useEffect, useRef, useState } from "react";
import { fetchAllOdata } from "../http/ODataApi";
import { Container, Pagination, Row, Spinner, Table, Button } from "react-bootstrap";
import { getHeaders, getValues } from '../utils/viewHelpers'
import { DataObjectDescription, PartialRecord, ViewProperty, PropertyDescriptions } from "../descriptions/DescriptionTypes";
import { Predicate } from "../utils/filters";
import * as Icon from 'react-bootstrap-icons';
import { confirmAlert } from 'react-confirm-alert';
import { State } from "../descriptions/State";
import ClosableAlert from '../components/ClosableAlert'
import ListToolbar from "./ListToolbar";

interface Props<T extends PropertyDescriptions> {
  view: PartialRecord<keyof T, ViewProperty>
  modelDescription: DataObjectDescription<T>
  onRowClick: any
  filter?: Predicate
  access?: { read: boolean, full: boolean }
  route?: string
  toolbar: boolean
  defaultSort?: { sortField: string, sortOrder: 'asc' | 'desc' }
  defaultPage?: number
}

const List = <T extends PropertyDescriptions>(props: Props<T>) => {
  const perPage = 10
  const [page, setPage] = useState(props.defaultPage || 1)
  const [pagesCount, setPagesCount] = useState(1)

  const [sort, setSort] = useState(props.defaultSort)

  const [items, setItems] = useState<any[]>([])
  const [itemsCount, setItemsCount] = useState<number>(0)

  const [waiting, setWaiting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [checked, setChecked] = useState<any>({})

  const [removeError, setRemoveError] = useState<boolean>(false)
  const [message, setMessage] = useState<string | null>(null)

  const setResult = (result: any, pageNumber: number) => {
    const pageCount = Math.ceil(result.count / perPage)

    if (pageCount < pageNumber && pageNumber > 1) {
      setPage(1)
      loadData(1)
      return
    }

    setItems(result.jsonItems);
    setLoading(false)

    setItemsCount(result.count)
    setPagesCount(pageCount)
  }

  const loadData = (pageNumber: number) => {
    setLoading(true)

    return fetchAllOdata(props.modelDescription, props.view, { limit: perPage, page: pageNumber, search: props.filter, sort: sort?.sortField, sortOrder: sort?.sortOrder }).then((results) => {
      setResult(results, pageNumber)
    }).catch((e) => {
      console.error(e)
      setLoading(false)
    });
  }

  const changePage = (pageNumber: number) => {
    setPage(pageNumber)
    loadData(pageNumber)
  }

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      loadData(page);
    } else {
      setPage(1)
      loadData(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter, sort])

  const Sort = (field: string) => {
    if (sort && sort.sortField === field) {
      setSort({ sortField: field, sortOrder: sort.sortOrder === 'asc' ? 'desc' : 'asc' })
    } else {
      setSort({ sortField: field, sortOrder: 'asc' })
    }
  }

  const Check = (id: string, check: boolean) => {
    let c: any = Object.assign({}, checked)
    if (!check && id in c) delete c[id];
    if (check) c[id] = true
    setChecked(c)
  }

  const Remove = (id: string | null) => {
    const ids = id ? [id] : Object.keys(checked)
    if (ids.length > 0) {
      confirmAlert({
        title: 'Подтвердите удаление',
        message: 'Удалить элемен(ы)?',
        buttons: [
          {
            label: 'Да',
            className: 'btn btn-primary',
            onClick: () => {
              setWaiting(true)

              let promises = ids.map((id) => {
                return props.modelDescription.save({
                  state: State.deleted, item: { id: id }
                })
              })

              Promise.allSettled(promises).then((results) => {
                setWaiting(false)
                if (results.filter((r) => { return r.status !== 'fulfilled'; }).length === 0) {
                  let newPageCount = Math.ceil((itemsCount - ids.length) / perPage)
                  if (newPageCount < 1) newPageCount = 1

                  const newPage = page > newPageCount ? newPageCount : page
                  setPage(newPage)

                  loadData(newPage).then(() => {
                    setMessage('Объект(ы) успешно удален(ы)')
                  })
                } else {
                  setRemoveError(true)
                }
              }).catch(() => {
                setWaiting(false)
                setRemoveError(true)
              })
            }
          },
          {
            label: 'Нет',
            className: 'btn btn-primary',
          }
        ]
      });
    } else {
      setMessage('Не выбрано ни одного объекта')
    }
  }

  const headers = getHeaders(props.view, Sort, sort)

  return (
    <Container fluid>
      {loading
        ? <Container fluid className='save-loader d-flex justify-content-center'><Spinner animation='border' variant='primary' aria-flowto='' /></Container>
        : <Row>
          {props.toolbar && <ListToolbar route={props.route || ''} access={props.access} checked={checked} onDelete={() => { Remove(null) }} />}
          <Row className='list-form-content'>
            {waiting
              ? <Container fluid className='save-loader d-flex justify-content-center'><Spinner animation='border' variant='primary' aria-flowto='' />Операция выполняется...</Container>
              : <div>
                <Row>
                  <div className="table-container">
                    {removeError
                      ? <ClosableAlert className={'error'} text={'Произошла ошибка при удалении'} onClose={() => setRemoveError(false)} />
                      : ''}

                    {message
                      ? <ClosableAlert className={'success'} text={message} onClose={() => setMessage(null)} />
                      : ''}

                    <Table striped hover responsive size="lg" className="list-form-table">
                      <thead>
                        <tr>
                          {props.access?.full && <th style={{ width: "30px" }}></th>}
                          {headers}
                        </tr>
                      </thead>
                      <tbody>
                        {items.length > 0
                          ? items.map((info) => {
                            return (
                              <tr key={info.id}>
                                {props.access?.full && <td>
                                  <div className="list-helper-column-layout">
                                    <div className="checkbox">
                                      <input type="checkbox" className="list-checkbox" onChange={(e) => { Check(info.id, e.target.checked) }} />
                                      <label></label>
                                    </div>
                                    <Button variant="" className="icon-btn" title={'Удалить'} onClick={() => Remove(info.id)}><i className="icon-guideline-delete-r"></i></Button>
                                  </div>
                                </td>}
                                {getValues(props.modelDescription, props.view, info, () => { props.onRowClick(info, page) })}
                              </tr>)
                          })
                          : <tr><td className={'noData'} colSpan={headers.length + 1}>Нет данных</td></tr>}
                      </tbody>
                    </Table>
                  </div>
                </Row>
                <Row>
                  {pagesCount > 0 &&
                    <Pagination>
                      <span className="pagination-pages-count">{pagesCount} страниц(ы)&nbsp;</span>
                      <div className="pagination-page-items">
                        <Pagination.First className="page-item-fixed-width" onClick={() => changePage(1)}><i className="icon-guideline-navigate-arrows-left"></i></Pagination.First>
                        <Pagination.Prev className="page-item-fixed-width" {...(page > 1 ? {} : { disabled: true })} onClick={() => changePage(page - 1)}><i className="icon-guideline-navigate-arrow-left"></i></Pagination.Prev>
                        {page > 3 && <Pagination.Item key={1} active={false} onClick={() => changePage(1)}>{1}</Pagination.Item>}

                        {page > 4 && <Pagination.Ellipsis />}

                        {page > 2 && <Pagination.Item key={page - 2} active={false} onClick={() => changePage(page - 2)}>{page - 2}</Pagination.Item>}
                        {page > 1 && <Pagination.Item key={page - 1} active={false} onClick={() => changePage(page - 1)}>{page - 1}</Pagination.Item>}
                        <Pagination.Item key={page} active={true} onClick={() => changePage(page)}>{page}</Pagination.Item>
                        {page < pagesCount && <Pagination.Item key={page + 1} active={false} onClick={() => changePage(page + 1)}>{page + 1}</Pagination.Item>}
                        {(page + 1 < pagesCount) && <Pagination.Item key={page + 2} active={false} onClick={() => changePage(page + 2)}>{page + 2}</Pagination.Item>}

                        {pagesCount - page >= 4 && <Pagination.Ellipsis />}

                        {pagesCount - page >= 3 && <Pagination.Item key={pagesCount} active={false} onClick={() => changePage(pagesCount)}>{pagesCount}</Pagination.Item>}

                        <Pagination.Next className="page-item-fixed-width" {...(page < pagesCount ? {} : { disabled: true })} onClick={() => changePage(page + 1)}><i className="icon-guideline-navigate-arrow"></i></Pagination.Next>
                        <Pagination.Last className="page-item-fixed-width" onClick={() => changePage(pagesCount)}><i className="icon-guideline-navigate-arrows"></i></Pagination.Last>
                      </div>
                    </Pagination>}
                </Row>
              </div>
            }
          </Row>
        </Row>
      }
    </Container>
  );
}

List.defaultProps = {
  toolbar: true
}

export default List;