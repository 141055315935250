export const HOME_ROUTE = '/'
export const ABOUT_ROUTE = '/about'
export const TD_ROUTE = '/thnchsk-dl'
export const SPRAVOCHNIK_ROUTE = '/sprvchnk'
export const FIAS_ROUTE = '/fs'
export const KLADR_ROUTE = '/kldr'
export const MATERIAL_ROUTE = '/mtrl'
export const TIPSTEN_ROUTE = '/tp-stn'
export const TIPSANTEHNIKI_ROUTE = '/tp-snthnk'
export const TIPFUNDAMENTA_ROUTE = '/tp-fndmnt'
export const TIPPEREKRITIA_ROUTE = '/tp-prkrt'
export const TIPKROVLI_ROUTE = '/tp-krvl'
export const TIPPOLA_ROUTE = '/tp-pl'
export const TIPPROEMA_ROUTE = '/tp-prm'
export const TIPOTDELKI_ROUTE = '/tp-tdlk'
export const TIPPEREGORODOK_ROUTE = '/tp-prgrdk'
export const EDIZMERENIA_ROUTE = '/dzmrn'
export const TIPSOORUZHENIYA_ROUTE = '/tp-srzn'
export const FOND_ROUTE = '/fnd'
export const VIDTEHPASPORTA_ROUTE = '/vd-thpsprt'
export const VIDIZNOSA_ROUTE = '/vd-zns'
export const ETAZHI_ROUTE = '/tzh'
export const ELEMENT_ROUTE = '/lmnt'
export const POMESHENIE_ROUTE = '/pmsh'
export const BLAGOUSTROISTVO_ROUTE = '/blgstrstv'
export const VIDSOORUZHENIYA_ROUTE = '/vd-srzn'
export const VIDSOBSTVENNOSTI_ROUTE = '/vd-sbstvnst'
export const TIPVLADENIYA_ROUTE = '/tp-vldn'
export const KONTRAGENT_ROUTE = '/kontragent'