import { odataCreate, odataDelete, odataUpdate } from "../http/ODataApi";
import { State } from "./State";

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export function prop<P, K extends keyof P>(obj: P, key: K): any {
  return obj[key];
}

export class ViewProperty {
  caption: string = ''
  visible: boolean = true

  constructor(caption: string, visible: boolean = true) {
    this.caption = caption;
    this.visible = visible;
  }
}

export class ExpandViewProperty extends ViewProperty {
  expand?: PartialRecord<keyof any, ViewProperty> | null

  constructor(caption: string, expand: PartialRecord<keyof any, ViewProperty> | null = null, visible: boolean = true) {
    super(caption, visible)
    this.expand = expand
  }
}

export class DataObjectViewProperty extends ExpandViewProperty {
  property?: string | null

  constructor(caption: string, property: string | null = null, expand: PartialRecord<keyof any, ViewProperty> | null = null, visible: boolean = true) {
    super(caption, expand, visible)
    this.property = property
  }
}

export class DetailViewProperty extends ExpandViewProperty {
  constructor(caption: string, expand: PartialRecord<keyof any, ViewProperty> | null = null, visible: boolean = true) {
    super(caption, expand, visible)
  }
}

interface IDeserializeFunc {
  (source: any, key: string): object;
}

interface ISerializeFunc {
  (source: any, key: string): object;
}

export class PropertyDescription {
  serialize: ISerializeFunc = (source: any, key: string) => {
    let value = (source as any)[key]
    if (value === undefined) value = null

    if (this.type === 'number' && value) {
      value = Number(value)
    } else if (this.type === "text" && value) {
      value = value.replaceAll('\\', '/').replaceAll('"', '\'')

      let t: any = {}
      t[key] = value

      source = Object.assign(source, t)
    }

    let r: any = {}
    r[(this.sourceKey || key)] = value

    return r
  }

  deserialize: IDeserializeFunc = (source: any, key: string) => {
    let value = (source as any)[this.sourceKey || key]
    if (value === undefined) value = null

    let r: any = {}
    r[key] = value

    return r
  }

  type: string

  sourceKey: string | null

  constructor(type: string = 'text', sourceKey: string | null = null) {
    this.type = type
    this.sourceKey = sourceKey
  }
}

export class FailDescription extends PropertyDescription {
  serialize: ISerializeFunc = (source: any, key: string) => {
    const value = (source as any)[key] || null

    let r: any = {}
    r[(this.sourceKey || key)] = value ? JSON.stringify(value) : ''

    return r
  }

  deserialize: IDeserializeFunc = (source: any, key: string) => {
    const value = (source as any)[this.sourceKey || key] || null

    let r: any = {}
    r[key] = JSON.parse(value) || {}

    return r
  }

  constructor() {
    super('file')
  }
}

export class AuditFieldsDescription extends PropertyDescription {
  serialize: ISerializeFunc = () => {
    return {}
  }

  deserialize: IDeserializeFunc = (source: any, key: string) => {
    const value = (source as any)[this.sourceKey || key] || null

    let r: any = {}
    r[key] = JSON.parse(value) || []

    return r
  }

  constructor() {
    super('auditFields')
  }
}

export class DatePropertyDescription extends PropertyDescription {
  serialize: ISerializeFunc = (source: any, key: string) => {
    const value = ((source as any)[key] || null) as Date

    let r: any = {}
    r[(this.sourceKey || key)] = value ? value.toISOString() : null

    return r
  }

  deserialize: IDeserializeFunc = (source: any, key: string) => {
    const value = (source as any)[this.sourceKey || key] || null

    let r: any = {}
    r[key] = value ? new Date(value) : null

    return r
  }

  constructor() {
    super('date')
  }
}

export class BoolPropertyDescription extends PropertyDescription {
  constructor() {
    super('bool')
  }
}

export class EnumPropertyDescription extends PropertyDescription {
  linkedEnum: { [s: number]: string }
  backendName: string

  constructor(linkedEnum: { [s: number]: string }, backendName: string) {
    super('enum')
    this.linkedEnum = linkedEnum
    this.backendName = backendName
  }
}

export class DataObjectPropertyDescription extends PropertyDescription {
  serialize: ISerializeFunc = (source: any, key: string) => {
    const value = (source as any)[key] || null

    const id = value ? value['id'] : null

    let r: any = {}
    r[(this.sourceKey || key) + '@odata.bind'] = id ? this.odata.odataClassName + '(' + id + ')' : null

    return r
  }

  deserialize: IDeserializeFunc = (source: any, key: string) => {
    const value = (source as any)[this.sourceKey || key] || null
    let r: any = {}
    r[key] = value ? this.odata.deserialize(value) : null
    r[key + 'Id'] = r[key] ? r[key]['id'] || null : null

    return r
  }

  odata: DataObjectDescription<PropertyDescriptions>

  constructor(odataClass: DataObjectDescription<PropertyDescriptions>, type: string = 'lookup') {
    super(type)
    this.odata = odataClass
  }
}

export class DetailPropertyDescription extends DataObjectPropertyDescription {
  serialize: ISerializeFunc = (source: any, key: string) => {
    // Не будем сериализовывать детейлы, их все равно надо отправлять отдельно
    return {}
  }

  deserialize: IDeserializeFunc = (source: any, key: string) => {
    const value = ((source as any)[this.sourceKey || key] || null) as Array<any>

    let r: any = {}

    if (value) {
      let a: any[] = []
      value.forEach((o: any) => {
        a.push(this.odata.deserialize(o))
      })
      r[key] = a
    } else {
      r[key] = null
    }

    return r
  }

  masterPropertyName: string

  constructor(odataClass: DataObjectDescription<PropertyDescriptions>, masterPropertyName: string) {
    super(odataClass, 'array')
    this.masterPropertyName = masterPropertyName
    this.odata.masterPropertyName = masterPropertyName
  }
}

export declare type PropertyDescriptions = {
  [k: string]: PropertyDescription;
};

export declare type ViewDescription<T extends PropertyDescriptions> = {
  [k: string]: PartialRecord<keyof T, ViewProperty>;
};

interface IValidationOptions {
  message?: string | null
}

interface IValidationRuleFunc {
  (value: any, options: IValidationOptions): string | null;
}

export class ValidationRule {
  callback: IValidationRuleFunc
  options: any

  constructor(callback: IValidationRuleFunc, options: any = {}) {
    this.callback = callback
    this.options = options
  }
}

export const required = (options: IValidationOptions): ValidationRule => {
  return new ValidationRule((value: any, options: IValidationOptions): string | null => {
    return value ? null : (options.message || 'Значение не указано')
  }, options)
}

interface IBeforeSaveFunc {
  (value: any): Promise<any>;
}

export class DataObjectDescription<T extends PropertyDescriptions> {
  odataClassName: string = ''
  idProperty: PropertyDescription
  masterPropertyName?: string
  masterPropertyODataClassName?: string
  properties: T
  views: ViewDescription<T>
  validationRules: PartialRecord<keyof T, ValidationRule[]>
  beforeSave: IBeforeSaveFunc = () => { return Promise.resolve() }

  defaultValues: any

  constructor(odataClassName: string, properties: T, views: ViewDescription<T>, defaultValues: any = {}, validations: PartialRecord<keyof T, ValidationRule[]> = {}, beforeSave?: IBeforeSaveFunc, idProperty: PropertyDescription = new PropertyDescription('uuid', '__PrimaryKey')) {
    this.odataClassName = odataClassName
    this.idProperty = idProperty
    this.properties = properties
    this.defaultValues = defaultValues

    Object.entries(this.properties).forEach(([key, p]) => {
      if (p instanceof DetailPropertyDescription) {
        (p as DetailPropertyDescription).odata.masterPropertyODataClassName = this.odataClassName;
      }
    });

    this.views = views
    this.validationRules = validations
    if (beforeSave)
      this.beforeSave = beforeSave
  }

  isFailDetail(prop: string): boolean {
    const property = this.properties[prop]
    if (!property) return false;

    return Object.values((property as DetailPropertyDescription).odata.properties).filter((p) => { return p instanceof FailDescription }).length > 0;
  }

  save(value: any): Promise<any> {
    if (IsDataObjectChangedElement(value)) {

      return this.beforeSave(value.item).then(() => {
        let mainPromise;
        const data = this.serialize(value.item)

        switch (value.state) {
          case State.created:
            mainPromise = odataCreate(this, data).then((data: any) => {
              value.item = Object.assign(value.item, this.idProperty.deserialize(data, 'id'))
            })
            break;
          case State.altered:
            mainPromise = odataUpdate(this, data, value.item.id)
            break;
          case State.deleted:
            mainPromise = odataDelete(this, value.item.id)
            break;
          default:
            mainPromise = Promise.resolve()
            break;
        }

        return (mainPromise || Promise.resolve()).then(() => {

          if (value.state === State.deleted) {
            return;
          }

          let promises: Promise<any>[] = []
          Object.entries(this.properties).forEach(([key, p]) => {
            if (p instanceof DetailPropertyDescription) {

              if (!value.item[key]) return

              const dd = p as DetailPropertyDescription;
              value.item[key].forEach((detail: any) => {
                if (IsDataObjectChangedElement(detail)) {

                  if (detail.state === State.created) {
                    detail.item[dd.masterPropertyName] = value.item.id
                  }

                  let data = dd.odata.serialize(detail.item)
                  switch (detail.state) {
                    case State.created:
                      promises.push(odataCreate(dd.odata, data).then((data: any) => {
                        detail.item = Object.assign(detail.item, dd.odata.idProperty.deserialize(data, 'id'))
                      }))
                      break;
                    case State.altered:
                      promises.push(odataUpdate(dd.odata, data, detail.item.id))
                      break;
                    case State.deleted:
                      promises.push(odataDelete(dd.odata, detail.item.id))
                      break;
                    default:
                      break;
                  }
                }
              })
            }
          })

          return Promise.allSettled(promises).then((results: any[]) => {
            if (results.filter((r) => { return r.status !== 'fulfilled'; }).length === 0) {

              let copy: any = {}

              Object.entries(this.properties).forEach(([key, p]) => {
                if (p instanceof DetailPropertyDescription) {

                  if (!value.item[key]) return
                  copy[key] = []

                  value.item[key].forEach((detail: any) => {
                    if (IsDataObjectChangedElement(detail)) {
                      if (detail.state !== State.deleted) {
                        copy[key].push(detail.item)
                      }
                    } else copy[key].push(detail)
                  })
                } else {
                  copy[key] = value.item[key]
                }
              })

              copy.id = value.item.id;

              return Promise.resolve(copy);
            } else {
              console.error('Ошибка при сохранении детейла')
              return Promise.reject(new Error('Ошибка при сохранении детейла'))
            }
          });
        })
      }).catch((error) => {
        console.error(error);
        throw error
      })
    } else return Promise.resolve()
  }

  serialize(o: object): string {
    let result: any = {}

    Object.entries(this.properties).forEach(([key, p]) => {
      let prop = p.serialize(o, key)
      result = { ...result, ...prop };
    })

    if (this.masterPropertyName && this.masterPropertyODataClassName) {
      let master: any = {}
      let id = (o as any)[this.masterPropertyName]
      if (typeof id === 'object') id = id.id
      master[this.masterPropertyName + '@odata.bind'] = this.masterPropertyODataClassName + '(' + id + ')'
      result = { ...result, ...master }
    }

    return JSON.stringify(result);
  }

  deserialize(json: object): object {
    let result: any = {}

    let prop = this.idProperty.deserialize(json, 'id')
    result = { ...result, ...prop };

    Object.entries(this.properties).forEach(([key, p]) => {
      let prop = p.deserialize(json, key)
      result = { ...result, ...prop };
    })

    return result;
  }

  validate(o: object): PartialRecord<keyof T, string> {
    return {};
  }

  viewToQuery(view: PartialRecord<keyof any, ViewProperty>): { select: string, expand: string } {
    const queryFromView = (view: PartialRecord<keyof any, ViewProperty>, dataObjectDescription: DataObjectDescription<PropertyDescriptions>): { select: string, expand: string } => {
      const select: string[] = []
      const expand: string[] = []

      select.push(dataObjectDescription.idProperty.sourceKey || 'id')

      Object.entries(view).forEach(([f, v],) => {

        const property = dataObjectDescription.properties[f]
        if (!property) return;

        const queryKey = property.sourceKey || f

        if (v instanceof ExpandViewProperty && property instanceof DataObjectPropertyDescription) {
          const view = (v as ExpandViewProperty).expand;
          const innerData = (property as DataObjectPropertyDescription)?.odata
          if (view && innerData) {
            const innerQuery = queryFromView(view, innerData)
            expand.push(queryKey + '($select=' + innerQuery.select + (innerQuery.expand ? ';$expand=' + innerQuery.expand : '') + ')')
          }
        }

        select.push(queryKey)
      })

      return { select: select.join(','), expand: expand.join(',') }
    }

    return queryFromView(view, this)
  }
}

export interface IDataObjectChangedElement {
  state: State
  item: any
}

export const IsDataObjectChangedElement = (obj: any) => {
  return 'state' in obj && 'item' in obj
}

export interface IWebFile {
  fileUrl: string
  previewUrl: string | null
  fileName: string
  fileSize: number | null
  fileMimeType: string | null
}

export interface IWithWebFile {
  id: string
  Fajl: IWebFile
}

export interface IDetailWithWebFile extends IDataObjectChangedElement {
  file: File | null
  preview?: any
  state: State
  item: IWithWebFile
}

export const IsDetailWithWebFile = (obj: any) => {
  return 'file' in obj && obj['file'] instanceof File
}