import axios from "axios";
import { config } from '../utils/config'

const $baseHost =  axios.create({    
})

const $odataHost = axios.create({
    baseURL: config.REACT_APP_API_URL + config.REACT_APP_SECURITY_ODATA_PREFIX
})

const $securityHost = axios.create({
    baseURL: config.REACT_APP_API_URL + config.REACT_APP_SECURITY_ACCESS_API_PREFIX
})

const $apiHost = axios.create({
    baseURL: config.REACT_APP_API_URL + config.REACT_APP_SECURITY_BASE_API_PREFIX
})

const authInterceptor = config => {
    if (localStorage.getItem('token'))
        config.headers.authorization = `Bearer ${localStorage.getItem('token')}`

    return config
}

const refreshInterceptor = (error, instance) => {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        console.log('Unauthorized!')
        //const access_token = await refreshAccessToken();
        //axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return instance(originalRequest);
    }

    let exception = error.response.status === 400
        ? "Произошла ошибка"
        : error?.response?.data?.error?.message ?? error?.response?.data?.message ?? "Произошла ошибка";

    throw new Error(exception);
}

$odataHost.interceptors.request.use(authInterceptor)
$securityHost.interceptors.request.use(authInterceptor)
$apiHost.interceptors.request.use(authInterceptor)
$baseHost.interceptors.request.use(authInterceptor)

$odataHost.interceptors.response.use((response) => { return response }, (error) => { refreshInterceptor(error, $odataHost) })
$securityHost.interceptors.response.use((response) => { return response }, (error) => { refreshInterceptor(error, $securityHost) })
$apiHost.interceptors.response.use((response) => { return response }, (error) => { refreshInterceptor(error, $apiHost) })

export {
    $odataHost,
    $securityHost,
    $apiHost,
    $baseHost
}