import {makeAutoObservable} from "mobx";

export class MenuStore {
    private _expanded: boolean;

    private static instance: MenuStore;

    static Create() {
        if (this.instance) return this.instance;
        return new MenuStore()
    }

    private constructor() {
        this._expanded = false
        makeAutoObservable(this)
    }

    toggle() {
        this._expanded = !this._expanded
    }

    get expanded() {
        return this._expanded
    }
}

export const menuStore = MenuStore.Create();