import { loadFile } from '../http/ODataApi'
import {
    PropertyDescription, DataObjectDescription, ViewProperty, FailDescription, DataObjectPropertyDescription, DatePropertyDescription,
    DetailPropertyDescription, required, IsDataObjectChangedElement, IsDetailWithWebFile, DataObjectViewProperty, DetailViewProperty, EnumPropertyDescription, BoolPropertyDescription, AuditFieldsDescription,
} from './DescriptionTypes'

import {
    AdresFiasDesc, AdresKladrDesc, TipSooruzheniyaDesc, FondDesc, VidTehPasportaDesc, VidIznosaDesc, MaterialDesc, TipSantehnikiDesc, ElementDesc, PomeshenieDesc, VidSobstvennostiDesc, TipVladeniyaDesc,
    BlagoustroistvoDesc, TipFundamentaDesc, TipPerekritiaDesc, TipKrovliDesc, TipPolaDesc, TipProemaDesc, TipOtdelkiDesc, TipPeregorodokDesc, VidSooruzheniyaDesc, KontragentDesc
} from './Dictionaries'

import { TipObjekta, StatusObjekta, ExecutionStatus, OperationType } from './Enums'

export const SobstvennikDesc = new DataObjectDescription('SkyoriEASobstvenniks', {
    'Kontragent': new DataObjectPropertyDescription(KontragentDesc),
    'TipVladeniya': new DataObjectPropertyDescription(TipVladeniyaDesc),
    'VidSobstvennosti': new DataObjectPropertyDescription(VidSobstvennostiDesc),
    'DataNachala': new DatePropertyDescription(),
    'Dolya': new PropertyDescription(),
    'Dokument': new PropertyDescription(),
    'Notarius': new PropertyDescription()
}, {
    'webD': {
        'Kontragent': new DataObjectViewProperty('', null, {
            'Naimenovanie': new ViewProperty('Собственник')
        }, false),
        'DataNachala': new ViewProperty('Дата начала'),
        'Dokument': new ViewProperty('Документ')
    },
    'webE': {
        'TipVladeniya': new DataObjectViewProperty('Тип владения', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        }),
        'VidSobstvennosti': new DataObjectViewProperty('Вид собственности', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        }),
        'Kontragent': new DataObjectViewProperty('Контрагент', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('ФИО/Наименование')
        }),
        'DataNachala': new ViewProperty('Дата начала'),
        'Dolya': new ViewProperty('Доля'),
        'Dokument': new ViewProperty('Документ'),
        'Notarius': new ViewProperty('Нотариус')
    }
})

export const TehSostoyanieDesc = new DataObjectDescription('SkyoriEATehSostoyanies', {
    'Blagoustroistvo': new DataObjectPropertyDescription(BlagoustroistvoDesc)
}, {
    'webD': {
        'Blagoustroistvo': new DataObjectViewProperty('Вид благоустройства', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        })
    }
})

export const OtdelkaDesc = new DataObjectDescription('SkyoriEAOtdelkas', {
    'Pomeshenie': new DataObjectPropertyDescription(PomeshenieDesc),
    'Element': new DataObjectPropertyDescription(ElementDesc),
    'Material': new DataObjectPropertyDescription(MaterialDesc)
}, {
    'webD': {
        'Pomeshenie': new DataObjectViewProperty('Помещение', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        }),
        'Element': new DataObjectViewProperty('Констр. элемент', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        }),
        'Material': new DataObjectViewProperty('Материал', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        })
    }
})

export const KvartiraDesc = new DataObjectDescription('SkyoriEAKvartiras', {
    'NomerEtazha': new PropertyDescription(),
    'NomerKvartiry': new PropertyDescription(),
    'OsnovnayaPloshad': new PropertyDescription('number'),
    'VspomPloshad': new PropertyDescription('number'),
    'PrPloshad': new PropertyDescription('number'),
    'Hrwart': new PropertyDescription('number'),
    'KolKuhon': new PropertyDescription('number'),
    'KolKomnat': new PropertyDescription('number'),
    'DataInventarizacii': new DatePropertyDescription(),
    'UrEtazha': new PropertyDescription('number'),
    'TehSostoyanie': new DetailPropertyDescription(TehSostoyanieDesc, 'Kvartira'),
    'Otdelka': new DetailPropertyDescription(OtdelkaDesc, 'Kvartira')
}, {
    'webD': {
        'NomerKvartiry': new ViewProperty('Квартира'),
        'OsnovnayaPloshad': new ViewProperty('Основная площадь'),
        'DataInventarizacii': new ViewProperty('Дата инвентаризации')
    },
    'webE': {
        'NomerEtazha': new ViewProperty('Этаж'),
        'NomerKvartiry': new ViewProperty('Квартира'),
        'OsnovnayaPloshad': new ViewProperty('Осн. площадь'),
        'VspomPloshad': new ViewProperty('Вспом. площадь'),
        'PrPloshad': new ViewProperty('Прочая площадь'),
        'Hrwart': new ViewProperty('Высота'),
        'KolKuhon': new ViewProperty('Кол-во кухонь'),
        'KolKomnat': new ViewProperty('Кол-во комнат'),
        'DataInventarizacii': new ViewProperty('Дата инвентаризации'),
        'UrEtazha': new ViewProperty('Ур. этажа'),

        'TehSostoyanie': new DetailViewProperty('', {
            'Blagoustroistvo': new DataObjectViewProperty('Вид благоустройства', 'Naimenovanie', {
                'Naimenovanie': new ViewProperty('Наименование')
            })
        }),

        'Otdelka': new DetailViewProperty('', {
            'Pomeshenie': new DataObjectViewProperty('Помещение', 'Naimenovanie', {
                'Naimenovanie': new ViewProperty('Наименование')
            }),
            'Element': new DataObjectViewProperty('Констр. элемент', 'Naimenovanie', {
                'Naimenovanie': new ViewProperty('Наименование')
            }),
            'Material': new DataObjectViewProperty('Материал', 'Naimenovanie', {
                'Naimenovanie': new ViewProperty('Наименование')
            })
        })
    }
})

export const ZemelnyUchastokDesc = new DataObjectDescription('SkyoriEAZemelnyUchastoks', {
    'PoshadPoDokumentam': new PropertyDescription('number'),
    'ZastroennayaPloshad': new PropertyDescription('number'),
    'NezastroennayaPloshad': new PropertyDescription('number'),
    'Ozeleneno': new PropertyDescription('number'),
    'Ogorod': new PropertyDescription('number'),
    'Dvor': new PropertyDescription('number'),
    'Prochee': new PropertyDescription('number'),
    'Grunt': new PropertyDescription('number'),
    'Proezd': new PropertyDescription('number'),
    'Trotuar': new PropertyDescription('number')
}, {
    'webD': {
        'PoshadPoDokumentam': new ViewProperty('Площадь по документам'),
        'ZastroennayaPloshad': new ViewProperty('Застроенная площадь'),
        'NezastroennayaPloshad': new ViewProperty('Незастроенная площадь'),
        'Ozeleneno': new ViewProperty('Площадь озеленения'),
        'Ogorod': new ViewProperty('Площадь огорода'),
        'Dvor': new ViewProperty('Площадь двора'),
        'Grunt': new ViewProperty('Площадь грунта'),
        'Proezd': new ViewProperty('Площадь проезда'),
        'Trotuar': new ViewProperty('Площадь тротуара'),
        'Prochee': new ViewProperty('Прочая площадь')
    }
})

export const AdrPlanDesc = new DataObjectDescription('SkyoriEAAdrPlans', {
    'TipObjekta': new EnumPropertyDescription(TipObjekta, 'Skyori.EA.TipObjekta'),
    'Status': new EnumPropertyDescription(StatusObjekta, 'Skyori.EA.StatusObjekta'),
    'Naimenovanie': new PropertyDescription(),
    'DataPasporta': new DatePropertyDescription(),
    'InventNomer': new PropertyDescription(),
    'ReestrRKN': new PropertyDescription(),
    'ReestrZap': new PropertyDescription(),
    'Ispolnitel': new PropertyDescription(),
    'NomerBoksa': new PropertyDescription(),
    'Stoimost': new PropertyDescription('number'),
    'GodStoimosti': new PropertyDescription('number'),
    'AdresStrokoi': new PropertyDescription(),
    'PrimAdr': new PropertyDescription(),
    'IspolnitelOTUTI': new PropertyDescription(),
    'BDRosreestra': new BoolPropertyDescription(),

    'TehPasport': new DataObjectPropertyDescription(VidTehPasportaDesc),
    'Fond': new DataObjectPropertyDescription(FondDesc),
    'Kladr': new DataObjectPropertyDescription(AdresKladrDesc),
    'Fias': new DataObjectPropertyDescription(AdresFiasDesc)
}, {
    'webE': {
        'TipObjekta': new ViewProperty('Тип объекта'),
        'Status': new ViewProperty('Статус'),
        'Naimenovanie': new ViewProperty('Наименование'),
        'DataPasporta': new ViewProperty('Дата паспорта'),
        'InventNomer': new ViewProperty('Инв. номер'),
        'ReestrRKN': new ViewProperty('Реестр РКН'),
        'ReestrZap': new ViewProperty('Реестр'),
        'Ispolnitel': new ViewProperty('Исполнитель'),
        'NomerBoksa': new ViewProperty('Номер бокса'),
        'Stoimost': new ViewProperty('Стоимость'),
        'GodStoimosti': new ViewProperty('Год стоимости'),
        'AdresStrokoi': new ViewProperty('Адрес'),
        'PrimAdr': new ViewProperty('Описание адреса'),
        'IspolnitelOTUTI': new ViewProperty('Исполнитель ОТУТИ'),
        'BDRosreestra': new ViewProperty('БД Росреестра'),

        'TehPasport': new DataObjectViewProperty('Тех. паспорт', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        }),
        'Fond': new DataObjectViewProperty('Фонд', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        }),
        'Kladr': new DataObjectViewProperty('Адрес (КЛАДР)', 'AdresStrokoi', {
            'AdresStrokoi': new ViewProperty('Адрес')
        }),
        'Fias': new DataObjectViewProperty('Адрес (ФИАС)', 'AdresStrokoi', {
            'AdresStrokoi': new ViewProperty('Адрес')
        })
    },
    'webD': {
        'TipObjekta': new ViewProperty('Тип объекта'),
        'Status': new ViewProperty('Статус'),
        'Naimenovanie': new ViewProperty('Наименование'),
        'DataPasporta': new ViewProperty('Дата паспорта'),
        'InventNomer': new ViewProperty('Инв. номер'),
        'ReestrRKN': new ViewProperty('Реестр РКН'),
        'ReestrZap': new ViewProperty('Реестровая запись'),
        'Ispolnitel': new ViewProperty('Исполнитель'),
        'NomerBoksa': new ViewProperty('Номер бокса'),
        'Stoimost': new ViewProperty('Стоимость'),
        'GodStoimosti': new ViewProperty('Год стоимости'),
        'AdresStrokoi': new ViewProperty('Адрес'),
        'IspolnitelOTUTI': new ViewProperty('Исполнитель ОТУТИ'),
        'BDRosreestra': new ViewProperty('БД Росреестра'),

        'TehPasport': new DataObjectViewProperty('Тех. паспорт', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        }),
        'Fond': new DataObjectViewProperty('Фонд', 'Naimenovanie', {
            'Naimenovanie': new ViewProperty('Наименование')
        })
    }
}, {}, {},
    (fields: any): Promise<any> => {
        let adresses = []
        if (fields['Kladr']?.AdresStrokoi) adresses.push(fields['Kladr']?.AdresStrokoi);
        if (fields['Fias']?.AdresStrokoi) adresses.push(fields['Fias']?.AdresStrokoi);
        if (fields['PrimAdr']) adresses.push(fields['PrimAdr']);

        fields['AdresStrokoi'] = adresses.join('; ')

        return Promise.resolve()
    })

export const FajlTDDesc = new DataObjectDescription('SkyoriEAFajlTDs', {
    'Fajl': new FailDescription(),
}, {
    'webD': {
        'Fajl': new ViewProperty('Файл')
    }
})

export const TehnicheskoeDeloDesc = new DataObjectDescription('SkyoriEATehnicheskoeDelos', {
    'NalichieTV': new BoolPropertyDescription(),
    'NalichieVodosnabzheniya': new BoolPropertyDescription(),
    'NalichieElektrichestva': new BoolPropertyDescription(),
    'NalichieRadio': new BoolPropertyDescription(),
    'NalichieTelefona': new BoolPropertyDescription(),
    'NalichieVannoi': new BoolPropertyDescription(),
    'NalichieGorVody': new BoolPropertyDescription(),
    'NalichieVentilyacii': new BoolPropertyDescription(),
    'NalichieGazosnabzheniya': new BoolPropertyDescription(),
    'NalichieMusoroprovoda': new BoolPropertyDescription(),
    'NalichieLifta': new BoolPropertyDescription(),
    'NalichieKanalizacii': new BoolPropertyDescription(),
    'NalichieElPlity': new BoolPropertyDescription(),
    'NalicieBasseina': new BoolPropertyDescription(),
    'NalicieSauny': new BoolPropertyDescription(),
    'NalichieSignalizacii': new BoolPropertyDescription(),
    'NaliciePechOtopleniya': new BoolPropertyDescription(),
    'NalichieDopVentilyazii': new BoolPropertyDescription(),
    'NalichieCentrOtopleniya': new BoolPropertyDescription(),
    'NalichieDopTV': new BoolPropertyDescription(),
    'NalichieDrugogoOtopleniya': new BoolPropertyDescription(),
    'TehSostFundamenta': new PropertyDescription(),
    'TehSostNaruzhSten': new PropertyDescription(),
    'TehSostVnutrSten': new PropertyDescription(),
    'TehSostPeregorodok': new PropertyDescription(),
    'TehSostPerekrytCher': new PropertyDescription(),
    'TehSostPerekrMezh': new PropertyDescription(),
    'TehSostPerekrPodv': new PropertyDescription(),
    'TehSostKrysh': new PropertyDescription(),
    'TehSostPola': new PropertyDescription(),
    'TehSostOkonProem': new PropertyDescription(),
    'TehSostDvernProem': new PropertyDescription(),
    'TehSostOtdelkaNar': new PropertyDescription(),
    'TehSostOtdelkaVnutr': new PropertyDescription(),
    'TehSostSanteh': new PropertyDescription(),
    'TehSostProch': new PropertyDescription(),
    'IznosFundamenta': new PropertyDescription('number'),
    'IznosSten': new PropertyDescription('number'),
    'IznosPerekryt': new PropertyDescription('number'),
    'IznosPeregorodok': new PropertyDescription('number'),
    'IznosKryshi': new PropertyDescription('number'),
    'IznosPolov': new PropertyDescription('number'),
    'IznosOkon': new PropertyDescription('number'),
    'IznosOtdelki': new PropertyDescription('number'),
    'IznosSantehniki': new PropertyDescription('number'),
    'IznosProchee': new PropertyDescription('number'),
    'IznosStenObshii': new PropertyDescription('number'),
    'IznosFundamentaObshii': new PropertyDescription('number'),

    'GodPostroiki': new PropertyDescription('number'),
    'GodPriobreteniya': new PropertyDescription('number'),
    'GodKapRemonta': new PropertyDescription('number'),
    'DataSnosa': new DatePropertyDescription(),

    'TipFundamenta': new DataObjectPropertyDescription(TipFundamentaDesc),
    'TipSooruzheniya': new DataObjectPropertyDescription(TipSooruzheniyaDesc),
    'VidSooruzheniya': new DataObjectPropertyDescription(VidSooruzheniyaDesc),
    'TipStenNaruzh': new DataObjectPropertyDescription(MaterialDesc),
    'TipStenVnutr': new DataObjectPropertyDescription(MaterialDesc),
    'VidIznosa': new DataObjectPropertyDescription(VidIznosaDesc),
    'TipPeregorodok': new DataObjectPropertyDescription(TipPeregorodokDesc),
    'TipKrovli': new DataObjectPropertyDescription(TipKrovliDesc),
    'TipSantehniki': new DataObjectPropertyDescription(TipSantehnikiDesc),
    'TipPola': new DataObjectPropertyDescription(TipPolaDesc),
    'TipPerekritiaCher': new DataObjectPropertyDescription(TipPerekritiaDesc),
    'TipPerekritiaMezh': new DataObjectPropertyDescription(TipPerekritiaDesc),
    'TipPerekritiaPodv': new DataObjectPropertyDescription(TipPerekritiaDesc),
    'TipOkonProema': new DataObjectPropertyDescription(TipProemaDesc),
    'TipDvernProema': new DataObjectPropertyDescription(TipProemaDesc),
    'TipOtdelkiVn': new DataObjectPropertyDescription(TipOtdelkiDesc),
    'TipOtdelkiNaruzh': new DataObjectPropertyDescription(TipOtdelkiDesc),

    'AdrPlan': new DataObjectPropertyDescription(AdrPlanDesc),

    'FajlTD': new DetailPropertyDescription(FajlTDDesc, 'TehDelo'),
    'Sobstvennik': new DetailPropertyDescription(SobstvennikDesc, 'TehDelo'),
    'ZemelnyUchastok': new DetailPropertyDescription(ZemelnyUchastokDesc, 'TehDelo'),
    'Kvartira': new DetailPropertyDescription(KvartiraDesc, 'TehDelo')
}, {
    'webL': {
        'AdrPlan': new DataObjectViewProperty('', null, {
            'Naimenovanie': new ViewProperty('Наименование объекта'),
            'AdresStrokoi': new ViewProperty('Адрес'),
            'InventNomer': new ViewProperty('Инвентарный номер'),
            'TipObjekta': new ViewProperty('Тип объекта'),
            'IspolnitelOTUTI': new ViewProperty('Исполнитель ОТУТИ'),
            'BDRosreestra': new ViewProperty('БД Росреестра')

        }, false),
        'GodPostroiki': new ViewProperty('Год постройки')
    },
    'webE': {
        'AdrPlan': new DataObjectViewProperty('', null, {
            'TipObjekta': new ViewProperty('Тип объекта'),
            'Status': new ViewProperty('Статус'),
            'Naimenovanie': new ViewProperty('Наименование'),
            'DataPasporta': new ViewProperty('Дата паспорта'),
            'InventNomer': new ViewProperty('Инв. номер'),
            'ReestrRKN': new ViewProperty('Реестр РКН'),
            'ReestrZap': new ViewProperty('Реестровая запись'),
            'Ispolnitel': new ViewProperty('Исполнитель'),
            'NomerBoksa': new ViewProperty('Номер бокса'),
            'Stoimost': new ViewProperty('Стоимость'),
            'GodStoimosti': new ViewProperty('Год стоимости'),
            'AdresStrokoi': new ViewProperty('Адрес'),
            'IspolnitelOTUTI': new ViewProperty('Исполнитель ОТУТИ'),
            'BDRosreestra': new ViewProperty('БД Росреестра'),

            'TehPasport': new DataObjectViewProperty('Тех. паспорт', 'Naimenovanie', {
                'Naimenovanie': new ViewProperty('Наименование')
            }),
            'Fond': new DataObjectViewProperty('Фонд', 'Naimenovanie', {
                'Naimenovanie': new ViewProperty('Наименование')
            })
        }),

        'NalichieTV': new ViewProperty(''),
        'NalichieVodosnabzheniya': new ViewProperty(''),
        'NalichieElektrichestva': new ViewProperty(''),
        'NalichieRadio': new ViewProperty(''),
        'NalichieTelefona': new ViewProperty(''),
        'NalichieVannoi': new ViewProperty(''),
        'NalichieGorVody': new ViewProperty(''),
        'NalichieVentilyacii': new ViewProperty(''),
        'NalichieGazosnabzheniya': new ViewProperty(''),
        'NalichieMusoroprovoda': new ViewProperty(''),
        'NalichieLifta': new ViewProperty(''),
        'NalichieKanalizacii': new ViewProperty(''),
        'NalichieElPlity': new ViewProperty(''),
        'NalicieBasseina': new ViewProperty(''),
        'NalicieSauny': new ViewProperty(''),
        'NalichieSignalizacii': new ViewProperty(''),
        'NaliciePechOtopleniya': new ViewProperty(''),
        'NalichieDopVentilyazii': new ViewProperty(''),
        'NalichieCentrOtopleniya': new ViewProperty(''),
        'NalichieDopTV': new ViewProperty(''),
        'NalichieDrugogoOtopleniya': new ViewProperty(''),
        'TehSostFundamenta': new ViewProperty(''),
        'TehSostNaruzhSten': new ViewProperty(''),
        'TehSostVnutrSten': new ViewProperty(''),
        'TehSostPeregorodok': new ViewProperty(''),
        'TehSostPerekrytCher': new ViewProperty(''),
        'TehSostPerekrMezh': new ViewProperty(''),
        'TehSostPerekrPodv': new ViewProperty(''),
        'TehSostKrysh': new ViewProperty(''),
        'TehSostPola': new ViewProperty(''),
        'TehSostOkonProem': new ViewProperty(''),
        'TehSostDvernProem': new ViewProperty(''),
        'TehSostOtdelkaNar': new ViewProperty(''),
        'TehSostOtdelkaVnutr': new ViewProperty(''),
        'TehSostSanteh': new ViewProperty(''),
        'TehSostProch': new ViewProperty(''),
        'IznosFundamenta': new ViewProperty(''),
        'IznosSten': new ViewProperty(''),
        'IznosPerekryt': new ViewProperty(''),
        'IznosPeregorodok': new ViewProperty(''),
        'IznosKryshi': new ViewProperty(''),
        'IznosPolov': new ViewProperty(''),
        'IznosOkon': new ViewProperty(''),
        'IznosOtdelki': new ViewProperty(''),
        'IznosSantehniki': new ViewProperty(''),
        'IznosProchee': new ViewProperty(''),
        'IznosStenObshii': new ViewProperty(''),
        'IznosFundamentaObshii': new ViewProperty(''),

        'GodPostroiki': new ViewProperty(''),
        'GodPriobreteniya': new ViewProperty(''),
        'GodKapRemonta': new ViewProperty(''),
        'DataSnosa': new ViewProperty(''),

        'TipFundamenta': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipSooruzheniya': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'VidSooruzheniya': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipStenNaruzh': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipStenVnutr': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'VidIznosa': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipPeregorodok': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipKrovli': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipSantehniki': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipPola': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipPerekritiaCher': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipPerekritiaMezh': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipPerekritiaPodv': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipOkonProema': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipDvernProema': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipOtdelkiVn': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),
        'TipOtdelkiNaruzh': new DataObjectViewProperty('', null, { 'Naimenovanie': new ViewProperty('') }),

        'FajlTD': new DetailViewProperty('', {
            'Fajl': new ViewProperty('')
        }),

        'Sobstvennik': new DetailViewProperty('', {
            'Kontragent': new DataObjectViewProperty('', null, {
                'Naimenovanie': new ViewProperty('Собственник')
            }),
            'DataNachala': new ViewProperty('Дата начала'),
            'Dokument': new ViewProperty('Документ')
        }),

        'ZemelnyUchastok': new DetailViewProperty('', {
            'PoshadPoDokumentam': new ViewProperty('Площадь по док.'),
            'ZastroennayaPloshad': new ViewProperty('Застроенная площадь'),
            'NezastroennayaPloshad': new ViewProperty('Незастроенная площадь'),
            'Ozeleneno': new ViewProperty('Озеленено'),
            'Ogorod': new ViewProperty('Огород'),
            'Dvor': new ViewProperty('Двор'),
            'Proezd': new ViewProperty('Проезд'),
            'Grunt': new ViewProperty('Грунт'),
            'Trotuar': new ViewProperty('Тротуар'),
            'Prochee': new ViewProperty('Прочее')
        }),

        'Kvartira': new DetailViewProperty('', {
            'NomerKvartiry': new ViewProperty('Номер квартиры'),
            'OsnovnayaPloshad': new ViewProperty('Основная площадь'),
            'DataInventarizacii': new ViewProperty('Дата инвентаризации')
        })
    }
}, {}, {
    'AdrPlan': [required({ message: 'Необходимо указать адресный паспорт' })]
},
    (fields: any): Promise<any> => {
        let promises: Promise<any>[] = []

        fields.FajlTD?.forEach((ftd: any) => {
            if (IsDataObjectChangedElement(ftd) && IsDetailWithWebFile(ftd)) {
                if (ftd.file) {
                    promises.push(loadFile(ftd.file).then((data) => {
                        ftd.item.Fajl = data;
                        ftd.file = null
                    }))
                }
            }
        })

        return Promise.allSettled(promises)
    }
)

export const AuditDesc = new DataObjectDescription('NewPlatformFlexberryAuditBigDataAuditRecords', {
    'UserName': new PropertyDescription(),
    'UserLogin': new PropertyDescription(),
    'ObjectType': new PropertyDescription(),
    'ObjectPrimaryKey': new PropertyDescription(),
    'OperationTime': new DatePropertyDescription(),
    'OperationType': new EnumPropertyDescription(OperationType, ''),
    'ExecutionStatus': new EnumPropertyDescription(ExecutionStatus, ''),
    'Source': new PropertyDescription(),
    'SerializedFields': new AuditFieldsDescription()
}, {
    'webL': {
        'UserName': new ViewProperty(''),
        'UserLogin': new ViewProperty(''),
        'ObjectType': new ViewProperty(''),
        'ObjectPrimaryKey': new ViewProperty(''),
        'OperationTime': new ViewProperty(''),
        'OperationType': new ViewProperty(''),
        'ExecutionStatus': new ViewProperty(''),
        'SerializedFields': new ViewProperty('')
    }
})
