import React, { useRef, useEffect, useState } from "react";
import LookupForm from './LookupForm';
import Modal from "react-bootstrap/Modal";
import { Button, Spinner } from "react-bootstrap";

import { fetchAllOdata } from "../../http/ODataApi";
import { DataObjectDescription, PartialRecord, PropertyDescriptions, ViewProperty } from "../../descriptions/DescriptionTypes";
import { ContainsPredicate } from "../../utils/filters";
import * as Icon from 'react-bootstrap-icons';

interface Props<T extends PropertyDescriptions> {
    onLookupClick: any,
    id: string,
    initialVal: any
    dataLoaded: Boolean
    readonly: boolean

    autocompleteProp: string
    autocomplete: boolean
    edit: boolean
    view: PartialRecord<keyof T, ViewProperty>
    modelDescription: DataObjectDescription<T>
    title?: string

    showObjectForm?: (id: string) => React.ReactElement
    editForm?: (id: string | null, onclose: any) => React.ReactElement

    className?: string
    defaultSort?: { sortField: string, sortOrder: 'asc' | 'desc' }
}

interface Auto {
    id: string,
    text: string
}

const Lookup = <T extends PropertyDescriptions>(props: Props<T>) => {
    const [showList, setShowList] = useState(false)
    const [showObject, setShowObject] = useState(false)
    const [editObject, setEditObject] = useState(false)
    const [createObject, setCreateObject] = useState(false)
    const [loading, setLoading] = useState(false)

    const [text, setText] = useState<string | null>(null)

    const [handler, setHandler] = useState<NodeJS.Timeout | null>(null)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [val, setVal] = useState<string | null>(null)

    const defaultVariants: Auto[] = []
    const [variants, setVariants] = useState(defaultVariants)
    const [showVariants, setShowVariants] = useState(false)
    const showVariantsRef = useRef(false);
    useEffect(() => {
        showVariantsRef.current = showVariants;
    }, [showVariants]);

    const [activeVariant, setActiveVariant] = useState(-1)

    const previousController = useRef<AbortController>();

    useEffect(() => {
        if (props.dataLoaded && props.id) {
            setVal(props.id)
            setText((props.initialVal || {})[props.autocompleteProp])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataLoaded])

    function handleSelect(info: any) {
        const text = info ? info[props.autocompleteProp] : null
        const id = info ? info.id : null

        props.onLookupClick(id, info);

        setShowList(false);
        setVal(id)
        setText(text);
    }

    function handleAutocomplete(e: React.ChangeEvent<HTMLInputElement>) {
        if (handler) {
            clearTimeout(handler);
        }

        setText(e.target.value);
        e.preventDefault();

        if (props.id) {
            props.onLookupClick(null, null);
            setVal(null)
        }

        setHandler(setTimeout(() => {
            getData(e.target.value)
        }, 500));
    }

    const getData = (searchTerm: string) => {
        if (previousController.current) {
            previousController.current.abort();
        }

        if (searchTerm && searchTerm.length >= 3) {
            const controller = new AbortController();
            const signal = controller.signal;
            previousController.current = controller;

            const filter = searchTerm ? new ContainsPredicate(props.autocompleteProp, searchTerm.replaceAll(' ', '*')) : undefined

            setLoading(true);
            fetchAllOdata<T>(props.modelDescription, props.view, { search: filter, limit: 20, signal }).then((results) => {
                const updatedOptions = results.jsonItems.map((p: any) => {
                    const text = p ? p[props.autocompleteProp] : ''
                    return Object.assign(p, { id: p.id, text });
                });

                setShowVariants(updatedOptions.length > 0)
                setVariants(updatedOptions);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setShowVariants(false)
            //setLoading(false);
        }
    };

    function remove() {
        setActiveVariant(-1)
        setShowVariants(false)

        props.onLookupClick(null, null);
        setText(null)
        setVal(null)
    }

    function onClick(e: Auto) {
        setActiveVariant(-1)
        setShowVariants(false)

        props.onLookupClick(e.id, e);

        setText(e.text)
        setVal(e.id)
    };

    function onFocusOut(e: React.FocusEvent<HTMLInputElement>) {
        setTimeout(() => {
            if (showVariantsRef.current) {
                setActiveVariant(-1)
                setShowVariants(false)
                setText(null)
            }
        }, 500)
    }

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            setActiveVariant(-1)
            setShowVariants(false)
            let active = variants[activeVariant]

            props.onLookupClick(active.id, active);

            setText(active.text)
            setVal(active.id)
        }
        else if (e.key === "ArrowUp") {
            if (activeVariant <= 0) {
                return;
            }

            setActiveVariant(activeVariant - 1)
            let active = variants[activeVariant - 1]
            setText(active.text)
        }
        else if (e.key === "ArrowDown") {
            if (activeVariant + 1 === variants.length) {
                return;
            }

            setActiveVariant(activeVariant + 1)
            let active = variants[activeVariant + 1]
            setText(active.text)
        }
    };

    return (
        props.readonly
            ? <div className={'readonly'}>{props.autocomplete && (text || '')}</div>
            : <div className={'lookup' + (props.autocomplete ? ' autocomplete' : ' only-buttons')}>
                {props.autocomplete && <input className={props.className}
                    value={text || ''}
                    onChange={handleAutocomplete}
                    onKeyDown={onKeyDown}
                    onBlur={onFocusOut}>
                </input>}
                {props.autocomplete && loading && <Spinner className="lookup-inside" animation='border' size="sm" variant='primary' aria-flowto='' />}

                <div className='lookup-buttons'>
                    {props.id && props.edit && <Button variant="" className={'icon-btn lookup-btn'} title={'Редактировать'} onClick={() => setEditObject(true)}><i className="icon-guideline-edit-r"></i></Button>}

                    {props.id && !props.edit && <Icon.X size={28} className={'lookup-btn'} title={'Очистить'} onClick={() => remove()}></Icon.X>}
                    {props.id && props.showObjectForm && <Icon.Eye size={22} className={'lookup-btn'} title={'Просмотр объекта'} onClick={() => setShowObject(true)}></Icon.Eye>}
                    <Button variant="" className={'btn icon-btn lookup-btn'} title={'Показать список'} onClick={() => setShowList(true)}><i className='icon-guideline-list-r'></i></Button>

                    {props.edit && <Button variant="" className={'btn icon-btn lookup-btn'} title={'Создать'} onClick={() => setCreateObject(true)}><i className="icon-guideline-plus-r"></i></Button>}
                </div>
                <div className='lookup-variants-container'>
                    {showVariants
                        ?
                        <div className='lookup-variants'>
                            {variants.map((v, index) => {
                                let className;

                                if (index === activeVariant) {
                                    className = "active";
                                }

                                return (
                                    <div className={'variant ' + className} key={v.id} onClick={() => { onClick(v) }}>
                                        {v.text}
                                    </div>
                                );
                            })}
                        </div>
                        : ""
                    }
                </div>
                <Modal
                    className={props.className}
                    show={showList}
                    onHide={() => setShowList(false)}
                    size="lg"
                    scrollable={true}
                    centered>
                    <Modal.Header closeButton>
                        {props.title}
                    </Modal.Header>
                    <Modal.Body className={'lookup-form'}>
                        <LookupForm defaultSort={props.defaultSort} modelDescription={props.modelDescription} view={props.view} onLookupClick={(info: T) => { handleSelect(info) }}></LookupForm>
                    </Modal.Body>
                </Modal>
                <Modal
                    className={props.className}
                    show={showObject}
                    onHide={() => setShowObject(false)}
                    size="lg"
                    scrollable={true}
                    centered>
                    <Modal.Header closeButton>
                        {props.title}
                    </Modal.Header>
                    <Modal.Body>
                        {props.showObjectForm ? props.showObjectForm(props.id || '') : ''}
                    </Modal.Body>
                </Modal>

                <Modal
                    className={props.className}
                    show={editObject || createObject}
                    onHide={() => { setEditObject(false); setCreateObject(false) }}
                    size="lg"
                    scrollable={true}
                    centered>
                    <Modal.Header>
                        {props.title}
                    </Modal.Header>
                    <Modal.Body>
                        {props.editForm ? props.editForm(createObject ? null : props.id || '', () => { setEditObject(false); setCreateObject(false) }) : ''}
                    </Modal.Body>
                </Modal>
            </div>
    );
}

Lookup.defaultProps = {
    readonly: false,
    autocomplete: true,
    edit: false
};


export default Lookup;
