import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.scss';
import Header from './components/Header';
import Content from './components/Content';
import LeftMenu from './components/LeftMenu';
import { Container, Row, Col } from 'react-bootstrap';

import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Context } from "./index";

const App = observer(() => {

  const context = useContext(Context)

  if (!context?.user?.Loading) {
    if (context?.user?.authenticated) {
      return (
        <Container fluid>
          <Row>
            <Header />
          </Row>
          <Row style={{ padding: '72px 0 0 0' }}>
            <LeftMenu />
            <Col className='app content'>
              <Content />
            </Col>
          </Row>

        </Container>
      )
    } else return (<div>Unable to authenticate!</div>)
  }

  return (
    <div>...</div>
  );

})

export default App;
